import { useEffect } from "react";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import PayInvoicesStepper from "../PayInvoicesStepper";
import Confirmation from "../payInvoicesSteps/Conformation";
import ManualTransfer from "../payInvoicesSteps/ManualTransfer";
import PaymentMethod from "../payInvoicesSteps/PaymentMethod";
import ReviewInvoices from "../payInvoicesSteps/ReviewInvoices";
import {
  PayInvoicesContainer,
  PayInvoicesContentContainer,
  PayInvoicesHeader,
  PayInvoicesSubtitle,
  PayInvoicesTitle,
  StepsContainer,
} from "./styled";
import { useNavigate } from "react-router-dom";

const PayInvoices = () => {
  const { current_pay_invoices_step: currentStep, totalPayment } =
    useAppSelector((state) => state.payment);
  const navigate = useNavigate();

  const renderSteps = () => {
    switch (currentStep) {
      case 0:
        return <ReviewInvoices />;
      case 1:
        return <PaymentMethod />;
      case 2:
        return <ManualTransfer />;
      case 3:
        return <Confirmation />;
    }
  };

  useEffect(() => {
    if (totalPayment == 0) {
      navigate("/payment");
    }
  }, [totalPayment]);

  return (
    <PayInvoicesContainer>
      <PayInvoicesHeader>
        <PayInvoicesTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Pay Invoices{" "}
        </PayInvoicesTitle>
        <PayInvoicesSubtitle
          size={FontSizes.TX_MD}
          weight={FontWeights.REGULAR}
        >
          Fermentum Venenatis Tortor
        </PayInvoicesSubtitle>
      </PayInvoicesHeader>
      <PayInvoicesContentContainer>
        <StepsContainer>{renderSteps()}</StepsContainer>
        <PayInvoicesStepper />
      </PayInvoicesContentContainer>
    </PayInvoicesContainer>
  );
};

export default PayInvoices;
