import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Card, CardRow } from "../../shared/Card";

export const PayrollDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PayrollDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  width: 100%;
  align-items: start;
`;
export const PayrollDetailsHeaderTitleContainer = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
`;
export const PayrollDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const PayrollDetailsSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TransparentRow = styled(CardRow)`
  background-color: transparent;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;
export const DownloadAllButton = styled.a`
  color: ${Colors.text_quarterary_on_brand};
  text-decoration: none;
`;

export const DownloadButtonContainer = styled.a`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_full};
  text-decoration: none;
  background-color: ${Colors.bg_brand_secondary};
`;
export const PayrollDetailsCardRow = styled(CardRow)`
  width: 100%;
`;
export const Header = styled(Card)`
  margin-bottom: 0px;
`;
