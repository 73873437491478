import styled from "styled-components";
import Typography from "../../../shared/Typography";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";

export const EmptyStateContainer = styled.div`
  position: relative;
  display: flex;
  padding-block: ${Spaces.spacing_3xl};
  padding-inline-end: 11px;
  padding-inline-start: ${Spaces.spacing_3xl};
  background-color: ${Colors.Brand_500};
  border-radius: ${Radiuses.radius_md};
  box-sizing: border-box;
`;
export const EmptyStateImg = styled.img`
  width: 50%;
  height: 260px;
  margin-top: calc(-${Spaces.spacing_3xl} + 5px);
  margin-bottom: calc(-${Spaces.spacing_3xl} + 5px);
`;
export const EmptyStateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  flex-wrap: wrap;
`;

export const EmptyStateTitle = styled(Typography)`
  color: ${Colors.text_white};
  white-space: pre;
`;
export const EmptyStateDescription = styled(Typography)`
  color: ${Colors.text_white};
  width: 70%;
`;
