import moment from "moment";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import { Table, TableCell, TableRow } from "../../../shared/Table/styled";
import { TableTextFaded } from "../../InvoiceDetails/styled";
import {
  ButtonsContainer,
  ReviewInvoicesContainer,
  SecondaryHeader,
  TableBoldText,
  TableContainer,
  TableCustomHeader,
  TableFadedText,
} from "./styled";
import Button from "../../../shared/Button";
import { useNavigate } from "react-router-dom";
import { onPayInvoicesNextStep } from "../../../../redux/slices/paymentSlice";

const ReviewInvoices = () => {
  const { selected_invoices: data, totalPayment } = useAppSelector(
    (state) => state.payment
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const renderRows = () => {
    return data.map((invoice) => (
      <TableRow key={invoice.id}>
        <TableCell>
          <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
            {invoice.id}
          </TableBoldText>
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {invoice.description}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {currencyFormatter(invoice.total.amount, invoice.total.currency)}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            {moment(invoice.issue_date).format("MM DD, YYYY")}
          </TableFadedText>
        </TableCell>
        <TableCell>
          <Button
            text="View Details"
            variant={ButtonVariants.secondary_color}
            size={ButtonSizes.sm}
            onClick={() => navigate(`/payment/invoice-details/${invoice.id}`)}
          />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <ReviewInvoicesContainer>
      <TableContainer>
        <Table>
          <TableCustomHeader height="20px">
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Invoice Number
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Description
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Value
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Issue Date
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Actions
              </TableTextFaded>
            </TableCell>
          </TableCustomHeader>
          <SecondaryHeader height="20px">
            <TableCell>
              <TableBoldText
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Total
              </TableBoldText>
            </TableCell>
            <TableCell />
            <TableCell>
              <TableBoldText
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(totalPayment, "USD")}
              </TableBoldText>
            </TableCell>
            <TableCell />
            <TableCell />
          </SecondaryHeader>
          {renderRows()}
        </Table>
      </TableContainer>
      <ButtonsContainer>
        <Button
          text="Cancel"
          variant={ButtonVariants.secondary_color}
          size={ButtonSizes.lg}
          onClick={() => navigate(-1)}
        />
        <Button
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => dispatch(onPayInvoicesNextStep())}
          style={{ width: "35%" }}
        />
      </ButtonsContainer>
    </ReviewInvoicesContainer>
  );
};

export default ReviewInvoices;
