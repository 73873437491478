import {
  LogOutBtn,
  ProfilePicture,
  SideBarEntryContainer,
  SideBarEntryIcon,
  SideBarFooter,
  SidebarContainer,
  UserDataContainer,
  UserEmail,
  UserName,
  SideBarEntryTitle,
  SupportEntriesContainer,
  BottomSection,
  SideBarHeader,
  NotificationBtn,
  EntryRightSide,
  Chevron,
  ChildContainer,
  AppLogo,
} from "./styled";
import LogOut from "../../../Images/icons/log-out.svg";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import {
  removeUser,
  selectUser,
  storeUserData,
} from "../../../redux/slices/authSlice";
import {
  HOME_SCREEN,
  SIDE_BAR_ENTRIES,
  SIDE_BAR_SUPPORT_ENTRIES,
} from "../../../constants/routesNames";
import Logo from "../../../Images/icons/LogoColored.svg";
import NotificationIcon from "../../../Images/icons/notificationIcon.svg";
import { SideBarEntry } from "../../../types";
import ChevronDown from "../../../Images/icons/chevronDown.svg";
import ChevronUp from "../../../Images/icons/chevronUp.svg";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserData } from "../../../queries/auth";
import DropdownList from "../DropdownList";
import { DropdownListOption } from "../../../types/componentsProps";
import { useGetUserCompanies } from "../../../queries/companies";
import { createCompaniesDropdownOptions } from "../../../redux/slices/companiesSlice";
import InitialsProfilePic from "../InitialsProfilePic";

const Sidebar = ({ style }: { style?: React.CSSProperties }) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openTab, setOpenTab] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<string | null>(null);
  const { userCompaniesOptions } = useAppSelector((state) => state.companies);
  const { data: companies, error: companiesError } = useGetUserCompanies();

  const { data: userData } = useGetUserData();

  const [selectedCompany, setSelectedCompany] = useState<DropdownListOption>(
    userCompaniesOptions?.[0]
  );

  useEffect(() => {
    if (userCompaniesOptions) {
      setSelectedCompany(userCompaniesOptions[0]);
    }
  }, [userCompaniesOptions]);

  useEffect(() => {
    if (companies) {
      dispatch(createCompaniesDropdownOptions(companies));
    }
  }, [companies]);

  useEffect(() => {
    if (userData) {
      dispatch(storeUserData(userData));
    }
  }, [userData]);

  const checkActivePath = (
    text: string,
    path: string,
    children?: {
      text: string;
      path: string;
      extraPaths?: string[];
    }[],
    extraPaths?: string[]
  ) => {
    const isChildActive =
      children &&
      children.some(
        (child) =>
          location.pathname === child.path ||
          (child.extraPaths &&
            child.extraPaths.some((p) => location.pathname.includes(p)))
      );

    const isParentActive =
      location.pathname === path ||
      (extraPaths && extraPaths.some((p) => location.pathname.includes(p)));
    if (isChildActive || isParentActive) {
      setOpenTab(path);
      children && setExpanded(text);
    }
  };
  const Entry = ({ text, icon }: { text: string; icon: string }) => {
    return (
      <EntryRightSide>
        <SideBarEntryIcon src={icon} width={24} height={24} />
        <SideBarEntryTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          {text}
        </SideBarEntryTitle>
      </EntryRightSide>
    );
  };

  const EntryChild = ({
    text,
    path,
    extraPaths,
  }: {
    text: string;
    path: string;
    extraPaths?: string[];
  }) => {
    const isActive =
      location.pathname === path ||
      (extraPaths && extraPaths.some((p) => location.pathname.includes(p)));

    return (
      <ChildContainer to={path} active={!!isActive}>
        <SideBarEntryTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          {text}
        </SideBarEntryTitle>
      </ChildContainer>
    );
  };

  const SideBarEntry = ({
    text,
    icon,
    path,
    children,
    extraPaths,
  }: SideBarEntry) => {
    checkActivePath(text, path, children, extraPaths);
    const isActive =
      location.pathname === path ||
      (extraPaths && extraPaths.some((p) => location.pathname.includes(p)));
    const isExpanded = expanded === text;
    return (
      <>
        <SideBarEntryContainer
          to={path || "#"}
          active={isActive || false}
          onClick={(e) => {
            if (!children) {
              navigate(path);
            }
          }}
        >
          <Entry text={text} icon={icon} />

          {children ? (
            isExpanded ? (
              <Chevron
                src={ChevronUp}
                alt="arrow"
                width={20}
                height={20}
                onClick={(e) => {
                  setExpanded(null);
                }}
              />
            ) : (
              <Chevron
                src={ChevronDown}
                alt="arrow"
                width={20}
                height={20}
                onClick={(e) => {
                  setExpanded(text);
                }}
              />
            )
          ) : null}
        </SideBarEntryContainer>
        {expanded === text && children
          ? children.map((child) => {
              return (
                <EntryChild
                  text={child.text}
                  path={child.path}
                  extraPaths={child.extraPaths}
                />
              );
            })
          : null}
      </>
    );
  };

  return (
    <SidebarContainer style={style}>
      <SideBarHeader>
        <AppLogo
          src={Logo}
          alt="logo"
          width={104}
          height={32}
          onClick={() => {
            navigate(HOME_SCREEN);
          }}
        />
        <NotificationBtn
          src={NotificationIcon}
          alt="notification"
          width={36}
          height={36}
        />
      </SideBarHeader>

      <DropdownList
        label=""
        options={userCompaniesOptions}
        selectedOption={selectedCompany}
        setSelectedOption={(company: DropdownListOption) => {
          setSelectedCompany(company);
        }}
      />

      <div>
        {SIDE_BAR_ENTRIES.map((entry) => (
          <SideBarEntry key={entry.text} {...entry} />
        ))}
      </div>

      <BottomSection>
        {/* <SupportEntriesContainer>
          {SIDE_BAR_SUPPORT_ENTRIES.map((entry) => (
            <SideBarEntry key={entry.text} {...entry} />
          ))}
        </SupportEntriesContainer> */}

        <SideBarFooter>
          <UserDataContainer>
            <InitialsProfilePic
              fullName={`${user?.first_name} ${user?.last_name}`}
            />
            <div style={{ marginInlineStart: "1rem" }}>
              <UserName size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {" "}
                {user?.first_name} {user?.last_name}
              </UserName>
              <UserEmail size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
                {" "}
                {user?.email}
              </UserEmail>
            </div>
          </UserDataContainer>
          <LogOutBtn
            src={LogOut}
            alt="logout"
            width={20}
            height={20}
            onClick={() => {
              dispatch(removeUser());
            }}
          />
        </SideBarFooter>
      </BottomSection>
    </SidebarContainer>
  );
};

export default Sidebar;
