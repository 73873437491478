import { useParams } from "react-router-dom";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import {
  CardsColumn,
  DetailsTabContainer,
  PaymentTrackerContainer,
  StepIcon,
  StepIconWrapper,
  StepSubtitle,
  StepTitle,
  StepperConnector,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import { OVARC_BANK_ACCOUNT } from "../../../../mockServer/mockData";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Badge from "../../../shared/Badge";
import { PAYMENT_STATUS_BADGE_COLOR } from "../../../../types";
import ActiveIcon from "../../../../Images/icons/activeStepIcon.svg";
import InactiveIcon from "../../../../Images/icons/InactiveStepperStepGrey.svg";
import CompletedIcon from "../../../../Images/icons/completedStepIcon.svg";
import moment from "moment";
import { useGetPaymentDetails } from "../../../../queries/payment";

const DetailsTab = () => {
  const { payment_id } = useParams();

  const { data: payment } = useGetPaymentDetails(payment_id);

  const renderPaymentTracker = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Payment Tracker
        </CardTitle>
        <PaymentTrackerContainer>
          <StepIconWrapper>
            <StepIcon
              src={CompletedIcon}
              width={32}
              height={32}
              alt="step-icon"
              isCurrent={false}
            />
            <StepperConnector isComplete={true} isLast={false} />
          </StepIconWrapper>

          <div>
            <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payment Initiated
            </StepTitle>
            <StepSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(payment?.created_at).format("DD MMM, YYYY")}
            </StepSubtitle>
          </div>
        </PaymentTrackerContainer>
        {payment?.received_at ? (
          <PaymentTrackerContainer>
            <StepIconWrapper>
              <StepIcon
                src={CompletedIcon}
                width={32}
                height={32}
                alt="step-icon"
                isCurrent={false}
              />
              <StepperConnector isComplete={true} isLast={true} />
            </StepIconWrapper>

            <div>
              <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Payment received
              </StepTitle>
              <StepSubtitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {moment(payment?.created_at).format("DD MMM, YYYY")}
              </StepSubtitle>
            </div>
          </PaymentTrackerContainer>
        ) : (
          <>
            <PaymentTrackerContainer>
              <StepIconWrapper>
                <StepIcon
                  src={ActiveIcon}
                  width={32}
                  height={32}
                  alt="step-icon"
                  isCurrent={false}
                />
                <StepperConnector isComplete={false} isLast={false} />
              </StepIconWrapper>

              <div>
                <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Waiting for your payment
                </StepTitle>
                <StepSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  This may take 2-3 days
                </StepSubtitle>
              </div>
            </PaymentTrackerContainer>
            <PaymentTrackerContainer>
              <StepIconWrapper>
                <StepIcon
                  src={InactiveIcon}
                  width={32}
                  height={32}
                  alt="step-icon"
                  isCurrent={false}
                />
                <StepperConnector isComplete={false} isLast={true} />
              </StepIconWrapper>

              <div>
                <StepTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  Payment received
                </StepTitle>
                <StepSubtitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  We will process your payment as soon as we receive it.
                </StepSubtitle>
              </div>
            </PaymentTrackerContainer>
          </>
        )}
      </Card>
    );
  };

  return (
    <DetailsTabContainer>
      <CardsColumn>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payment Details
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Amount
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {currencyFormatter(
                payment?.total.amount,
                payment?.total.currency
              )}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Status
            </CardRowTitle>
            <Badge
              text={payment?.status || ""}
              dot
              color={PAYMENT_STATUS_BADGE_COLOR[payment?.status || ""]}
            />
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Invoice(s)
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {payment?.invoices.length}
            </CardRowValue>
          </CardRow>
        </Card>

        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Ovarc bank account details
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Transaction Reference
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              2342341431341
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Transfer Method
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Bank Transfer
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Currency
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {OVARC_BANK_ACCOUNT.currency}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Beneficiary
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {OVARC_BANK_ACCOUNT.beneficiary}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Account Number
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {OVARC_BANK_ACCOUNT.account_number}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              SWIFT Code
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {OVARC_BANK_ACCOUNT.swift_code}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              IBAN
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {OVARC_BANK_ACCOUNT.iban}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Address
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {OVARC_BANK_ACCOUNT.address}
            </CardRowValue>
          </CardRow>
        </Card>
      </CardsColumn>

      <CardsColumn>{renderPaymentTracker()}</CardsColumn>
    </DetailsTabContainer>
  );
};

export default DetailsTab;
