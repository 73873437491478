import React, { useState } from "react";
import Typography from "../../shared/Typography";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
  Spaces,
} from "../../../constants/styles";
import { Colors } from "../../../constants/styles";
import { LoginFormContainer, FormRow, Buttons } from "./styled";
import InputField from "../../shared/InputField";
import Checkbox from "../../shared/Checkbox";
import SocialButton from "../../shared/SocialButton";
import Button from "../../shared/Button";
import { RegistrationSteps, emailRegex } from "../../../constants/registration";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../../queries/auth";

import { HOME_SCREEN } from "../../../constants/routesNames";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useGoogleLogin } from "@react-oauth/google";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { storeLoggedIn, storeUser } from "../../../redux/slices/authSlice";
import { USERS } from "../../../mockServer/mockData";

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const { mutateAsync: login, isPending } = useLogin({
    email: formData.email,
    password: formData.password,
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const setFormDataPoint = (key: string, value: string | boolean) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errorObj = {
      email: "",
      password: "",
    };

    if (!formData.email) {
      errorObj.email = "Email is required";
      isValid = false;
    }

    if (!formData.password) {
      errorObj.password = "Password is required";
      isValid = false;
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      errorObj.email = "Invalid email";
      isValid = false;
    }

    if (formData.password && formData.password.length < 8) {
      errorObj.password = "Password must be at least 8 characters";
      isValid = false;
    }

    setError(errorObj);
    return isValid;
  };

  const onSubmit = async () => {
    if (validateForm()) {
      try {
        const data = await login();
        dispatch(storeUser(data.user, data.access_token));
        if (data.user.verified === false) {
          navigate(`/register/${RegistrationSteps.email_confirmation}`);
        }
        dispatch(storeLoggedIn(true));
        navigate(HOME_SCREEN);
      } catch (e: any) {
        if (e.response?.status && e.response.status === 401) {
          setError({
            ...error,
            email: "Invalid email or password",
            password: "Invalid email or password",
          });
        } else {
          showSnackbar({
            open: true,
            title: "Login error",
            description: e.response?.message || "Something went wrong",
            type: SnackbarTypes.ERROR,
          });
        }
      }
    }
  };

  const onGoogleSignIn = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      // const response = await apiClient
      //   .get(
      //     `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${codeResponse.access_token}`,
      //         Accept: "application/json",
      //       },
      //     }
      //   )
      //   .then((res) => {

      //   })
      //   .catch((e) => {
      //     showSnackbar({
      //       open: true,
      //       title: "Login error",
      //       description: e.response.message,
      //       type: SnackbarTypes.ERROR,
      //     });
      //   });

      dispatch(
        storeUser(
          {
            ...USERS[0],
          },
          codeResponse.access_token
        )
      );
      dispatch(storeLoggedIn(true));
      navigate(HOME_SCREEN);
    },
    onError: (e: any) => {
      showSnackbar({
        open: true,
        title: "Login error",
        description: e.response.message,
        type: SnackbarTypes.ERROR,
      });
    },
  });

  return (
    <LoginFormContainer>
      <InputField
        label="Email"
        type="email"
        placeholder="Enter your email"
        name="email"
        containerStyle={{ marginBottom: Spaces.spacing_2xl }}
        value={formData.email}
        onChange={(e) => {
          setFormDataPoint("email", e.target.value);
        }}
        error={error.email}
        onFocus={() => {
          setError({
            ...error,
            email: "",
          });
        }}
      />
      <InputField
        label="Password"
        type="password"
        name="password"
        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
        containerStyle={{ marginBottom: Spaces.spacing_3xl }}
        value={formData.password}
        onChange={(e) => {
          setFormDataPoint("password", e.target.value);
        }}
        error={error.password}
        onFocus={() => {
          setError({
            ...error,
            password: "",
          });
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />

      <FormRow>
        <Checkbox
          label="Remember for 30 days"
          name="checkbox"
          checked={formData.rememberMe}
          onChange={() => {
            setFormDataPoint("rememberMe", !formData.rememberMe);
          }}
        />

        <Typography
          size={FontSizes.TX_SM}
          weight={FontWeights.SEMIBOLD}
          style={{
            color: Colors.button_tertiary_color_fg,
            cursor: "pointer",
          }}
          tabIndex={0}
        >
          Forgot password
        </Typography>
      </FormRow>

      <Buttons>
        <Button
          size={ButtonSizes.lg}
          variant={ButtonVariants.primary}
          text="Sign in"
          onClick={onSubmit}
          loading={isPending}
        />
        <SocialButton
          social="google"
          text="Sign in with Google"
          onClick={() => onGoogleSignIn()}
        />
      </Buttons>
    </LoginFormContainer>
  );
};

export default LoginForm;
