import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const CardContainerData = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_md};
  margin-top: ${Spaces.spacing_2xl};
`;

export const CardDataRow = styled.div`
  display: flex;
  gap: ${Spaces.spacing_md};
  flex-direction: row;
  align-items: center;
`;

export const CardDataText = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const CardDataDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${(props) => props.color};
`;
