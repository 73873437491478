import React, { useEffect, useState } from "react";
import { PaymentContainer, PaymentHeader, PaymentTitle } from "./styled";
import { FontSizes, FontWeights } from "../../constants/styles";
import { PayrollSubtitle } from "../Payroll/styled";
import PaymentEmptyState from "../../components/payment/PaymentEmptyState";
import Tabs from "../../components/shared/Tabs";
import InvoicesDue from "../../components/payment/InvoicesDue";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import { Outlet, useLocation } from "react-router-dom";
import PaymentsList from "../../components/payment/PaymentsList";
import { useGetCategorizedInvoices } from "../../queries/payment";
import { setInvoicesList } from "../../redux/slices/paymentSlice";
import { useSnackbar } from "../../hooks/useSnackbar";
import { SnackbarTypes } from "../../types/componentsProps";

const PaymentScreen: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOutlet, setIsOutlet] = useState(false);
  const { invoices_due_list, invoices_due_tableVariables } = useAppSelector(
    (state) => state.payment
  );
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();

  const { data: invoices, error } = useGetCategorizedInvoices({
    sort: {
      sortKey: invoices_due_tableVariables.sortKey,
      sortDirection: invoices_due_tableVariables.sortDirection,
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(setInvoicesList([]));
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching invoices.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  useEffect(() => {
    if (invoices) {
      dispatch(setInvoicesList(invoices));
    }
  }, [invoices]);

  useEffect(() => {
    setIsOutlet(
      location.pathname.includes("invoice-details") ||
        location.pathname.includes("pay-invoices") ||
        location.pathname.includes("payment-details")
    );
  }, [location]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <InvoicesDue />;
      case 1:
        return <PaymentsList />;
      default:
        return null;
    }
  };

  return (
    <PaymentContainer>
      {isOutlet ? (
        <Outlet />
      ) : (
        <>
          {" "}
          <PaymentHeader>
            <PaymentTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
              Payment
            </PaymentTitle>
            <PayrollSubtitle
              size={FontSizes.TX_MD}
              weight={FontWeights.REGULAR}
            >
              View and manage payments here{" "}
            </PayrollSubtitle>
            <Tabs
              tabsTitles={["Invoices due", "Payments"]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </PaymentHeader>
          {invoices_due_list.length > 0 ? (
            renderTabContent()
          ) : (
            <PaymentEmptyState />
          )}
        </>
      )}
    </PaymentContainer>
  );
};

export default PaymentScreen;
