import { ALL_ASSETS_STATUS_OPTIONS } from "../../../mockServer/mockData";
import SearchInput from "../../shared/SearchInput";
import {
  AssetsListContainer,
  AssetsListHeader,
  AssetsListTableContainer,
  TableBoldText,
  TableFadedText,
} from "./styled";

import { FontSizes, FontWeights } from "../../../constants/styles";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
} from "../../shared/Table/styled";
import Badge from "../../shared/Badge";
import { ASSETS_LIST_STATUS_BADGE_COLOR } from "../../../types";

import ActionsDropDown from "../../shared/ActionsDropDown";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../../shared/ProgressBar";
import DropdownList from "../../shared/DropdownList";
import { ChangeEvent, useEffect } from "react";
import {
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { editAssetsListVariables } from "../../../redux/slices/assetsHubSlice";
import TablePagination from "../../shared/TablePagination";
import { useGetAssetHubList } from "../../../queries/assetsHub";
import { AxiosError } from "axios";
import { useSnackbar } from "../../../hooks/useSnackbar";

const AssetsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const { selectedStatus, search, currentPage, totalPages, itemsPerPage } =
    useAppSelector((state) => state.assetsHub.assetsListVariables);

  const activeCompany = useAppSelector(
    (state) => state.companies.activeCompany
  );

  const { data: list, error } = useGetAssetHubList({
    company_id: activeCompany?.id ?? "",
    filter: {
      status: selectedStatus.label,
    },
    ...(search && { search }),

    page: currentPage,
    page_size: itemsPerPage,
  });

  useEffect(() => {
    if (list?.data) {
      dispatch(
        editAssetsListVariables({
          currentPage: list.data._metadata.page,
          totalRecords: list.data._metadata.total_records,
          totalPages: list.data._metadata.last_page,
        })
      );
    }
  }, [list]);

  useEffect(() => {
    if (error) {
      dispatch(editAssetsListVariables({ filteredAssets: null }));
      const err = error as unknown as AxiosError;
      switch (err.response?.status) {
        case 401:
          navigate("/login");
          break;
        case 403:
          navigate("/404");
          break;
        default:
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while fetching assets.",
            description: "Please try again later.",
          });
          break;
      }
    }
  }, [error]);
  const renderRows = () => {
    return list?.data.assets.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <div>
              <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {item.name}
              </TableBoldText>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.REGULAR}
              >
                {item.type}
              </TableFadedText>
            </div>
          </TableCell>

          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {item.brand}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Badge
              dot
              text={item.status}
              color={ASSETS_LIST_STATUS_BADGE_COLOR[item.status]}
            />
          </TableCell>
          <TableCell>
            {item.assignee ? (
              <div>
                <TableBoldText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  {item.assignee.name}
                </TableBoldText>
                <TableFadedText
                  size={FontSizes.TX_XS}
                  weight={FontWeights.REGULAR}
                >
                  {item.assignee.email}
                </TableFadedText>
              </div>
            ) : (
              <TableFadedText
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                Unassigned
              </TableFadedText>
            )}
          </TableCell>
          <TableCell
            style={{
              width: "20%",
            }}
          >
            <ProgressBar condition={item.condition} />
          </TableCell>
          <TableCell>
            <ActionsDropDown
              actions={[
                {
                  text: "View",
                  onSelect: () => {
                    navigate(`/assets-hub/asset-details/${item.id}`);
                  },
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <AssetsListTableContainer>
        <Table>
          <TableHeader>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Asset
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Brand
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Status
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Assignee
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Condition
              </TableFadedText>
            </TableCell>
            <TableCell></TableCell>
          </TableHeader>
          {renderRows()}
          {totalPages > 1 && (
            <TableRowDynamic height="68px">
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={(page: number) =>
                  dispatch(editAssetsListVariables({ currentPage: page }))
                }
                totalPages={totalPages}
              />
            </TableRowDynamic>
          )}
        </Table>
      </AssetsListTableContainer>
    );
  };

  return (
    <AssetsListContainer>
      <AssetsListHeader>
        <SearchInput
          placeholder="Search Assets"
          containerStyle={{
            backgroundColor: "white",
            height: "40px",
            boxSizing: "border-box",
            width: "30%",
          }}
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch(
              editAssetsListVariables({
                search: e.target.value,
              })
            )
          }
        />
        <DropdownList
          options={ALL_ASSETS_STATUS_OPTIONS}
          selectedOption={selectedStatus}
          setSelectedOption={(status: DropdownListOption) =>
            dispatch(
              editAssetsListVariables({
                selectedStatus: status,
                currentPage: 1,
              })
            )
          }
          containerStyle={{ width: "30%" }}
          style={{ height: "48px", marginTop: "0px" }}
        />
      </AssetsListHeader>
      {renderTable()}
    </AssetsListContainer>
  );
};

export default AssetsList;
