import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import { Card, CardHeader, CardTitle } from "../../../shared/Card";
import {
  AddAssetButton,
  CloseIcon,
  EquipmentStepButtonsContainer,
  EquipmentStepContainer,
  NextButton,
} from "./styled";
import { useNavigate } from "react-router-dom";
import {
  addRequestedAsset,
  deleteRequestedAsset,
  editRequestedAsset,
  editRequestedAssetAssignee,
  editRequestedAssetLocation,
  onNextRequestAssetStep,
} from "../../../../redux/slices/assetsHubSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import Plus from "../../../../Images/icons/plusBlue.svg";
import DropdownList from "../../../shared/DropdownList";
import InputField from "../../../shared/InputField";
import { ASSETS_CATEGORIES_OPTIONS } from "../../../../mockServer/mockData";
import Close from "../../../../Images/icons/x-close.svg";
import { useGetActiveEmployees } from "../../../../queries/people";
import { useEffect, useState } from "react";
import { DropdownListOption } from "../../../../types/componentsProps";

const EquipmentStep = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { requestedAssets } = useAppSelector((state) => state.assetsHub);
  const [activeEmployeesOptions, setActiveEmployeesOptions] = useState<
    DropdownListOption[]
  >([]);

  const { data: activeEmployees, error: activeEmployeesError } =
    useGetActiveEmployees();
  useEffect(() => {
    if (activeEmployees?.data) {
      let options = activeEmployees.data.employees.map((employee) => {
        return {
          label: employee.user.first_name + " " + employee.user.last_name,
          value: employee.id,
        };
      });
      setActiveEmployeesOptions(options);
    }
  }, [activeEmployees]);
  const checkDisableNext = () => {
    return (
      requestedAssets.assets.some(
        (asset) =>
          asset.specifications === "" ||
          asset.brand === "" ||
          asset.model === ""
      ) ||
      requestedAssets.location === "" ||
      requestedAssets.assignee.value === ""
    );
  };
  const renderAssetsInfo = () => {
    return requestedAssets.assets.map((asset, index) => {
      return (
        <Card>
          <CardHeader>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Asset Information
            </CardTitle>
            {index !== 0 && (
              <CloseIcon
                src={Close}
                alt="Close"
                onClick={() => {
                  dispatch(deleteRequestedAsset(index));
                }}
              />
            )}
          </CardHeader>

          <DropdownList
            label="Category/Type"
            containerStyle={{ marginBottom: "0.5rem" }}
            options={ASSETS_CATEGORIES_OPTIONS}
            selectedOption={requestedAssets.assets[index].category}
            setSelectedOption={(option) => {
              dispatch(
                editRequestedAsset({
                  index,
                  asset: { category: option },
                })
              );
            }}
          />
          <InputField
            label="Brand"
            placeholder="Canon"
            containerStyle={{ marginBottom: "0.5rem" }}
            value={asset.brand}
            onChange={(e) => {
              dispatch(
                editRequestedAsset({
                  index,
                  asset: { brand: e.target.value },
                })
              );
            }}
          />
          <InputField
            label="Model/Make"
            placeholder="Inkjet Printer"
            containerStyle={{ marginBottom: "0.5rem" }}
            value={asset.model}
            onChange={(e) => {
              dispatch(
                editRequestedAsset({
                  index,
                  asset: { model: e.target.value },
                })
              );
            }}
          />
          <InputField
            label="Specifications"
            placeholder="Inkjet, Lightweight, Bluetooth, Wi-Fi, Black."
            value={asset.specifications}
            onChange={(e) => {
              dispatch(
                editRequestedAsset({
                  index,
                  asset: { specifications: e.target.value },
                })
              );
            }}
          />
        </Card>
      );
    });
  };
  return (
    <EquipmentStepContainer>
      {renderAssetsInfo()}
      <AddAssetButton
        text="Add Another Asset"
        variant={ButtonVariants.secondary_gray}
        size={ButtonSizes.lg}
        iconLeading={<img src={Plus} alt="Add Asset" width={20} height={20} />}
        onClick={() => {
          dispatch(addRequestedAsset());
        }}
      />
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Location and Assignment
        </CardTitle>

        <InputField
          label="Location"
          placeholder="IT Department"
          containerStyle={{ marginBottom: "0.5rem" }}
          value={requestedAssets.location}
          onChange={(e) => {
            dispatch(editRequestedAssetLocation(e.target.value));
          }}
        />
        <DropdownList
          label="Assignee"
          containerStyle={{ marginBottom: "0.5rem" }}
          options={activeEmployeesOptions}
          selectedOption={requestedAssets.assignee}
          setSelectedOption={(option) => {
            dispatch(editRequestedAssetAssignee(option));
          }}
        />
      </Card>
      <EquipmentStepButtonsContainer>
        <Button
          text="Cancel"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          onClick={() => navigate(-1)}
        />
        <NextButton
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => {
            dispatch(onNextRequestAssetStep());
          }}
          disabled={checkDisableNext()}
        />
      </EquipmentStepButtonsContainer>
    </EquipmentStepContainer>
  );
};

export default EquipmentStep;
