import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategorizedInvoices, Invoice, UserPayment } from "../../types";
import { PAYMENTS_LIST } from "../../mockServer/mockData";
import { sortDirection } from "../../types/apisTypes";
import { AppDispatch, RootState } from "../store";
export const PAYROLL_PROCESS_STEPS = [
  {
    title: "Review Payment",
    completed: false,
  },
  {
    title: "Choose how you want to pay",
    completed: false,
  },
  {
    title: "Choose Manual Transfer",
    completed: false,
  },
  {
    title: "Confirm payment",
    completed: false,
  },
];
interface PaymentState {
  invoices_due_list: CategorizedInvoices;
  payments_list: UserPayment[];
  selected_invoices: Invoice[];
  invoices_due_tableVariables: {
    sortKey: string;
    sortDirection: sortDirection;
  };
  payments_table_Variables: {
    currentPage: number;
    itemsPerPage: number;
    totalRecords: number;
    totalPages: number;
  };
  pay_invoices_steps: {
    title: string;
    completed: boolean;
  }[];
  pay_invoices_variables: {
    currency: string;
    paymentMethod: string;
    manualTransferMethod: string;
    transactionReferenceNumber: string;
  };

  current_pay_invoices_step: number;
  totalPayment: number;
}

const initialState: PaymentState = {
  invoices_due_list: [],
  payments_list: PAYMENTS_LIST,
  selected_invoices: [],
  invoices_due_tableVariables: {
    sortKey: "id",
    sortDirection: "ascending",
  },
  payments_table_Variables: {
    currentPage: 1,
    itemsPerPage: 7,
    totalRecords: 0,
    totalPages: 0,
  },
  pay_invoices_steps: PAYROLL_PROCESS_STEPS,
  pay_invoices_variables: {
    currency: "USD",
    paymentMethod: "Manual Transfer",
    manualTransferMethod: "Bank Transfer",
    transactionReferenceNumber: "",
  },

  current_pay_invoices_step: 0,
  totalPayment: 0,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setInvoicesList: (state, action: PayloadAction<CategorizedInvoices>) => {
      state.invoices_due_list = action.payload;
    },
    setSelectedInvoices: (state, action: PayloadAction<Invoice[]>) => {
      state.selected_invoices = action.payload;
    },
    selectCategoryInvoices: (state, action: PayloadAction<string>) => {
      const categoryInvoices = state.invoices_due_list
        .filter((invoice) => invoice.category === action.payload)
        .map((invoice) => invoice.invoices)
        .flat();

      if (
        categoryInvoices.every((invoice) =>
          state.selected_invoices.find(
            (selectedInvoice) => selectedInvoice.id === invoice.id
          )
        )
      ) {
        state.selected_invoices = state.selected_invoices.filter(
          (invoice) =>
            !categoryInvoices.find(
              (selectedInvoice) => selectedInvoice.id === invoice.id
            )
        );
        return;
      }

      state.selected_invoices = [
        ...state.selected_invoices,
        ...categoryInvoices,
      ];
    },
    selectAllInvoices: (state) => {
      const allInvoices = state.invoices_due_list
        .map((invoice) => invoice.invoices.map((invoice) => invoice.id))
        .flat();
      if (
        allInvoices.every((invoice) =>
          state.selected_invoices.find(
            (selectedInvoice) => selectedInvoice.id === invoice
          )
        )
      ) {
        state.selected_invoices = [];
        return;
      }
      state.selected_invoices = state.invoices_due_list
        .map((invoice) => invoice.invoices.map((invoice) => invoice))
        .flat();
    },
    selectSingleInvoice: (state, action: PayloadAction<Invoice>) => {
      if (
        state.selected_invoices.find(
          (selectedInvoice) => selectedInvoice.id === action.payload.id
        )
      ) {
        state.selected_invoices = state.selected_invoices.filter(
          (invoice) => invoice.id !== action.payload.id
        );
        return;
      }
      state.selected_invoices.push(action.payload);
    },
    updateInvoicesDueTableVariables: (
      state,
      action: PayloadAction<
        Partial<PaymentState["invoices_due_tableVariables"]>
      >
    ) => {
      state.invoices_due_tableVariables = {
        ...state.invoices_due_tableVariables,
        ...action.payload,
      };
    },
    updatePaymentProcessSteps: (
      state,
      action: PayloadAction<PaymentState["pay_invoices_steps"]>
    ) => {
      state.pay_invoices_steps = action.payload;
    },

    setCurrentPayInvoicesStep: (state, action: PayloadAction<number>) => {
      state.current_pay_invoices_step = action.payload;
    },
    setTotalPayment: (state, action: PayloadAction<number>) => {
      state.totalPayment = action.payload;
    },
    updatePayInvoicesVariables: (
      state,
      action: PayloadAction<Partial<PaymentState["pay_invoices_variables"]>>
    ) => {
      state.pay_invoices_variables = {
        ...state.pay_invoices_variables,
        ...action.payload,
      };
    },
  },
});

export const onPayInvoicesNextStep =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const payment = getState().payment;
    const { current_pay_invoices_step, pay_invoices_steps } = payment;
    const newProcessSteps = pay_invoices_steps.map(
      (step: any, index: number) => {
        if (index === current_pay_invoices_step) {
          return { ...step, completed: true };
        }
        return step;
      }
    );
    dispatch(updatePaymentProcessSteps(newProcessSteps));
    dispatch(setCurrentPayInvoicesStep(current_pay_invoices_step + 1));
  };

export const onPayInvoicesPreviousStep =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const payment = getState().payment;
    const { current_pay_invoices_step, pay_invoices_steps } = payment;
    const newProcessSteps = pay_invoices_steps.map(
      (step: any, index: number) => {
        if (index === current_pay_invoices_step) {
          return { ...step, completed: false };
        }
        return step;
      }
    );
    dispatch(updatePaymentProcessSteps(newProcessSteps));
    dispatch(setCurrentPayInvoicesStep(current_pay_invoices_step - 1));
  };

export const calculateTotalPayment =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const payment = getState().payment;
    const totalPayment = payment.selected_invoices.reduce(
      (acc, invoice) => acc + invoice.total.amount,
      0
    );
    dispatch(setTotalPayment(totalPayment));
  };
export const {
  updateInvoicesDueTableVariables,
  updatePaymentProcessSteps,
  setSelectedInvoices,
  selectCategoryInvoices,
  selectAllInvoices,
  selectSingleInvoice,
  setCurrentPayInvoicesStep,
  setTotalPayment,
  setInvoicesList,
  updatePayInvoicesVariables,
} = paymentSlice.actions;

export default paymentSlice.reducer;
