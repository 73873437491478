import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import EmptyActionCard from "../ActionCard";
import { ActionCardContainer, ActionCardTitle } from "../ActionCard/styled";
import {
  CardDataContainer,
  CardDataLabel,
  CardDataValue,
  CardHeader,
} from "./styled";

const UpcomingPayrollCard = () => {
  const upcomingPayroll = [{ id: 1 }];
  return upcomingPayroll.length ? (
    <ActionCardContainer>
      <CardHeader>
        <ActionCardTitle size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
          Upcoming Payroll
        </ActionCardTitle>

        <Button
          text="Review"
          onClick={() => console.log("Payroll")}
          size={ButtonSizes.sm}
          variant={ButtonVariants.primary}
          style={{ width: "fit-content" }}
        />
      </CardHeader>
      <CardDataContainer>
        <div>
          <CardDataLabel size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Cycle
          </CardDataLabel>
          <CardDataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            1 Feb 2024 - 28 Feb 2024
          </CardDataValue>
        </div>
        <div>
          <CardDataLabel size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Due Date
          </CardDataLabel>
          <CardDataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            25 Jan 2024
          </CardDataValue>
        </div>
      </CardDataContainer>
    </ActionCardContainer>
  ) : (
    <EmptyActionCard
      title="Upcoming Payroll"
      description="Effortlessly monitor and manage your employees."
      btnText="View Payroll"
      onClick={() => console.log("Payroll")}
    />
  );
};

export default UpcomingPayrollCard;
