import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetPayrollListParams,
  GetPayrollListResponse,
  GetPayrollReviewEmployees,
  GetPayrollReviewEmployeesResponse,
  SubmitPayrollParams,
} from "../types/apisTypes";
import { apiClient } from "./apiClient";
import qs from "qs";
import {
  PayrollDetails,
  PayrollReview,
  UserPaymentProcessReview,
} from "../types";

export const useGetPayrolls = (params: GetPayrollListParams) => {
  return useQuery<GetPayrollListResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll?${qs.stringify(params)}`);
      return response.data;
    },
    retry: false,

    queryKey: ["payrolls", params],
  });
};

export const useGetPayrollReview = (id: string | undefined) => {
  return useQuery<PayrollReview, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll/${id || ""}`);
      return response.data.data.review;
    },
    retry: false,
    queryKey: ["payrollReview", id],
    enabled: !!id,
  });
};

export const useGetPayrollReviewEmployees = (
  id: string | undefined,
  params: GetPayrollReviewEmployees
) => {
  return useQuery<GetPayrollReviewEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(
        `/payroll/${id || ""}/employee?${qs.stringify(params)}`
      );
      return response.data;
    },
    retry: false,
    queryKey: ["payrollReviewEmployees", id, params],
    enabled: !!id,
  });
};

export const useAddBonus = () => {
  return useMutation<
    void,
    Error,
    {
      payroll_id: string;
      employeeId: string;
      data: { type: string; price: number }[];
    }
  >({
    mutationFn: async ({ payroll_id, employeeId, data }) => {
      const response = await apiClient.post(
        `/payroll/${payroll_id}/employee/${employeeId}/bonus`,
        data
      );
      return response.data;
    },
  });
};

export const useGetPayrollDetails = (id: string | undefined) => {
  return useQuery<PayrollDetails, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll-details/${id || ""}`);
      return response.data.data.payrollDetails;
    },
    retry: false,
    queryKey: ["payrollDetails", id],
    enabled: !!id,
  });
};

export const useGetPayrollProcess = () => {
  return useQuery<UserPaymentProcessReview, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payroll/process`);
      return response.data.data.payrollProcess;
    },
    retry: false,
    queryKey: ["payrollProcess"],
  });
};

export const useSubmitPayroll = () => {
  return useMutation<void, Error, SubmitPayrollParams>({
    mutationFn: async (data) => {
      const response = await apiClient.post(`/payroll/submit`, data);
      return response.data;
    },
  });
};
