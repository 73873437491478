import {
  BadgeTypes,
  ModalProps,
  SnackbarTypes,
} from "../../../../../types/componentsProps";

import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../../constants/styles";
import {
  ContentContainer,
  ModalFields,
  ModalFooter,
  ModalHeader,
  Title,
  EmptyStateAssetsContainer,
  EmptyStateAssetsCenterImg,
  EmptyStateAssetsTitle,
  EmptyStateAssetsDescription,
  EmptyStateAssetsButtons,
  AssetTitle,
  AssetType,
  UnAssignAssetButton,
} from "../../styled";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../../shared/Table/styled";
import { useEffect, useState } from "react";
import Close from "../../../../../Images/icons/alertClose.svg";

import Button from "../../../../shared/Button";
import Monitor from "../../../../../Images/icons/monitor.svg";
import Typography from "../../../../shared/Typography";
import Badge from "../../../../shared/Badge";
import SearchInput from "../../../../shared/SearchInput";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/reduxHooks";
import {
  useAssignAsset,
  useGetAvailableAssets,
} from "../../../../../queries/people";
import { setAvailableAssets } from "../../../../../redux/slices/peopleSlice";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const AddAssetsModal = ({
  onClose,
  cancelBtn = true,
  btnText = "Confirm",
}: {
  onClose: ModalProps["onClose"];
  cancelBtn?: boolean;
  btnText?: string;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const assetsOptions = useAppSelector((state) => state.people.availableAssets);
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { id: employeeId } = useParams();
  const { data: availableAssets, error } = useGetAvailableAssets();
  const { mutateAsync: assignAsset } = useAssignAsset();
  const queryClient = useQueryClient();
  const [selectedAssets, setSelectedAssets] = useState(assetsOptions);

  useEffect(() => {
    if (assetsOptions) {
      setSelectedAssets(assetsOptions);
    }
  }, [assetsOptions]);

  useEffect(() => {
    if (availableAssets) {
      dispatch(setAvailableAssets(availableAssets.data.assets));
    }
  }, [availableAssets]);

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch  assets",
      });
    }
  }, [error]);

  const onAssignAsset = async (asset_id: string) => {
    try {
      await assignAsset({ employeeId: employeeId || "", asset_id });
      queryClient.invalidateQueries({
        queryKey: ["employeeAssets", employeeId],
      });
      queryClient.invalidateQueries({
        queryKey: ["AllAssets"],
      });

      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Asset assigned successfully",
        description: "",
      });
      onClose();
    } catch (error) {
      onClose();
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to assign asset",
      });
    }
  };

  const EmptyState = () => {
    return (
      <EmptyStateAssetsContainer>
        <EmptyStateAssetsCenterImg src={Monitor} width={48} height={48} />
        <EmptyStateAssetsTitle
          size={FontSizes.TX_MD}
          weight={FontWeights.SEMIBOLD}
        >
          Seems that you didn’t add assets yet!
        </EmptyStateAssetsTitle>
        <EmptyStateAssetsDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          You can add assets from Assets Hub and then you{"\n"} can assign them
          to your employees.
        </EmptyStateAssetsDescription>
        <EmptyStateAssetsButtons>
          <Button
            text="Cancel"
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_gray}
            onClick={onClose}
          />
          <Button
            text="Go to Assets Hub"
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            onClick={() => {}}
          />
        </EmptyStateAssetsButtons>
      </EmptyStateAssetsContainer>
    );
  };

  const onConfirm = () => {};

  const renderAssetRow = () => {
    return selectedAssets?.map((asset) => {
      return (
        <TableRow>
          <TableCell>
            <AssetTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {asset.name}
            </AssetTitle>
            <AssetType size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {asset.type} - {asset.brand}
            </AssetType>
          </TableCell>
          <TableCell>
            <Badge
              dot
              text={asset.employeeStatus}
              color={
                asset.employeeStatus === "In-Active"
                  ? BadgeTypes.gray
                  : BadgeTypes.blueLight
              }
            />
          </TableCell>

          <TableCell>
            {asset.employeeStatus === "In-Active" ? (
              <Button
                text="Assign"
                size={ButtonSizes.sm}
                variant={ButtonVariants.secondary_color}
                onClick={() => {
                  onAssignAsset(asset.id);
                }}
              />
            ) : (
              <UnAssignAssetButton onClick={() => {}}>
                <Typography
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  Unassign
                </Typography>
              </UnAssignAssetButton>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };
  const renderAssets = () => {
    return (
      <>
        <ModalFields>
          <SearchInput
            value={searchValue}
            onChange={(e: any) => {
              setSearchValue(e.target.value);
              setSelectedAssets(
                assetsOptions?.filter(
                  (asset) =>
                    asset.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    asset.type
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                ) || []
              );
            }}
            placeholder="Search for assets"
          />

          <Table>
            <TableHeader>
              <TableCell>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Asset
                </Typography>
              </TableCell>

              <TableCell>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Status
                </Typography>
              </TableCell>

              <TableCell>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  Action
                </Typography>
              </TableCell>
            </TableHeader>
            {renderAssetRow()}
          </Table>
        </ModalFields>
        <ModalFooter>
          {cancelBtn && (
            <Button
              text="Cancel"
              size={ButtonSizes.md}
              variant={ButtonVariants.secondary_gray}
              onClick={onClose}
            />
          )}

          <Button
            text={btnText}
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            onClick={onConfirm}
          />
        </ModalFooter>
      </>
    );
  };
  return (
    <ContentContainer>
      <ModalHeader>
        <Title size={FontSizes.TX_XL} weight={FontWeights.SEMIBOLD}>
          Add Asset(s)
        </Title>
        <img src={Close} alt="Close" onClick={onClose} />
      </ModalHeader>

      {assetsOptions?.length === 0 || !assetsOptions ? (
        <EmptyState />
      ) : (
        renderAssets()
      )}
    </ContentContainer>
  );
};

export default AddAssetsModal;
