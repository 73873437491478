import styled from "styled-components";
import {
  Colors,
  FontSizes,
  FontWeights,
  Radiuses,
  Spaces,
} from "../../../constants/styles";
import Typography from "../Typography";

export const Card = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  padding-inline: ${Spaces.spacing_3xl};
  padding-block: ${Spaces.spacing_2xl} 20px;
  border-radius: ${Radiuses.radius_md};
  background-color: ${Colors.bg_primary};
  border: 1px solid ${Colors.border_secondary};
  height: fit-content;
  box-sizing: border-box;
  min-width: 350px;
  margin-bottom: ${Spaces.spacing_md};
`;
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spaces.spacing_xl};
  align-items: center;
`;

export const CardTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xl};
`;

export const CardSubtitle = styled(Typography)`
  color: ${Colors.bg_brand_solid};
  margin-bottom: ${Spaces.spacing_sm};
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spaces.spacing_md} ${Spaces.spacing_xl};
  background-color: ${Colors.bg_primary_hover};
  border-radius: ${Radiuses.radius_md};
  align-items: center;
  margin-bottom: ${Spaces.spacing_sm};
`;

export const CardRowTitle = styled(Typography)`
  color: ${Colors.text_secondary_700};
`;

export const CardRowValue = styled(Typography)`
  color: ${Colors.text_secondary_hover};
  white-space: pre;
  text-align: right;
`;

export const CardRowSubValue = styled.span`
  color: ${Colors.text_secondary_hover};
  font-size: ${FontSizes.TX_SM};
  font-weight: ${FontWeights.REGULAR};
`;

export const CardRowValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${Spaces.spacing_6xl};
`;

export const CardTransparentRow = styled(CardRow)`
  background-color: transparent;
`;
