import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { Card } from "../../../shared/Card";

export const PayslipDetails = styled.div`
  width: 100%;
  padding-block: ${Spaces.spacing_3xl};
`;
export const PayslipDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block-end: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;
export const PayslipDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const PayslipDetailsHeaderButtons = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 23px;
  flex-wrap: wrap;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;

export const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${Radiuses.radius_md};
  gap: ${Spaces.spacing_sm};
  cursor: pointer;
  text-decoration: none;
  background-color: ${Colors.button_secondary_bg};
  color: ${Colors.button_secondary_color_fg};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid ${Colors.button_secondary_color_border};
  height: 40px;
  padding: 8px 14px;
  box-sizing: border-box;

  &:hover {
    background-color: ${Colors.button_secondary_color_bg_hover};
  }
  &:focus {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(158, 119, 237, 0.24);
  }

  &:disabled {
    background-color: ${Colors.bg_primary};
    color: ${Colors.fg_disabled};
    border: 1px solid ${Colors.border_disabled_subtle};
  }
`;
