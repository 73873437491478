import {
  ContractContainer,
  Banner,
  BannerDescription,
  CardsContainer,
  ContractName,
  Signature,
  ContractFileContainer,
  ContractFileName,
  DownloadButton,
  CardsColumn,
  CancelButton,
} from "./styled";
import {
  Card,
  CardRow,
  CardRowSubValue,
  CardRowTitle,
  CardRowValue,
  CardSubtitle,
  CardTitle,
} from "../../../shared/Card";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Alert from "../../../../Images/icons/alertTriangleOutline.svg";
import PDF from "../../../../Images/icons/pdf.svg";
import DownLoad from "../../../../Images/icons/download-.svg";
import moment from "moment";
import Typography from "../../../shared/Typography";
import PeopleEmptyState from "../../PeopleEmptyState";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { useGetEmployeeContract } from "../../../../queries/people";
import { setActiveEmployeeContract } from "../../../../redux/slices/peopleSlice";

const EmployeeContract = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { activeEmployeeContract, activeEmployee } = useAppSelector(
    (state) => state.people
  );

  const { data: contract, error } = useGetEmployeeContract(id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (contract) {
      dispatch(setActiveEmployeeContract(contract.data.contract));
    }
  }, [contract]);

  useEffect(() => {
    return () => {
      dispatch(setActiveEmployeeContract(null));
    };
  }, [error]);

  const renderSignature = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Signature
        </CardTitle>
        <CardSubtitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
          Ovarc
        </CardSubtitle>
        <CardRow>
          <div>
            <ContractName size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Sherry Karam
            </ContractName>
            <ContractName size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Ovarc CEO
            </ContractName>
            <ContractName size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              people@ovarc.io
            </ContractName>
          </div>
          <Signature>Sherry karam</Signature>
        </CardRow>
      </Card>
    );
  };

  const renderDocuments = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Documents
        </CardTitle>
        <div>
          {activeEmployeeContract?.docs.map((document) => (
            <ContractFileContainer key={document.id}>
              <img src={PDF} />
              <ContractFileName
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {document.name}
              </ContractFileName>
              <DownloadButton>
                <img src={DownLoad} />
              </DownloadButton>
            </ContractFileContainer>
          ))}
        </div>
      </Card>
    );
  };

  const renderAgreementDetails = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Agreement Details
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Start Date
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(
              new Date(
                activeEmployeeContract?.assignment_details.start_date || ""
              )
            ).format("D MMM, YYYY")}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Created on{" "}
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(
              new Date(
                activeEmployeeContract?.assignment_details.created_at || ""
              )
            ).format("D MMM, YYYY")}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Job Title
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {activeEmployeeContract?.assignment_details.job_title}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Seniority level
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {activeEmployeeContract?.assignment_details.seniority_level}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Employment Type
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {activeEmployeeContract?.assignment_details.employment_type} {"\n"}
            <CardRowSubValue>
              {activeEmployeeContract?.assignment_details.hours_per_week} hours
              per week
            </CardRowSubValue>
          </CardRowValue>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Company
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {activeEmployeeContract?.assignment_details.company}
          </CardRowValue>
        </CardRow>
      </Card>
    );
  };

  const renderContractEnd = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Contract End
        </CardTitle>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            End Date
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {activeEmployeeContract?.contract_end.end_date}
          </CardRowValue>
        </CardRow>

        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Probation Period
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {activeEmployeeContract?.contract_end.probation_period}
          </CardRowValue>
        </CardRow>
        <CardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Last day of probation period
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            {moment(
              new Date(
                activeEmployeeContract?.contract_end.last_day_of_probation || ""
              )
            ).format("D MMM, YYYY")}
          </CardRowValue>
        </CardRow>
        {activeEmployee?.employee_status === "Draft" && (
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Cancelation
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              <CancelButton>
                <Typography
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  Cancel Contract
                </Typography>
              </CancelButton>
            </CardRowValue>
          </CardRow>
        )}
      </Card>
    );
  };

  const renderContract = () => {
    return (
      <ContractContainer>
        {/* TODO payments : see if the user's payment is ready */}
        {true && (
          <Banner>
            <img src={Alert} alt="alert" />
            <div>
              <BannerDescription
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                Ovarc will not send an invitation link for the employee to start
                onboarding until your contract payment is processed.
              </BannerDescription>
              <Button
                text="Proceed to Payments"
                size={ButtonSizes.sm}
                variant={ButtonVariants.primary}
                onClick={() => {
                  navigate("/payment");
                }}
                style={{ marginTop: "0.75rem" }}
              />
            </div>
          </Banner>
        )}
        <CardsContainer>
          <CardsColumn>
            {renderSignature()} {renderAgreementDetails()}
          </CardsColumn>
          <CardsColumn>
            {renderDocuments()}

            {renderContractEnd()}
          </CardsColumn>
        </CardsContainer>
      </ContractContainer>
    );
  };
  return activeEmployeeContract ? (
    renderContract()
  ) : (
    <PeopleEmptyState
      title="You don’t have a contract for this employee"
      btnText="Create Contract"
      onBtnClick={() => {
        navigate(`/people/profile/${id}/employee-contract`);
      }}
    />
  );
};

export default EmployeeContract;
