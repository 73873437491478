import { useEffect, useState } from "react";
import {
  Container,
  InvitedBadge,
  PeopleDataContainer,
  PeopleDataHeader,
  PeopleDataHeaderTitle,
  PeopleDataList,
  PeopleDataListItem,
  PeopleDataListItemFirstRow,
  PeopleDataListItemImg,
  PeopleDataListItemInfo,
  PeopleDataListItemTitle,
  PeopleNumberContainer,
  Dot,
  PeopleDataListItemExtraInfo,
  Arrow,
  DetailsContainer,
  CloseBtn,
  ViewProfileBtn,
  Banner,
  BannerTitle,
  BannerDescription,
  BannerBtn,
  WarningBanner,
  WarningBannerText,
  PayDepositContainer,
  PayDepositContent,
  PayDepositTitle,
  PayDepositDescription,
  PayDepositBtn,
  DataRow,
  DataLabel,
  DataValue,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import ArrowRight from "../../../../Images/icons/arrowRightBlue.svg";
import moment from "moment";
import CloseIcon from "../../../../Images/icons/bannerCloseIcon.svg";
import WarningSign from "../../../../Images/icons/warinigSign.svg";
import Dollar from "../../../../Images/icons/dollarSign.svg";
import Share from "../../../../Images/icons/shareExternalWhite.svg";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { setContractsToOnboard } from "../../../../redux/slices/peopleSlice";
import { useGetContractsToOnboard } from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import PeopleEmptyState from "../../PeopleEmptyState";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const [extendedItem, setExtendedItem] = useState<number | null>(null);
  const peopleToOnboard = useAppSelector(
    (state) => state.people.contractsToOnboard
  );
  const dispatch = useAppDispatch();
  const { data, error } = useGetContractsToOnboard();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      dispatch(setContractsToOnboard(data.data));
    } else if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        title: "Error fetching data",
        description: "",
        open: true,
      });
    }
  }, [data, error]);

  const renderPeopleDataList = () => {
    return peopleToOnboard?.map((person, index) => {
      return (
        <PeopleDataListItem key={person.id} active={extendedItem === index}>
          <PeopleDataListItemImg>
            <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.first_name[0] + person.last_name[0]}
            </Typography>
          </PeopleDataListItemImg>
          <PeopleDataListItemInfo>
            <PeopleDataListItemFirstRow>
              <PeopleDataListItemTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {person.first_name} {person.last_name}
              </PeopleDataListItemTitle>
              <InvitedBadge>
                <Dot />
                <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                  {person.status}
                </Typography>
              </InvitedBadge>
            </PeopleDataListItemFirstRow>
            <PeopleDataListItemExtraInfo
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              {person.type} Employee . Starts on{" "}
              {moment(new Date(person.joining_date)).format("D MMM, YYYY")}
            </PeopleDataListItemExtraInfo>
          </PeopleDataListItemInfo>
          <Arrow
            src={ArrowRight}
            alt="Arrow Right"
            onClick={() =>
              setExtendedItem(extendedItem === index ? null : index)
            }
            active={extendedItem === index}
          />
        </PeopleDataListItem>
      );
    });
  };

  const renderExtendedItem = () => {
    if (extendedItem !== null) {
      const person = peopleToOnboard[extendedItem];

      return (
        <DetailsContainer>
          <CloseBtn
            src={CloseIcon}
            alt="Close Icon"
            onClick={() => setExtendedItem(null)}
            width={20}
            height={20}
          />
          <PeopleDataHeader style={{ width: "100%" }}>
            <PeopleDataListItemImg style={{ width: "42px", height: "42px" }}>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {person.first_name[0] + person.last_name[0]}
              </Typography>
            </PeopleDataListItemImg>
            <div>
              <PeopleDataListItemTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {person.first_name} {person.last_name}
              </PeopleDataListItemTitle>
              <PeopleDataListItemExtraInfo
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {person.type} Employee
              </PeopleDataListItemExtraInfo>
            </div>
            <ViewProfileBtn
              size={ButtonSizes.sm}
              variant={ButtonVariants.secondary_color}
              onClick={() => navigate(`/people/profile/${person.id}`)}
              text="View Profile"
            />
          </PeopleDataHeader>
          <Banner>
            <BannerTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              This employee has not yet signed up to Ovarc
            </BannerTitle>
            <BannerDescription
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              We sent an email invitation on Oct 25th, 2023. Re-invite the
              employee to complete onboarding to avoid delays in start date and
              payroll.
            </BannerDescription>
            <BannerBtn
              size={ButtonSizes.sm}
              variant={ButtonVariants.tertiary_color}
              text="Discard Contract"
              onClick={() => console.log("Resend Invitation")}
            />
          </Banner>
          <WarningBanner>
            <img src={WarningSign} alt="Warning Sign" />
            <WarningBannerText
              size={FontSizes.TX_SM}
              weight={FontWeights.REGULAR}
            >
              Please pay the deposit as soon as possible. The contract cannot be
              cancelled. All monetary obligations towards the employee,
              including all payments, will be chargeable to you.
            </WarningBannerText>
          </WarningBanner>

          <PayDepositContainer>
            <img src={Dollar} alt="dollar-sign" />
            <PayDepositContent>
              <PayDepositTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Awaiting deposit payment
              </PayDepositTitle>
              <PayDepositDescription
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                to be paid by the employer
              </PayDepositDescription>
            </PayDepositContent>
            <PayDepositBtn
              size={ButtonSizes.sm}
              variant={ButtonVariants.primary}
              text="Pay Deposit"
              iconTrailing={
                <img src={Share} alt="share-icon" width={20} height={20} />
              }
            />
          </PayDepositContainer>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Start Date
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {moment(new Date(person.joining_date)).format("D MMM, YYYY")}
            </DataValue>
          </DataRow>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Location of work
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.country_of_citizenship}
            </DataValue>
          </DataRow>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payroll frequency
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.payroll_frequency}
            </DataValue>
          </DataRow>
          <DataRow>
            <DataLabel size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Job title
            </DataLabel>
            <DataValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {person.position}
            </DataValue>
          </DataRow>
        </DetailsContainer>
      );
    }
  };

  return peopleToOnboard.length !== 0 ? (
    <Container>
      <PeopleDataContainer>
        <PeopleDataHeader>
          <PeopleDataHeaderTitle
            size={FontSizes.TX_MD}
            weight={FontWeights.SEMIBOLD}
          >
            People
          </PeopleDataHeaderTitle>
          <PeopleNumberContainer>
            {peopleToOnboard.length}
          </PeopleNumberContainer>
        </PeopleDataHeader>
        <PeopleDataList>{renderPeopleDataList()}</PeopleDataList>
      </PeopleDataContainer>
      {renderExtendedItem()}
    </Container>
  ) : (
    <PeopleEmptyState
      title="You don’t have any onboarding contracts"
      description="Onboarded employees have completed all their onboarding tasks.  You can view contacts from employees profile."
      btnText="View People"
      onBtnClick={() => navigate("/people/manage")}
    />
  );
};

export default Onboarding;
