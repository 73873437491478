import { Navigate, useParams } from "react-router-dom";
import {
  CardsColumn,
  CardsContainer,
  DownloadButton,
  PayslipDetails,
  PayslipDetailsHeader,
  PayslipDetailsHeaderButtons,
  PayslipDetailsTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import Printer from "../../../../Images/icons/printer.svg";
import Download from "../../../../Images/icons/download.svg";
import Button from "../../../shared/Button";
import moment from "moment";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import { useEffect } from "react";
import { useGetPayslipDetails } from "../../../../queries/people";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import Typography from "../../../shared/Typography";

const EmployeePayslipDetails = () => {
  const { payslip_id } = useParams();
  const { data: payslip, error } = useGetPayslipDetails(payslip_id);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch payslip details",
      });
    }
  }, [error]);
  useEffect(() => {
    if (!payslip_id || error) {
      <Navigate to={"/404"} />;
    }
  }, [payslip_id, error]);

  const handlePrint = (event: any, url: string) => {
    event.preventDefault();
    window.open(url, "PRINT", "height=600,width=600");
  };

  return payslip ? (
    <PayslipDetails>
      <PayslipDetailsHeader>
        <PayslipDetailsTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Payslip Details
        </PayslipDetailsTitle>
        <PayslipDetailsHeaderButtons>
          <Button
            text=""
            iconLeading={<img src={Printer} width={20} height={20} alt="" />}
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_color}
            onClick={(event) => handlePrint(event, payslip.url)}
          />
          <DownloadButton href={payslip.url} target={"_blank"}>
            <img src={Download} width={20} height={20} alt="" />
            <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Download
            </Typography>
          </DownloadButton>
        </PayslipDetailsHeaderButtons>
      </PayslipDetailsHeader>
      <CardsContainer>
        <CardsColumn>
          {/* Employee information */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Employee information
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Employee name
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payslip?.name}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Email
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payslip?.email}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Job title
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payslip?.title}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Earnings */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Earnings
            </CardTitle>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Basic salary
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(payslip?.payroll_amount, payslip?.currency)}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Bonuses
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(payslip?.total_bonuses, payslip?.currency)}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Allowance
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(payslip?.allowances, payslip?.currency)}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Net Pay */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Net Pay
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Total earnings
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(payslip?.total_amount, payslip?.currency)}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Total deductions
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(payslip?.tax_deduction, payslip?.currency)}
              </CardRowValue>
            </CardRow>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Net pay
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(payslip?.salary_net, payslip?.currency)}
              </CardRowValue>
            </CardRow>
          </Card>
        </CardsColumn>

        <CardsColumn>
          {/* Pay Period & status */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Pay Period & status
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Start and End Dates
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {moment(payslip?.cycle_start_date).format("DD MMM, YYYY")} -{" "}
                {moment(payslip?.cycle_end_date).format("DD MMM, YYYY")}
              </CardRowValue>
            </CardRow>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Date of payment
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {moment(payslip?.payday_date).format("DD MMM, YYYY")}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Deductions */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Deductions
            </CardTitle>

            {payslip?.deductions.map((deduction, index) => (
              <CardRow key={index}>
                <CardRowTitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {deduction.type}
                </CardRowTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(deduction.amount, payslip?.currency)}
                </CardRowValue>
              </CardRow>
            ))}
          </Card>
        </CardsColumn>
      </CardsContainer>
    </PayslipDetails>
  ) : null;
};

export default EmployeePayslipDetails;
