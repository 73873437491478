import { useEffect } from "react";
import PeopleEmptyState from "../../PeopleEmptyState";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../shared/Table/styled";
import Typography from "../../../shared/Typography";
import {
  RequestsContainer,
  RequestsHeader,
  RequestsText,
  RequestsTitle,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import moment from "moment";
import ActionsDropDown from "../../../shared/ActionsDropDown";
import { useNavigate } from "react-router-dom";
import Badge from "../../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../../types/componentsProps";
import { useGetAllREmployeeRequests } from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const EmployeeRequests = () => {
  const { data: employeeRequests, error } = useGetAllREmployeeRequests();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch employee requests",
      });
    }
  }, [error]);

  const renderEmployeeRequestsRows = () => {
    return employeeRequests?.map((request, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <RequestsText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {request.id}
            </RequestsText>
          </TableCell>

          <TableCell>
            <RequestsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {request.description}
            </RequestsText>
          </TableCell>
          <TableCell>
            <RequestsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {request.type}
            </RequestsText>
          </TableCell>
          <TableCell>
            <Badge
              dot
              text={request.status}
              color={
                request.status === "Delivered"
                  ? BadgeTypes.success
                  : request.status === "Pending"
                  ? BadgeTypes.blueLight
                  : BadgeTypes.error
              }
            />
          </TableCell>
          <TableCell>
            <RequestsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(request.date).format("DD-MM-YYYY")}
            </RequestsText>
          </TableCell>
          <TableCell>
            <ActionsDropDown
              actions={[
                {
                  text: "View",
                  onSelect: () => {
                    navigate(`/people/profile/1/request-details/${request.id}`);
                  },
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderEmployeeRequests = () => {
    return (
      <RequestsContainer>
        <RequestsHeader>
          <RequestsTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Requests
          </RequestsTitle>
        </RequestsHeader>
        <Table>
          <TableHeader>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                ID
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Description
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Type
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Date
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableHeader>
          {renderEmployeeRequestsRows()}
        </Table>
      </RequestsContainer>
    );
  };

  return employeeRequests?.length === 0 || !employeeRequests ? (
    <PeopleEmptyState title="You don’t have requests from this employee" />
  ) : (
    renderEmployeeRequests()
  );
};

export default EmployeeRequests;
