import React, { useEffect, useState } from "react";
import VerticalModal from "../../shared/VerticalModal";
import {
  AddBonusButton,
  CloseIcon,
  ModalContentContainer,
  ModalContentHeader,
  ModalContentTitle,
  ModalContentTitleAndImgContainer,
  ProfileLink,
  ReviewContainer,
  ButtonsContainer,
  InputsContainer,
  ModalFooter,
} from "./styled";
import {
  Card,
  CardHeader,
  CardRow,
  CardRowValue,
  CardTitle,
} from "../../shared/Card";
import { PayrollReviewEmployee } from "../../../types";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import External from "../../../Images/icons/link-external-brand.svg";
import Typography from "../../shared/Typography";
import Close from "../../../Images/icons/alertClose.svg";
import moment from "moment";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Plus from "../../../Images/icons/plus-brand.svg";
import Button from "../../shared/Button";
import InputField from "../../shared/InputField";
import { useAddBonus } from "../../../queries/payroll";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";

interface EmployeePayrollDetailsModalProps {
  open: boolean;
  onClose: () => void;
  employee: PayrollReviewEmployee | null;
}

const EmployeePayrollDetailsModal: React.FC<
  EmployeePayrollDetailsModalProps
> = ({ open, onClose, employee }) => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const [openAddBonus, setOpenAddBonus] = useState(false);
  const { company_id } = useParams<{ company_id: string }>();
  const [bonuses, setBonuses] = useState(employee?.bonuses || []);
  const [bonusFormData, setBonusFormData] = useState({
    type: "",
    price: 0,
  });
  const [bonusError, setBonusError] = useState({
    type: "",
    price: "",
  });
  const { mutateAsync: addBonus } = useAddBonus();

  useEffect(() => {
    setBonuses(employee?.bonuses || []);
  }, [employee]);
  if (!employee) {
    return null;
  }

  const validateForm = () => {
    let isValid = true;
    if (bonusFormData.type === "") {
      setBonusError({ ...bonusError, type: "Please enter a bonus type" });
      isValid = false;
    }
    if (bonusFormData.price === 0) {
      setBonusError({ ...bonusError, price: "Please enter a bonus amount" });
      isValid = false;
    }
    return isValid;
  };

  const onAddBonus = async () => {
    try {
      await addBonus({
        payroll_id: company_id || "",
        employeeId: employee.id,
        data: bonuses,
      });
      setOpenAddBonus(false);
      queryClient.invalidateQueries({
        queryKey: ["payrollReviewEmployees", company_id],
      });
      onClose();
      showSnackbar({
        type: SnackbarTypes.SUCCESS,
        open: true,
        title: "Bonus added successfully.",
        description: "",
      });
    } catch (e) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while adding the bonus.",
        description: "Please try again later.",
      });
    }
  };

  return (
    <VerticalModal open={open} onClose={onClose}>
      <ModalContentContainer>
        <ModalContentHeader>
          <ModalContentTitleAndImgContainer>
            <InitialsProfilePic
              fullName={`${employee.first_name} ${employee.last_name}`}
            />
            <div>
              <ModalContentTitle
                size={FontSizes.TX_XL}
                weight={FontWeights.SEMIBOLD}
              >
                {employee.first_name} {employee.last_name}
              </ModalContentTitle>
              <ProfileLink href={`/people/profile/${employee.id}/contract`}>
                <Typography size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                  View Contract
                </Typography>
                <img
                  src={External}
                  alt="external link"
                  width={16}
                  height={16}
                />
              </ProfileLink>
            </div>
            <CloseIcon src={Close} alt="close" onClick={onClose} />
          </ModalContentTitleAndImgContainer>
        </ModalContentHeader>

        <ReviewContainer>
          {/* contract */}
          <Card>
            <CardHeader>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Contract Details
              </CardTitle>
            </CardHeader>
            <CardRow>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Employee Status
              </CardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {employee.status}
              </CardRowValue>{" "}
            </CardRow>

            <CardRow>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Payroll Cycle
              </CardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {`${moment(employee.payroll_cycle_start_date).format(
                  "DD MMM"
                )} - ${moment(employee.payroll_cycle_end_date).format(
                  "DD MMM"
                )}`}
              </CardRowValue>{" "}
            </CardRow>
          </Card>

          {/* salary */}
          <Card>
            <CardHeader>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Salary
              </CardTitle>
            </CardHeader>
            <CardRow>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Rate
              </CardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(employee.total, employee.currency)}
              </CardRowValue>{" "}
            </CardRow>

            <CardRow>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Pay Per
              </CardTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {employee.salary_coverage_period}
              </CardRowValue>{" "}
            </CardRow>
          </Card>

          {/* Benefits */}

          <Card>
            <CardHeader>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Benefits
              </CardTitle>
            </CardHeader>
            {employee.benefits.map((benefit, index) => (
              <CardRow key={index}>
                <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  {benefit.type}
                </CardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(benefit.price, employee.currency)}
                </CardRowValue>{" "}
              </CardRow>
            ))}
          </Card>
          {/* Bonus */}

          <Card>
            <CardHeader>
              <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                Bonus
              </CardTitle>

              {!openAddBonus && (
                <AddBonusButton
                  onClick={() => {
                    setOpenAddBonus(true);
                  }}
                >
                  <img src={Plus} alt="plus" width={20} height={20} />

                  <Typography
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    Add Bonus
                  </Typography>
                </AddBonusButton>
              )}
            </CardHeader>
            {openAddBonus && (
              <>
                <InputsContainer>
                  <InputField
                    placeholder="Bonus type"
                    value={bonusFormData.type}
                    onChange={(e) => {
                      setBonusFormData({
                        ...bonusFormData,
                        type: e.target.value,
                      });
                    }}
                    error={bonusError.type}
                    onFocus={() => {
                      setBonusError({ ...bonusError, type: "" });
                    }}
                  />
                  <InputField
                    placeholder="Bonus amount"
                    type="number"
                    onKeyDown={(e) => {
                      if (e.key === "e" || e.key === ".") {
                        e.preventDefault();
                      }
                    }}
                    value={bonusFormData.price}
                    onChange={(e) => {
                      setBonusFormData({
                        ...bonusFormData,
                        price: parseInt(e.target.value),
                      });
                    }}
                    error={bonusError.price}
                    onFocus={() => {
                      setBonusError({ ...bonusError, price: "" });
                    }}
                  />
                </InputsContainer>

                <ButtonsContainer>
                  <Button
                    text="Cancel"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.secondary_gray}
                    onClick={() => {
                      setOpenAddBonus(false);
                    }}
                  />
                  <Button
                    text="Add"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.primary}
                    onClick={() => {
                      setBonuses([...bonuses, bonusFormData]);
                      setBonusFormData({ type: "", price: 0 });
                      setOpenAddBonus(false);
                    }}
                  />
                </ButtonsContainer>
              </>
            )}
            {bonuses.map((bonus, index) => (
              <CardRow key={index}>
                <CardTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                  {bonus.type}
                </CardTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {currencyFormatter(bonus.price, employee.currency)}
                </CardRowValue>{" "}
              </CardRow>
            ))}
          </Card>
          <ModalFooter>
            <Button
              text="Update"
              size={ButtonSizes.md}
              variant={ButtonVariants.primary}
              onClick={() => {
                bonuses.length === employee.bonuses.length
                  ? onClose()
                  : onAddBonus();
              }}
            />
          </ModalFooter>
        </ReviewContainer>
      </ModalContentContainer>
    </VerticalModal>
  );
};

export default EmployeePayrollDetailsModal;
