export const URLS_TO_PASS_THROUGH =
  process.env.REACT_APP_ENV === "dev"
    ? [
        "/login",
        "/country",
        "/register",
        "/verify_email",
        "/resend_email",
        "/job_hierarchy",
        "/user"
      ]
    : [];
