import { useNavigate } from "react-router-dom";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import {
  AssetInfoStepButtonsContainer,
  AssetInfoStepContainer,
  FieldsContainer,
  NextButton,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import {
  editToBeAddedAsset,
  onNextAddAssetStep,
} from "../../../../redux/slices/assetsHubSlice";
import { Card, CardTitle } from "../../../shared/Card";
import InputField from "../../../shared/InputField";
import DropdownList from "../../../shared/DropdownList";
import { ASSETS_CATEGORIES_OPTIONS } from "../../../../mockServer/mockData";
import { DropdownListOption } from "../../../../types/componentsProps";
import { useEffect, useState } from "react";
import { useGetActiveEmployees } from "../../../../queries/people";

const AssetInfoStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { info } = useAppSelector((state) => state.assetsHub.toBeAddedAsset);
  const checkIfAllFieldsAreFilled = () => {
    return (
      Object.values(info).every((value) => value !== "") &&
      info.assignee.value !== "" &&
      info.category.value !== ""
    );
  };

  const [activeEmployeesOptions, setActiveEmployeesOptions] = useState<
    DropdownListOption[]
  >([]);

  const { data: activeEmployees, error: activeEmployeesError } =
    useGetActiveEmployees();
  useEffect(() => {
    if (activeEmployees?.data) {
      let options = activeEmployees.data.employees.map((employee) => {
        return {
          label: employee.user.first_name + " " + employee.user.last_name,
          value: employee.id,
        };
      });
      setActiveEmployeesOptions(options);
    }
  }, [activeEmployees]);
  const renderAssetInfoCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Asset Information
        </CardTitle>
        <FieldsContainer>
          <InputField
            label="Name"
            value={info.name}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    name: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="ID/Tag"
            value={info.id}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    id: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Serial Number"
            value={info.serialNumber}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    serialNumber: e.target.value,
                  },
                })
              );
            }}
          />

          <DropdownList
            label="Category/Type"
            options={ASSETS_CATEGORIES_OPTIONS}
            selectedOption={info.category}
            setSelectedOption={(option) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    category: option,
                  },
                })
              );
            }}
          />

          <InputField
            label="Brand"
            value={info.brand}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    brand: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Model/Make"
            value={info.model}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    model: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Specifications"
            value={info.specifications}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    specifications: e.target.value,
                  },
                })
              );
            }}
          />
        </FieldsContainer>
      </Card>
    );
  };
  const renderLocationCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Location and Assignment
        </CardTitle>
        <FieldsContainer>
          <InputField
            label="Location"
            value={info.location}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    location: e.target.value,
                  },
                })
              );
            }}
          />

          <DropdownList
            label="Assigned To"
            options={activeEmployeesOptions}
            selectedOption={info.assignee}
            setSelectedOption={(option) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    assignee: option,
                  },
                })
              );
            }}
          />

          <InputField
            label="Installation Date"
            value={info.installationDate}
            type="date"
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    installationDate: e.target.value,
                  },
                })
              );
            }}
          />
        </FieldsContainer>
      </Card>
    );
  };

  const renderWarrantyCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Warranty and Supplier Information
        </CardTitle>

        <FieldsContainer>
          <InputField
            label="Warranty Period"
            value={info.warrantyPeriod}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    warrantyPeriod: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Supplier Name"
            value={info.supplier}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    supplier: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Supplier Contact Information"
            value={info.supplierContact}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    supplierContact: e.target.value,
                  },
                })
              );
            }}
          />
        </FieldsContainer>
      </Card>
    );
  };

  const renderMaintenanceCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Maintenance and Service Records
        </CardTitle>
        <FieldsContainer>
          <InputField
            label="Maintenance Schedule"
            value={info.maintenancePeriod}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    maintenancePeriod: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Last Service On"
            value={info.lastMaintenance}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    lastMaintenance: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Service Provider"
            value={info.maintenanceProvider}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    maintenanceProvider: e.target.value,
                  },
                })
              );
            }}
          />
        </FieldsContainer>
      </Card>
    );
  };

  const renderDisposalCard = () => {
    return (
      <Card>
        <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Disposal Information
        </CardTitle>
        <FieldsContainer>
          <InputField
            label="Disposal Date"
            value={info.disposalDate}
            type="date"
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    disposalDate: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Method of Disposal"
            value={info.disposalMethod}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    disposalMethod: e.target.value,
                  },
                })
              );
            }}
          />

          <InputField
            label="Reason for Disposal"
            value={info.disposalReason}
            onChange={(e) => {
              dispatch(
                editToBeAddedAsset({
                  info: {
                    ...info,
                    disposalReason: e.target.value,
                  },
                })
              );
            }}
          />
        </FieldsContainer>
      </Card>
    );
  };
  return (
    <AssetInfoStepContainer>
      {renderAssetInfoCard()}
      {renderLocationCard()}
      {renderWarrantyCard()}
      {renderMaintenanceCard()}
      {renderDisposalCard()}
      <AssetInfoStepButtonsContainer>
        <Button
          text="Cancel"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          onClick={() => navigate(-1)}
        />
        <NextButton
          text="Next"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          onClick={() => {
            dispatch(onNextAddAssetStep());
          }}
          disabled={!checkIfAllFieldsAreFilled()}
        />
      </AssetInfoStepButtonsContainer>
    </AssetInfoStepContainer>
  );
};

export default AssetInfoStep;
