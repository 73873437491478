import React, { useState } from "react";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { PageHeader, PageSubtitle, PageTitle } from "./styled";
import Onboarding from "./Onboarding";
import Tabs from "../../shared/Tabs";
import OffBoarding from "./Offboarding";

const Contract: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const renderTabContent = () => {
    if (activeTab === 0) {
      return <Onboarding />;
    }
    return <OffBoarding />;
  };

  return (
    <>
      <PageHeader>
        <PageTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Contracts
        </PageTitle>

        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Efficiently Navigate and manage your onboarding and off-boarding
          employees
        </PageSubtitle>
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabsTitles={["Onboarding", "Offboarding"]}
        />
        {renderTabContent()}
      </PageHeader>
    </>
  );
};

export default Contract;
