import { Asset, Company, Country, Employee, PAYMENTS_STATUS } from "../types";
import { DropdownListOption } from "../types/componentsProps";

export const USERS = [
  {
    id: 1,
    type: "employer_root",
    email: "admin@ex.com",
    password: "12345678",
    first_name: "John",
    last_name: "Doe",
    gender: "Male",
    verified: true,
    user_information: {
      id: 1,
      position: "CTO",
      date_of_birth: "1995-09-19",
      phone: "+201099999999",
      country_of_citizenship: "Egypt",
    },
    profile_picture: {
      id: 11,
      type: "Image",
      entries: [
        {
          id: 22,
          label: "large",
          url: "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
        },
      ],
    },
  },
];

export const ACCESS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";

export const USER_COMPANIES = [
  {
    value: 0,
    label: "InterLock - USA",
    iconLeading:
      "https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
  },

  {
    value: 1,
    label: "InterLock - UAE",
    iconLeading:
      "https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
  },
];

export const PAYMENTS = [
  {
    type: "Bank Transfer",
    status: PAYMENTS_STATUS.WaitingForPaymentToArrive,
    date: "18 Nov. 2023",
    amount: "33000",
  },
  {
    type: "Bank Transfer",
    status: PAYMENTS_STATUS.Processing,
    date: "18 Nov. 2023",
    amount: "351320",
  },
  {
    type: "Bank Transfer",
    status: PAYMENTS_STATUS.Processing,
    date: "14 Nov. 2023",
    amount: "23850",
  },
];

export const COMPANIES: Company[] = [
  {
    id: "0",
    name: "Interlock - USA",

    plan: {
      type: "Private",
      country: "USA",
    },
    industry: "Technology",
    legal_company_name: "Interlock",
    phone: "+201234567890",
    tax_id: "123456789",
    registration_number: "123456789",
    logo: {
      id: "0",
      url: "https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
  },

  {
    name: "Interlock - Egypt",
    id: "1",
    plan: {
      type: "Private",
      country: "Egypt",
    },
    industry: "Technology",
    legal_company_name: "Interlock",
    phone: "+201234567890",
    tax_id: "123456789",
    registration_number: "123456789",
    logo: {
      id: "1",
      url: "https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
  },
];

export const AssetsCard = [
  {
    name: "In-use",
    value: 50,
  },
  {
    name: "Inactive",
    value: 1,
  },
  {
    name: "Decommissioned",
    value: 1,
  },
];

export const AssetsCardColors = ["#17B26A", "#FDB022", "#98A2B3"];

export const PeopleCard = [
  {
    name: "EOR",
    value: 60,
  },
  {
    name: "Direct Employees",
    value: 40,
  },
  {
    name: "Contractors",
    value: 30,
  },
];

export const PeopleCardColors = ["#033B6E", "#B3B8DB", "#59ACFB"];

export const USER_COMPANY = {
  id: "11",
  name: "Ovarc",
  corporation_type: "C Corporation",
  subsidiary: true,
  parent_name: "Parent Ovarc",
  industry: "B2B Software",
  fund: "Main Funding",
  number_of_shares: 150,
  phone: "+201099999999",
  website: "www.dummy.com",
  product_description: "This product is really important",
  company_description: "This company is really important",
  marketplace: true,
  street: "74 Pineknoll Street",
  city: "Reisterstown",
  state: "MD",
  zip: "21136",
  longitude: 133.253,
  latitude: -117.654,
  country: "Egypt",
  plan_id: 1,
  size: {
    min: 11,
    max: 20,
  },
  main_user: {
    email: "admin@ex.com",
    first_name: "John",
    last_name: "Doe",
    gender: "Male",
    position: "CTO",
    date_of_birth: "1995-09-19",
    phone: "+201099999999",
    country_of_citizenship: "Egypt",
  },
  services: [],
};

export const PEOPLE_TO_ONBOARD = [
  {
    id: "1",
    first_name: "Jenny",
    last_name: "Wilson",
    type: "EOR",
    joining_date: "2023-11-02",
    status: "invited",
    signedUp: false,
    invitationDate: "2023-10-25",

    position: "Designer",
    country_of_citizenship: "Egypt",
    payroll_frequency: "Monthly",
  },
  {
    id: "2",
    first_name: "Courtney",
    last_name: "Henry",
    type: "EOR",
    joining_date: "2023-11-02",
    status: "invited",
    signedUp: false,
    invitationDate: "2023-10-25",

    position: "Designer",
    country_of_citizenship: "Egypt",
    payroll_frequency: "Monthly",
  },
  {
    id: "3",
    first_name: "Jacob",
    last_name: "Jones",
    type: "EOR",
    joining_date: "2023-11-02",
    status: "invited",
    signedUp: false,
    invitationDate: "2023-10-25",

    position: "Designer",
    country_of_citizenship: "Egypt",
    payroll_frequency: "Monthly",
  },
];

export const EMPLOYMENT_TYPES = [
  {
    label: "Full-time",
    value: "full_time",
  },
  {
    label: "Part-time",
    value: "part_time",
  },
  {
    label: "Contract",
    value: "contract",
  },
];
export const EMPLOYMENT_TERMS = [
  {
    label: "3 months",
    value: "3_months",
  },
  {
    label: "6 months",
    value: "6_months",
  },
  {
    label: "1 year",
    value: "1_year",
  },
];

export const CURRENCIES = [
  {
    label: "USD",
    value: "usd",
  },
  {
    label: "EGP",
    value: "egp",
  },
  {
    label: "EURO",
    value: "eur",
  },
];

export const CONTRACT = {
  user_id: 1,
  id: 1,
  worker_type: "EOR",
  salary: 1200,
  currency: "USD",
  docs: [
    {
      id: 1,
      name: "Master Service Agreement",
      url: "https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250",
    },
    {
      id: 2,
      name: "Scope of work",
    },
  ],

  assignment_details: {
    start_date: "2023-11-02",
    created_at: "2023-10-25",
    job_title: "Account Manager",
    seniority_level: "Senior (Individual Contributor Level 3)",
    employment_type: "Full-time",
    hours_per_week: 40,
    company: "Interlock - EGY",
    employment_terms: "3 Months",
  },
  contract_end: {
    end_date: "Not Specified",
    probation_period: "90 days",
    last_day_of_probation: "2024-01-31",
  },
  expiring: false,
};

export const HEALTH_INSURANCE_PROVIDERS_OPTIONS: DropdownListOption[] = [
  {
    label: "AXA",
    value: "AXA",
  },
  {
    label: "MetLife",
    value: "MetLife",
  },
  {
    label: "Allianz",
    value: "Allianz",
  },
];

export const HEALTH_INSURANCE_PLANS_OPTIONS: DropdownListOption[] = [
  {
    label: "Basic",
    value: "Basic",
  },
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Premium",
    value: "Premium",
  },
];

export const ASSETS = [
  {
    id: "1",
    name: "EliteDesk 800 G5",
    type: "Desktop",
    brand: "HP",
    condition: "80%",
    employeeStatus: "In-Active",
  },
  {
    id: "2",
    name: "Aeron Chair",
    type: "Furniture",
    brand: "Herman Miller",
    condition: "60%",
    employeeStatus: "In-Active",
  },
  {
    id: "3",
    name: "WorkForce Pro WF-4740",
    type: "Printer",
    employeeStatus: "In-Active",
    brand: "Epson",
    condition: "100%",
  },
  {
    id: "4",
    name: "RealPresence Trio 8800",
    type: "Conference Phone",
    employeeStatus: "In-Active",
    brand: "Polycom",
    condition: "70%",
  },
];

export const EmployeeAssets: {
  user_id: string;
  assets: Asset[];
}[] = [
  {
    user_id: "1",
    assets: [],
  },
  {
    user_id: "2",
    assets: [],
  },
  {
    user_id: "3",
    assets: [],
  },
  {
    user_id: "4",
    assets: [],
  },
  {
    user_id: "5",
    assets: [],
  },
];
export const EMPLOYEE_PAYSLIPS_OVERVIEW = [
  {
    id: "1",
    cycle_start_date: "2024-01-01",
    cycle_end_date: "2024-01-15",
    payday_date: "2024-01-16",
    salary_gross: 3200,
    total_deductions: 500,
    salary_net: 2700,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    currency: "USD",
  },
  {
    id: "2",
    cycle_start_date: "2024-01-17",
    cycle_end_date: "2024-01-30",
    payday_date: "2024-01-31",
    salary_gross: 3200,
    total_deductions: 500,
    salary_net: 2700,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    currency: "USD",
  },
  {
    id: "3",
    cycle_start_date: "2024-02-01",
    cycle_end_date: "2024-02-15",
    payday_date: "2024-02-16",
    salary_gross: 3200,
    total_deductions: 500,
    salary_net: 2700,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    currency: "USD",
  },
  {
    id: "4",
    cycle_start_date: "2024-02-17",
    cycle_end_date: "2024-02-28",
    payday_date: "2024-02-29",
    salary_gross: 3200,
    total_deductions: 500,
    salary_net: 2700,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    currency: "USD",
  },
  {
    id: "5",
    cycle_start_date: "2024-03-01",
    cycle_end_date: "2024-03-15",
    payday_date: "2024-03-16",
    salary_gross: 3200,
    total_deductions: 500,
    salary_net: 2700,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    currency: "USD",
  },
];

export const PAYSLIPS = [
  {
    id: "1",
    cycle_start_date: "2024-01-01",
    cycle_end_date: "2024-01-15",
    payday_date: "2024-01-16",
    name: "Craig David",
    email: "c.david@interlock.io",
    title: "Graphic designer",
    currency: "USD",
    salary_gross: 3200,

    total_deductions: 500,

    salary_net: 2700,
    payroll_amount: 2500,
    total_bonuses: 500,
    allowances: 200,

    total_amount: 3200,
    tax_deduction: 200,
    deductions: [
      {
        type: "Health Insurance",
        amount: 100,
      },
      {
        type: "Social Security",
        amount: 200,
      },
      {
        type: "Tax Deduction",
        amount: 200,
      },
    ],
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "2",
    cycle_start_date: "2024-01-17",
    cycle_end_date: "2024-01-30",
    payday_date: "2024-01-31",
    currency: "USD",
    salary_gross: 3200,

    total_deductions: 500,

    salary_net: 2700,
    payroll_amount: 2500,
    total_bonuses: 500,
    allowances: 200,

    total_amount: 3200,
    tax_deduction: 200,
    deductions: [
      {
        type: "Health Insurance",
        amount: 100,
      },
      {
        type: "Social Security",
        amount: 200,
      },
      {
        type: "Tax Deduction",
        amount: 200,
      },
    ],
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "3",
    cycle_start_date: "2024-02-01",
    cycle_end_date: "2024-02-15",
    payday_date: "2024-02-16",
    currency: "USD",
    salary_gross: 3200,

    total_deductions: 500,

    salary_net: 2700,
    payroll_amount: 2500,
    total_bonuses: 500,
    allowances: 200,

    total_amount: 3200,
    tax_deduction: 200,
    deductions: [
      {
        type: "Health Insurance",
        amount: 100,
      },
      {
        type: "Social Security",
        amount: 200,
      },
      {
        type: "Tax Deduction",
        amount: 200,
      },
    ],
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "4",
    cycle_start_date: "2024-02-17",
    cycle_end_date: "2024-02-28",
    payday_date: "2024-02-29",
    currency: "USD",
    salary_gross: 3200,

    total_deductions: 500,

    salary_net: 2700,
    payroll_amount: 2500,
    total_bonuses: 500,
    allowances: 200,

    total_amount: 3200,
    deductions: [
      {
        type: "Health Insurance",
        amount: 100,
      },
      {
        type: "Social Security",
        amount: 200,
      },
      {
        type: "Tax Deduction",
        amount: 200,
      },
    ],
    tax_deduction: 200,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "5",
    cycle_start_date: "2024-03-01",
    cycle_end_date: "2024-03-15",
    payday: "2024-03-16",
    currency: "USD",
    salary_gross: 3200,
    total_deductions: 500,
    salary_net: 2700,
    payroll_amount: 2500,
    total_bonuses: 500,
    allowances: 200,

    total_amount: 3200,
    tax_deduction: 200,
    deductions: [
      {
        type: "Health Insurance",
        amount: 100,
      },
      {
        type: "Social Security",
        amount: 200,
      },
      {
        type: "Tax Deduction",
        amount: 200,
      },
    ],
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
];

export const EMPLOYEE_REQUESTS_LIST = [
  {
    id: 1,
    description: "Family Vacation",
    type: "Vacation request",
    status: "Rejected",
    date: "2023-11-02",
  },
  {
    id: 2,
    description: "Work visa",
    type: "Document Renewal",
    status: "Pending",
    date: "2023-11-02",
  },
  {
    id: 3,
    description: "Driver's license",
    type: "Required Document",
    status: "Delivered",
    date: "2023-11-02",
  },
];

export const EMPLOYEE_REQUESTS_Details = [
  {
    id: 1,
    description: "Family Vacation",
    type: "Vacation request",
    status: "Rejected",
    date: "2023-11-02",
    startDate: "2023-11-02",
    endDate: "2023-11-13",
    reasonOfRejection: "Inadequate Notice",
    timeline: [
      {
        date: "2023-11-02",
        status: "Initiated",
        by: "Craig David",
      },
      {
        date: "2023-11-04",
        status: "Rejected",
        by: "Amanda Webb",
      },
    ],
  },
  {
    id: 2,
    description: "Work visa",
    type: "Document Renewal",
    status: "Pending",
    date: "2023-11-02",
    timeline: [
      {
        date: "2023-11-02",
        status: "Initiated",
        by: "Craig David",
      },
    ],
  },
  {
    id: 3,
    description: "Driver's license",
    type: "Required Document",
    status: "Delivered",
    date: "2023-11-02",
    timeline: [
      {
        date: "2023-11-02",
        status: "Initiated",
        by: "Craig David",
      },
      {
        date: "2023-11-04",
        status: "Delivered",
        by: "Amanda Webb",
      },
    ],
  },
];

export const EMPLOYEE_BENEFITS = [
  {
    id: 1,
    provider: "Allianz",
    type: "Health Insurance",
    startDate: "2023-11-02",
    endDate: "2024-11-02",
    plan: {
      title: "Bronze plan",
      price: 199,
      currency: "USD",
      coveragePeriod: "month",
    },
  },

  {
    id: 2,
    provider: "Allianz",
    type: "Vision Insurance",
    startDate: "2023-11-02",
    endDate: "2024-11-02",
    plan: {
      title: "Bronze plan",
      price: 99,
      currency: "USD",
      coveragePeriod: "month",
    },
  },
  {
    id: 3,
    provider: "Allianz",
    type: "Dental Insurance",
    startDate: "2023-11-02",
    endDate: "2024-11-02",
    plan: {
      title: "Bronze plan",
      price: 99,
      currency: "USD",
      coveragePeriod: "month",
    },
  },
];

export const EMPLOYEES: Employee[] = [
  {
    id: "1",
    employee_status: "Active",
    joining_date: "2023-11-02",
    user: {
      id: 1,
      first_name: "Jenny",
      last_name: "Wilson",
      email: "jane@interlock.io",
    },
    user_information: {
      id: 1,
      position: "UI/UX Designer",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 0,
      name: "Interlock - Egypt",
    },
  },
  {
    id: "2",
    employee_status: "Active",
    joining_date: "2023-11-02",
    user: {
      id: 2,
      first_name: "Phoenix",
      last_name: "Baker",
      email: "phoenix@interlock.io",
    },
    user_information: {
      id: 2,
      position: "Software Developer",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 0,
      name: "Interlock - Egypt",
    },
  },
  {
    id: "3",
    employee_status: "Active",
    joining_date: "2023-11-02",
    user: {
      id: 3,
      first_name: "Lana",
      last_name: "Steiner",
      email: "lana@interlock.io",
    },
    user_information: {
      id: 3,
      position: "Scrum Master",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 0,
      name: "Interlock - Egypt",
    },
  },
  {
    id: "4",
    employee_status: "Active",
    joining_date: "2023-11-02",
    user: {
      id: 4,
      first_name: "Demi",
      last_name: "Wilkinson",
      email: "demi@interlock.io",
    },
    user_information: {
      id: 4,
      position: "Ethical Hacker",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 0,
      name: "Interlock - Egypt",
    },
  },
  {
    id: "5",
    employee_status: "Active",
    joining_date: "2023-11-02",
    user: {
      id: 5,
      first_name: "Candice",
      last_name: "Wu",
      email: "candice@interlock.io",
    },
    user_information: {
      id: 5,
      position: "Software Tester",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 0,
      name: "Interlock - Egypt",
    },
    contract_expiring: true,
  },
  {
    id: "6",
    employee_status: "Draft",
    joining_date: "2023-11-02",
    user: {
      id: 6,
      first_name: "Natali",
      last_name: "Craig",
      email: "natali@interlock.io",
    },
    user_information: {
      id: 6,
      position: "Team Leader",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 0,
      name: "Interlock - Egypt",
    },
  },

  {
    id: "7",
    employee_status: "Draft",
    joining_date: "2023-11-02",
    user: {
      id: 7,
      first_name: "Drew",
      last_name: "Cano",
      email: "drew@interlock.io",
    },
    user_information: {
      id: 7,
      position: "Software Development",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 1,
      name: "Interlock - Egypt",
    },
  },
  {
    id: "8",
    employee_status: "Draft",
    joining_date: "2023-11-02",
    user: {
      id: 8,
      first_name: "Olivia",
      last_name: "Rye",
      email: "olivia@untitledui.com",
    },

    user_information: {
      id: 8,
      position: "Web Designer",
      country_of_citizenship: "Egypt",
    },
    company: {
      id: 1,
      name: "Interlock - Egypt",
    },
  },
];

export const EMPLOYEES_CONTRACTS = [
  {
    ...CONTRACT,

    assignment_details: {
      ...CONTRACT.assignment_details,
      job_title: EMPLOYEES[0].user_information.position,
    },
  },
  {
    ...CONTRACT,
    id: 2,
    user_id: "2",
    assignment_details: {
      ...CONTRACT.assignment_details,
      job_title: EMPLOYEES[1].user_information.position,
    },
  },
  {
    ...CONTRACT,
    id: 3,
    user_id: "3",

    assignment_details: {
      ...CONTRACT.assignment_details,
      job_title: EMPLOYEES[2].user_information.position,
    },
  },
  {
    ...CONTRACT,
    id: 4,
    user_id: "4",
    assignment_details: {
      ...CONTRACT.assignment_details,
      job_title: EMPLOYEES[3].user_information.position,
    },
  },
  {
    ...CONTRACT,
    id: 5,
    user_id: "5",
    assignment_details: {
      ...CONTRACT.assignment_details,
      job_title: EMPLOYEES[4].user_information.position,
    },
    expiring: true,
  },
];
export const ALL_STATUS_OPTIONS: DropdownListOption[] = [
  {
    label: "All Statuses",
    value: "All Statuses",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
  {
    label: "Draft",
    value: "Draft",
  },
  {
    label: "Pending Invite",
    value: "Pending Invite",
  },
  {
    label: "Invited",
    value: "Invited",
  },
  {
    label: "Onboarding",
    value: "Onboarding",
  },
  {
    label: "Onboarding at risk",
    value: "Onboarding at risk",
  },
  {
    label: "Onboarding overdue",
    value: "Onboarding overdue",
  },
  {
    label: "Ready to start",
    value: "Ready to start",
  },
];

export const ALL_ASSETS_STATUS_OPTIONS: DropdownListOption[] = [
  {
    label: "All Statuses",
    value: "All Statuses",
  },
  {
    label: "In-Use",
    value: "In-Use",
  },

  {
    label: "Decommissioned",
    value: "Decommissioned",
  },
  {
    label: "Lost",
    value: "Lost",
  },
  {
    label: "Under Maintenance",
    value: "Under Maintenance",
  },
  {
    label: "In-Active",
    value: "In-Active",
  },
  {
    label: "Pending Delivery",
    value: "Pending Delivery",
  },
];

export const COUNTRIES: Country[] = [
  {
    id: 1,
    name: "United States",
  },
  {
    id: 2,
    name: "United Arab Emirates",
  },
  {
    id: 3,
    name: "Egypt",
  },
];

export const COMPANIES_PAYROLLS = [
  {
    id: "1",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    payroll_status: "Awaiting Report Submission",
    company: {
      id: "1",
      name: "Interlock - Egypt",
      logo: {
        id: "1",
        url: "https://fastly.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
      },
    },
  },

  {
    id: "2",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    payroll_status: "Awaiting Report Submission",
    company: {
      id: "2",
      name: "Interlock - UAE",
      logo: {
        id: "2",
        url: "https://fastly.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
      },
    },
  },

  {
    id: "3",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    payroll_status: "Awaiting Report Submission",
    company: {
      id: "3",
      name: "Interlock - USA",
      logo: {
        id: "3",
        url: "https://fastly.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
      },
    },
  },
  {
    id: "4",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    payroll_status: "Awaiting Report Submission",
    company: {
      id: "4",
      name: "Interlock - KSA",
      logo: {
        id: "4",
        url: "https://fastly.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68",
      },
    },
  },
];

export const PAYROLLS_REVIEWS_EMPLOYEES = [
  {
    id: "1",
    first_name: "Jenny",
    last_name: "Wilson",
    email: "jane@interlock.io",
    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
  {
    id: "2",
    first_name: "Phoenix",
    last_name: "Baker",
    email: "phoenix@interlock.io",
    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
  {
    id: "3",
    first_name: "Lana",
    last_name: "Steiner",
    email: "ana@interlock.io",
    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
  {
    id: "4",
    first_name: "Demi",
    last_name: "Wilkinson",
    email: "demi@interlock.io",
    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
  {
    id: "5",
    first_name: "Candice",
    last_name: "Wu",
    email: "candice@interlock.io",

    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
  {
    id: "6",
    first_name: "Natali",
    last_name: "Craig",
    email: "natali@interlock.io",
    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
  {
    id: "7",
    first_name: "Drew",
    last_name: "Cano",
    email: "drew@interlock.io",
    status: "Active",
    currency: "USD",
    bonus_total: 1000,
    total_benefits: 1500,
    salary: 32000,
    total: 33000,
    salary_coverage_period: "Monthly",
    payroll_cycle_start_date: "2023-11-02",
    payroll_cycle_end_date: "2023-11-15",
    benefits: [
      {
        type: "Healthcare",
        price: 1000,
      },
      {
        type: "Car allowance",
        price: 500,
      },
    ],
    bonuses: [
      {
        type: "Birthday",
        price: 500,
      },
      {
        type: "Top Achiever",
        price: 500,
      },
    ],
  },
];

export const PAYROLLS_REVIEWS = [
  {
    id: "1",
    company_id: "1",
    company_name: "Interlock - Egypt",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
  },
  {
    id: "2",
    company_id: "2",
    company_name: "Interlock - UAE",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
  },
  {
    id: "3",
    company_id: "3",
    company_name: "Interlock - USA",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
  },
  {
    id: "4",
    company_id: "4",
    company_name: "Interlock - KSA",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
  },
];

export const PAYROLL_DETAILS = [
  {
    id: "1",
    company_id: "1",
    company_name: "Interlock - Egypt",
    currency: "USD",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
    total_employees: 7,
    total_active_Employees: 7,
    total_coast: 370000,
    net_salaries: 330000,
    liabilities: 40000,
    docs: [
      {
        id: 1,
        name: "G2N Report",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
      {
        id: 2,
        name: "Payslips",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
    ],
    all_docs_url:
      "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "2",
    company_id: "2",
    company_name: "Interlock - UAE",
    currency: "USD",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
    total_employees: 7,
    total_active_Employees: 7,
    total_coast: 370000,
    net_salaries: 330000,
    liabilities: 40000,
    docs: [
      {
        id: 1,
        name: "G2N Report",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
      {
        id: 2,
        name: "Payslips",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
    ],
    all_docs_url:
      "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "3",
    company_id: "3",
    company_name: "Interlock - USA",
    currency: "USD",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
    total_employees: 7,
    total_active_Employees: 7,
    total_coast: 370000,
    net_salaries: 330000,
    liabilities: 40000,
    docs: [
      {
        id: 1,
        name: "G2N Report",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
      {
        id: 2,
        name: "Payslips",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
    ],
    all_docs_url:
      "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
  {
    id: "4",
    company_id: "4",
    company_name: "Interlock - KSA",
    currency: "USD",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    cycle_start_date: "2023-11-02",
    cycle_end_date: "2023-11-15",
    payday: "2023-11-16",
    status: "Awaiting Report Submission",
    total_employees: 7,
    total_active_Employees: 7,
    total_coast: 370000,
    net_salaries: 330000,
    liabilities: 40000,
    docs: [
      {
        id: 1,
        name: "G2N Report",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
      {
        id: 2,
        name: "Payslips",
        url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
      },
    ],
    all_docs_url:
      "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
  },
];

export const USER_PAYMENT_PROCESS = {
  user_id: "1",
  total_cost: 150000,
  total_employees: 50,
  currency: "USD",
  companies: [
    {
      id: "1",
      name: "Interlock - Egypt",
      cost: 50000,
      employees: 25,
    },
    {
      id: "2",
      name: "Interlock - UAE",
      cost: 50000,
      employees: 10,
    },
    {
      id: "3",
      name: "Interlock - USA",
      cost: 50000,
      employees: 15,
    },
  ],
};

export const OVARC_BANK_ACCOUNT = {
  currency: "USD",
  beneficiary: "Ovarc",
  account_number: "1234979728",
  swift_code: "234098098222",
  iban: "2984023984098203",
  address: "4140 Parker Rd. Allentown, Cairo ",
};

export const INVOICES_LIST = [
  {
    id: "IND-222",
    description: "5 Laptops",
    status: "Pending",
    category: "Assets",
    total: {
      amount: 5000,
      currency: "USD",
    },
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "Laptop  HP 13’, core i5",
        quantity: 5,
        unit_price: 950,
        amount: 4750,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 250,
        amount: 250,
        currency: "USD",
      },
    ],
  },

  {
    id: "IND-223",
    description: "Printer",
    status: "Pending",
    category: "Assets",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

    total: {
      amount: 450,
      currency: "USD",
    },
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "Printer HP 13’",
        quantity: 1,
        unit_price: 400,
        amount: 400,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 50,
        amount: 50,
        currency: "USD",
      },
    ],
  },

  {
    id: "IND-224",
    description: "Router",
    status: "Pending",
    category: "Assets",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

    total: {
      amount: 200,
      currency: "USD",
    },
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "Router 13’",
        quantity: 1,
        unit_price: 150,
        amount: 150,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 50,
        amount: 50,
        currency: "USD",
      },
    ],
  },

  {
    id: "IND-226",
    description: "EOP Payroll",
    status: "Pending",
    category: "Payroll",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

    total: {
      amount: 35252,
      currency: "USD",
    },
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "EOP Payroll",
        quantity: 1,
        unit_price: 30000,
        amount: 30000,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 5252,
        amount: 5252,
        currency: "USD",
      },
    ],
  },
  {
    id: "IND-227",
    description: "Direct Hire Payroll",
    status: "Over due",
    category: "Payroll",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

    total: {
      amount: 25252,
      currency: "USD",
    },
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "Direct Hire Payroll",
        quantity: 1,
        unit_price: 20000,
        amount: 20000,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 5252,
        amount: 5252,
        currency: "USD",
      },
    ],
  },

  {
    id: "IND-228",
    description: "Life Insurance",
    status: "Pending",
    category: "",
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

    total: {
      amount: 3600,
      currency: "USD",
    },
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "Life Insurance",
        quantity: 1,
        unit_price: 3000,
        amount: 3000,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 600,
        amount: 600,
        currency: "USD",
      },
    ],
  },
  {
    id: "IND-229",
    description: "Health Insurance",
    status: "Pending",
    category: null,
    url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",

    total: {
      amount: 3600,
      currency: "USD",
    },
    issue_date: "2023-11-02",
    due_date: "2023-11-16",
    bill: {
      client_name: "Interlock - Egypt",
      address: "4140 Parker Rd. Allentown, Cairo ",
      registration_number: "PT5222410923",
    },
    items: [
      {
        item_name: "Life Insurance",
        quantity: 1,
        unit_price: 3000,
        amount: 3000,
        currency: "USD",
      },
      {
        item_name: "Ovarc Fees",
        category: "",
        quantity: 1,
        unit_price: 600,
        amount: 600,
        currency: "USD",
      },
    ],
  },
];

export const CATEGORIZED_INVOICES = [
  {
    id: "1",
    category: "Assets",
    status: "Pending",
    invoices: [INVOICES_LIST[0], INVOICES_LIST[1], INVOICES_LIST[2]],
    total: 9650,
    currency: "USD",
    due_date: "2023-11-16",
    issue_date: "2023-11-02",
  },
  {
    id: "2",
    category: "Payroll",
    status: "Pending",
    invoices: [INVOICES_LIST[3], INVOICES_LIST[4]],
    total: 60452,
    currency: "USD",
    due_date: "2023-11-16",
    issue_date: "2023-11-02",
  },
  {
    id: "3",
    category: null,
    status: null,
    invoices: [INVOICES_LIST[5], INVOICES_LIST[6]],
  },
];

export const PAYMENTS_LIST = [
  {
    id: "PAY-133",
    status: "Processing",
    total: {
      currency: "USD",
      amount: 3600,
    },
    date: "2023-11-02",
    invoices: [INVOICES_LIST[0], INVOICES_LIST[1], INVOICES_LIST[2]],
    created_at: "2023-11-02",
    received_at: null,
  },
  {
    id: "PAY-139",
    status: "Awaiting Funds",
    total: {
      currency: "USD",
      amount: 5000,
    },
    date: "2023-11-02",
    invoices: [INVOICES_LIST[3]],
    created_at: "2023-11-02",
    received_at: null,
  },
];

export const ASSETS_OVERVIEW = {
  total_assets: 110,
  total_value: 52434,
  currency: "USD",
  available_assets: 10,
  assets_groups: [
    {
      id: 1,
      name: "Computers",
      value: 30,
    },
    {
      id: 2,
      name: "Furniture",
      value: 20,
    },
    {
      id: 3,
      name: "Electronics",
      value: 10,
    },
    {
      id: 4,
      name: "Office Supplies",
      value: 10,
    },
    {
      id: 5,
      name: "Audio-Visual Equipment",
      value: 20,
    },
    {
      id: 6,
      name: "Kitchen Appliances",
      value: 20,
    },
  ],
  assets_statuses: [
    {
      id: 1,
      status: "In-use",
      value: 20,
    },
    {
      id: 2,
      status: "Under Maintenance",
      value: 40,
    },
    {
      id: 3,
      status: "Lost",
      value: 10,
    },
    {
      id: 4,
      status: "In-active",
      value: 10,
    },
    {
      id: 5,
      status: "Pending Delivery",
      value: 10,
    },
    {
      id: 6,
      status: "Decommissioned",
      value: 10,
    },
  ],
};

export const ASSETS_HUB_LIST = [
  {
    id: "1",
    name: "EliteDesk 800 G5",
    type: "Desktop",
    brand: "HP",
    condition: "80%",
    status: "In-Use",
    assignee: {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
    },
    group: {
      id: 1,
      name: "Computers",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "2",
    name: "Aeron Chair",
    type: "Furniture",
    brand: "Herman Miller",
    condition: "100%",
    status: "Pending Delivery",
    assignee: {
      name: "Phoenix Baker",
      email: "phoenix@interlock.io",
    },
    group: {
      id: 2,
      name: "Furniture",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "3",
    name: "WorkForce Pro WF-4740",
    type: "Printer",
    status: "Under Maintenance",
    brand: "Epson",
    condition: "20%",
    assignee: {
      name: "Lana Steiner",
      email: "lana@interlock.io",
    },
    group: {
      id: 3,
      name: "Electronics",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "4",
    name: "RealPresence Trio 8800",
    type: "Conference Phone",
    status: "In-Use",
    brand: "Polycom",
    condition: "90%",
    assignee: {
      name: "Demi Wilkinson",
      email: "demi@interlock.io",
    },
    group: {
      id: 5,
      name: "Audio-Visual Equipment",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "5",
    name: "MX Master 3",
    type: "Wireless Mouse",
    status: "In-Active",
    brand: "Logitech",
    condition: "60%",
    assignee: null,
    group: {
      id: 3,
      name: "Electronics",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "6",
    name: "Steelcase Gesture Desk",
    type: "Desk",
    status: "In-Use",
    brand: "Steelcase Gesture",
    condition: "100%",
    assignee: {
      name: "Natali Craig",
      email: "natali@interlock.io",
    },
    group: {
      id: 2,
      name: "Furniture",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "7",
    name: "Post-it",
    type: "Sticky Notes",
    status: "Decommissioned",
    brand: "3M",
    condition: "100%",
    assignee: {
      name: "Drew Cano",
      email: "drew@interlock.io",
    },
    group: {
      id: 4,
      name: "Office Supplies",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-01-22",
  },
  {
    id: "11",
    name: "Microwave",
    type: " Microwave 25 Liter",
    brand: "Sharp",
    status: "In-Use",
    assignee: {
      name: "Olivia Rhye",
      position: "Web Designer",
    },
    price: {
      amount: 948.55,
      currency: "USD",
    },
    purchase_date: "2024-01-22",
    group: {
      id: 6,
      name: "Kitchen Appliances",
    },
    condition: "100%",
  },
  {
    id: "12",
    name: "Coffe Machine",
    type: "Coffe Machine 1100 Watts",
    brand: "DeLonghi",
    status: "In-Use",
    assignee: {
      name: "Candice Wu",
      position: "Software Tester",
    },
    price: {
      amount: 767.5,
      currency: "USD",
    },
    purchase_date: "2024-01-22",
    group: {
      id: 6,
      name: "Kitchen Appliances",
    },
    condition: "100%",
  },
  {
    id: "13",
    name: "Laptop",
    type: "M3 15.6 Inch",
    brand: "Apple",
    status: "In-Use",
    assignee: {
      name: "Jenny Wilson",
      position: "UI/UX Designer",
    },
    price: {
      amount: 396.84,
      currency: "USD",
    },
    purchase_date: "2024-01-25",
    group: {
      id: 1,
      name: "Computers",
    },
    condition: "100%",
  },

  {
    id: "15",
    name: "Projector",
    type: "WXCG 3600 Lumens",
    brand: "BenQ",
    status: "In-Use",
    assignee: {
      name: "Lana Steiner",
      position: "Scrum Master",
    },
    price: {
      amount: 351.02,
      currency: "USD",
    },
    purchase_date: "2024-01-02",
    group: {
      id: 5,
      name: "Audio-Visual Equipment",
    },
    condition: "100%",
  },
  {
    id: "16",
    name: "Desk",
    type: "Steelcase Gesture Desk",
    brand: "Steelcase Gesture",
    status: "In-Use",
    assignee: {
      name: "Natali Craig",
      position: "Team Leader",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2024-01-20",
    group: {
      id: 2,
      name: "Furniture",
    },
    condition: "100%",
  },
  {
    id: "17",
    name: "Chair",
    type: "Aeron Chair",
    brand: "Herman Miller",
    status: "In-Use",
    assignee: {
      name: "Phoenix Baker",
      position: "Software Developer",
    },
    price: {
      amount: 250.25,
      currency: "USD",
    },
    purchase_date: "2023-10-22",
    group: {
      id: 2,
      name: "Furniture",
    },
    condition: "100%",
  },
];

export const ASSETS_GROUPS_COLORS = [
  {
    id: 1,
    color: "#033462",
  },
  {
    id: 2,
    color: "#033B6E",
  },
  {
    id: 3,
    color: "#0457A5",
  },
  {
    id: 4,
    color: "#0675DC",
  },
  {
    id: 5,
    color: "#2191F9",
  },
  {
    id: 6,
    color: "#EAECF0",
  },
];
export const ASSETS_Statuses_COLORS = [
  {
    id: 1,
    color: "#033462",
  },
  {
    id: 2,
    color: "#033B6E",
  },
  {
    id: 3,
    color: "#0457A5",
  },
  {
    id: 4,
    color: "#0675DC",
  },
  {
    id: 5,
    color: "#2191F9",
  },
  {
    id: 6,
    color: "#EAECF0",
  },
];

export const ASSETS_DETAILS = [
  {
    id: "1",
    name: "EliteDesk 800 G5",
    type: "Desktop",
    brand: "HP",
    condition: {
      percentage: "80%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Use"],
    assignee: {
      id: "8",
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      position: "Web Designer",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Olivia Rhye",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Olivia Rhye",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "2",
    name: "Aeron Chair",
    type: "Furniture",
    brand: "Herman Miller",
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    status: ["Pending Delivery"],
    assignee: {
      id: "2",
      name: "Phoenix Baker",
      email: "phoenix@interlock.io",
      position: "Software Developer",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Phoenix Baker",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Phoenix Baker",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "3",
    name: "WorkForce Pro WF-4740",
    type: "Printer",
    brand: "Epson",
    condition: {
      percentage: "20%",
      last_reviewed: "2023-10-22",
    },
    status: ["Under Maintenance"],
    assignee: {
      id: "3",
      name: "Lana Steiner",
      email: "lana@interlock.io",
      position: "Scrum Master",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Lana Steiner",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Lana Steiner",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "4",
    name: "RealPresence Trio 8800",
    type: "Conference Phone",

    brand: "Polycom",
    condition: {
      percentage: "90%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Use", "Under Maintenance"],
    assignee: {
      id: "4",
      name: "Demi Wilkinson",
      email: "demi@interlock.io",
      position: "Ethical Hacker",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Demi Wilkinson",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Demi Wilkinson",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "5",
    name: "MX Master 3",
    type: "Wireless Mouse",
    brand: "Logitech",
    condition: {
      percentage: "60%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Active"],
    assignee: null,
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Unsigned",
          subject: "Olivia Rhye",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Olivia Rhye",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "6",
    name: "Steelcase Gesture Desk",
    type: "Desk",
    brand: "Steelcase Gesture",
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    status: ["In-Use"],
    assignee: {
      id: "6",
      name: "Natali Craig",
      email: "natali@interlock.io",
      position: "Team Leader",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Natali Craig",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Natali Craig",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "7",
    name: "Post-it",
    type: "Sticky Notes",
    brand: "3M",
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    status: ["Decommissioned"],
    assignee: {
      id: "7",
      name: "Drew Cano",
      email: "drew@interlock.io",
      position: "Software Development",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "requested",
          subject: "Maintenance",
          message: "Printer does not turn on",
        },
      },
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Drew Cano",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "11",
    name: "Microwave",
    type: " Microwave 25 Liter",
    brand: "Sharp",
    status: ["In-Use"],
    assignee: {
      id: "8",
      name: "Olivia Rhye",
      position: "Web Designer",
      email: "olivia@untitledui.com",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 948.55,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 900.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Olivia Rhye",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "12",
    name: "Coffe Machine",
    type: "Coffe Machine 1100 Watts",
    brand: "DeLonghi",
    status: ["In-Use"],
    assignee: {
      id: "5",
      name: "Candice Wu",
      position: "Software Tester",
      email: "candice@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 767.5,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 700.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Candice Wu",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "13",
    name: "Laptop",
    type: "M3 15.6 Inch",
    brand: "Apple",
    status: ["In-Use"],
    assignee: {
      id: "1",
      name: "Jenny Wilson",
      position: "UI/UX Designer",
      email: "jane@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 396.84,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 300.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Jenny Wilson",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },

  {
    id: "15",
    name: "Projector",
    type: "WXCG 3600 Lumens",
    brand: "BenQ",
    status: ["In-Use"],
    assignee: {
      id: "3",
      name: "Lana Steiner",
      position: "Scrum Master",
      email: "lana@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 351.02,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 300.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Lana Steiner",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },

  {
    id: "16",
    name: "Desk",
    type: "Steelcase Gesture Desk",
    brand: "Steelcase Gesture",
    status: ["In-Use"],
    assignee: {
      id: "6",
      name: "Natali Craig",
      position: "Team Leader",
      email: "natali@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Natali Craig",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
  {
    id: "17",
    name: "Chair",
    type: "Aeron Chair",
    brand: "Herman Miller",
    status: ["In-Use"],
    assignee: {
      id: "2",
      name: "Phoenix Baker",
      position: "Software Developer",
      email: "phoenix@interlock.io",
    },
    condition: {
      percentage: "100%",
      last_reviewed: "2023-10-22",
    },
    warranty_and_supplier: {
      warranty_period: "3 years",
      supplier: "BTech",
      supplier_contact: "412-4228-2322",
    },
    maintenance_and_service: {
      maintenance_schedule: "6 months",
      last_service: "Not applicable",
      service_provider: "BTech",
    },
    disposal_information: {
      disposal_date: "2025-10-22",
      method_of_disposal: "Donate",
      disposal_reason: "Incompatibility with New Systems",
    },
    financial: {
      purchase_date: "2023-10-22",
      purchase_price: 250.25,
      currency: "USD",
      depreciation_method: "Not applicable",
      current_value: 200.25,
    },
    asset_log: [
      {
        date: "2023-10-22",
        employee_name: "Drew Cano",
        action: {
          type: "Assigned",
          subject: "Phoenix Baker",
        },
      },

      {
        date: "2023-10-22",
        employee_name: "Ovarc Admin",
        action: {
          type: "Purchased",
          file: {
            url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
            name: "purchase_order.pdf",
            size: "2.2 MB",
          },
        },
      },
    ],
  },
];

export const ASSETS_CATEGORIES_OPTIONS = [
  {
    value: "Computers",
    label: "Computers",
  },
  {
    value: "Furniture",
    label: "Furniture",
  },
  {
    value: "Electronics",
    label: "Electronics",
  },
  {
    value: "Office Supplies",
    label: "Office Supplies",
  },
  {
    value: "Audio-Visual Equipment",
    label: "Audio-Visual Equipment",
  },
  {
    value: "Kitchen Appliances",
    label: "Kitchen Appliances",
  },
];
export const ALL_ASSETS_STATUSES = [
  {
    id: 1,
    status: "In-Use",
  },
  {
    id: 2,
    status: "Under Maintenance",
  },
  {
    id: 3,
    status: "Lost",
  },
  {
    id: 4,
    status: "In-Active",
  },
  {
    id: 5,
    status: "Pending Delivery",
  },
  {
    id: 6,
    status: "Decommissioned",
  },
];

export const ALL_ASSETS_GROUPS = [
  {
    id: 1,
    name: "Computers",
  },
  {
    id: 2,
    name: "Furniture",
  },
  {
    id: 3,
    name: "Electronics",
  },
  {
    id: 4,
    name: "Office Supplies",
  },
  {
    id: 5,
    name: "Audio-Visual Equipment",
  },
  {
    id: 6,
    name: "Kitchen Appliances",
  },
];

export const JOB_HIERARCHY = [
  {
    category: "Software Development",
    description: "Software Development",
    level: "a",
    subcategory: "Junior Software Developer",
    role: "Software Developer",
  },
  {
    category: "Software Development",
    description: "Software Development b ",
    level: "b",
    subcategory: "senior Software Developer",
    role: "Data analyst",
  },
  {
    category: "Sales",
    description: "sales person",
    level: "a",
    subcategory: "Sales Associate",
    role: "Sales Associate",
  },
];
