import styled from "styled-components";
import { Spaces } from "../../../constants/styles";

export const LoginFormContainer = styled.div`
  margin-block: ${Spaces.spacing_4xl};
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    width: 80%;
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: ${Spaces.spacing_3xl};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;
