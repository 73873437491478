import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { ActionCardContainer, ActionCardTitle } from "../ActionCard/styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import {
  CardContainerData,
  CardDataDot,
  CardDataRow,
  CardDataText,
} from "./styled";

interface PieChartCardProps {
  title: string;
  data: { name: string; value: number }[];
  colors: string[];
}

const PieChartCard: React.FC<PieChartCardProps> = ({ title, data, colors }) => {
  const renderData = () => {
    return data.map((item, index) => (
      <CardDataRow>
        <CardDataDot color={colors[index]} />
        <CardDataText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          {item.name} {item.value}
        </CardDataText>
      </CardDataRow>
    ));
  };
  return (
    <ActionCardContainer style={{ position: "relative", flexDirection: "row" }}>
      <div>
        <ActionCardTitle size={FontSizes.TX_MD} weight={FontWeights.MEDIUM}>
          {title}
        </ActionCardTitle>
        <CardContainerData>{renderData()}</CardContainerData>
      </div>

      <ResponsiveContainer width={170} style={{ flex: 1 }}>
        <PieChart height={120}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            outerRadius={45}
            innerRadius={35}
            cx={"75%"}
            cy={"50%"}
          >
            {data.map((_, index) => (
              <>
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              </>
            ))}
          </Pie>
          <text
            x={"75%"}
            y={"40%"}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={FontSizes.TX_SM}
            fontWeight={FontWeights.REGULAR}
          >
            Total
          </text>
          <text
            x={"75%"}
            y={"57%"}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={FontSizes.SM}
            fontWeight={FontWeights.SEMIBOLD}
          >
            {data.reduce((acc, curr) => acc + curr.value, 0)}
          </text>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </ActionCardContainer>
  );
};

export default PieChartCard;
