import BriefCase from "../Images/icons/briefcase.svg";
import BankNote from "../Images/icons/bank-note.svg";
import Plus from "../Images/icons/plusBlue.svg";
import Receipt from "../Images/icons/receipt-check.svg";
import WhitePlus from "../Images/icons/plus.svg";
import WhiteBankNote from "../Images/icons/bank-note-white.svg";

export let CreateNewEmployeeContractSteps = [
  {
    title: "Job Details",
    description:
      "Clearly outline the employee’s responsibilities and duties within the contract.",
    completed: false,
    icon: BriefCase,
  },
  {
    title: "Compensation",
    description:
      "Specify the agreed salary and any additional financial benefits.",
    completed: false,
    completedIcon: WhiteBankNote,
    icon: BankNote,
  },
  // {
  //   title: "Extras",
  //   description: "Detail supplementary perks like health and life insurance.",
  //   completed: false,
  //   completedIcon: WhitePlus,
  //   icon: Plus,
  // },
  {
    title: "Contract Summary",
    description: "Summarize key terms and conditions for mutual understanding.",
    completed: false,
    icon: Receipt,
    completedIcon: Receipt,
  },
];
