import {
  ContentContainer,
  ModalTitle,
  ModalFooter,
  IconContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { ModalProps } from "../../../../types/componentsProps";
import InputField from "../../../shared/InputField";
import { useState } from "react";
import Calender from "../../../../Images/icons/calendarDarkBlue.svg";

const UnrollOnSpecificDateModal = ({
  open,
  onClose,
  onConfirm,
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  onConfirm: (date: string) => void;
}) => {
  const [date, setDate] = useState<string>("");
  const [error, setError] = useState<string>("");
  const onConfirmPressed = () => {
    if (date === "") {
      setError("Please select a date");
      return;
    }
    onConfirm(date);
  };
  return (
    <Modal onClose={onClose} open={open}>
      <ContentContainer>
        <IconContainer>
          <img src={Calender} alt="Calender" />
        </IconContainer>
        <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Please select date of unenrollment
        </ModalTitle>
        <InputField
          label="Select date"
          type="date"
          placeholder="Select date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          onFocus={() => setError("")}
          containerStyle={{ width: "100%", marginBottom: "2rem" }}
          error={error}
        />
        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            onClick={onClose}
            style={{ flex: 1 }}
          />

          <Button
            text="Confirm"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            onClick={onConfirmPressed}
            style={{ flex: 1 }}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};

export default UnrollOnSpecificDateModal;
