import React, { useEffect } from "react";
import {
  ManageHeader,
  PageSubtitle,
  PageTitle,
  HeaderFirstRow,
  ManageContentContainer,
  TitleContainer,
  ContentContainer,
  FiltersContainer,
  TableContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import PlusIcon from "../../../Images/icons/plus.svg";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";
import Badge from "../../shared/Badge";
import {
  BadgeTypes,
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import EmployeeTable from "./EmployeeTable";
import SearchInput from "../../shared/SearchInput";
import { ALL_STATUS_OPTIONS } from "../../../mockServer/mockData";
import DropdownList from "../../shared/DropdownList";
import { useGetEmployees } from "../../../queries/people";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { setEmployeesTableVariables } from "../../../redux/slices/peopleSlice";

const Manage: React.FC = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const {
    search,
    selectedStatus,
    currentPage,
    sortKey,
    sortDirection,
    filteredEmployees,
    itemsPerPage,
  } = useAppSelector((state) => state.people.employees_table_variables);
  const activeCompany = useAppSelector(
    (state) => state.companies.activeCompany
  );
  const dispatch = useAppDispatch();

  const { data: employeesData, error } = useGetEmployees({
    company_id: activeCompany?.id ?? "",
    filter: {
      employee_status: selectedStatus.label,
    },
    ...(search && { search }),

    sort: {
      sortKey,
      sortDirection,
    },
    page: currentPage,
    page_size: itemsPerPage,
  });

  useEffect(() => {
    if (employeesData?.data) {
      dispatch(
        setEmployeesTableVariables({
          filteredEmployees: employeesData.data.employees,
          currentPage: employeesData.data._metadata.page,
          totalRecords: employeesData.data._metadata.total_records,
          totalPages: employeesData.data._metadata.last_page,
        })
      );
    }
  }, [employeesData]);

  useEffect(() => {
    if (error) {
      dispatch(setEmployeesTableVariables({ filteredEmployees: null }));
      const err = error as unknown as AxiosError;
      switch (err.response?.status) {
        case 401:
          navigate("/login");
          break;
        case 403:
          navigate("/404");
          break;
        default:
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while fetching employees.",
            description: "Please try again later.",
          });
          break;
      }
    }
  }, [error]);

  const renderEmployees = () => {
    return (
      <ContentContainer>
        <FiltersContainer>
          <SearchInput
            value={search}
            onChange={(e) =>
              dispatch(setEmployeesTableVariables({ search: e.target.value }))
            }
            placeholder="Search for people"
            containerStyle={{ width: "36.5%" }}
          />
          <DropdownList
            options={ALL_STATUS_OPTIONS}
            selectedOption={selectedStatus}
            setSelectedOption={(status: DropdownListOption) =>
              dispatch(
                setEmployeesTableVariables({
                  selectedStatus: status,
                  currentPage: 1,
                })
              )
            }
            containerStyle={{ width: "21.3%" }}
            style={{ height: "48px", marginTop: "0px" }}
          />
        </FiltersContainer>
        <TableContainer>
          <EmployeeTable />
        </TableContainer>
      </ContentContainer>
    );
  };

  return (
    <>
      <ManageHeader>
        <HeaderFirstRow>
          <TitleContainer>
            <PageTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
              Manage People
            </PageTitle>
            {filteredEmployees?.length ?? 0 > 0 ? (
              <Badge
                text={(filteredEmployees?.length ?? 0).toString()}
                color={BadgeTypes.grayBlue}
              />
            ) : null}
          </TitleContainer>

          <Button
            text="Add Employee"
            variant={ButtonVariants.primary}
            size={ButtonSizes.md}
            iconLeading={
              <img src={PlusIcon} alt="plus" width={20} height={20} />
            }
            onClick={() => navigate("/people/new-employee")}
          />
        </HeaderFirstRow>
        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Effortlessly monitor and manage your employees’ information.
        </PageSubtitle>
      </ManageHeader>

      <ManageContentContainer>
        {!filteredEmployees ? <EmptyState /> : renderEmployees()}
      </ManageContentContainer>
    </>
  );
};

export default Manage;
