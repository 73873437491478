import { useEffect } from "react";
import {
  GeneralDataContainer,
  GeneralDataLabel,
  GeneralDataRow,
  GeneralDataValue,
  DataContainer,
  DataContainerHeader,
  DataContainerTitle,
  OverviewContainer,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import { useParams } from "react-router-dom";
import { useGetEmployeeContract } from "../../../../queries/people";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { setActiveEmployeeContract } from "../../../../redux/slices/peopleSlice";
import moment from "moment";
import ContractOverView from "./ContractOverView";

const Overview = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { activeEmployee } = useAppSelector((state) => state.people);
  const { data: contract, error } = useGetEmployeeContract(id);

  useEffect(() => {
    if (contract) {
      dispatch(setActiveEmployeeContract(contract.data.contract));
    }
  }, [contract]);

  useEffect(() => {
    return () => {
      dispatch(setActiveEmployeeContract(null));
    };
  }, [error]);

  const renderOverview = () => {
    return (
      <DataContainer>
        <DataContainerHeader>
          <DataContainerTitle
            size={FontSizes.TX_MD}
            weight={FontWeights.SEMIBOLD}
          >
            General Information
          </DataContainerTitle>
        </DataContainerHeader>

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            First Name
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {activeEmployee?.user.first_name}
          </GeneralDataValue>
        </GeneralDataRow>

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Last Name
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {activeEmployee?.user.last_name}
          </GeneralDataValue>
        </GeneralDataRow>

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Start Date
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {moment(activeEmployee?.joining_date).format("DD, MMM YYYY")}
          </GeneralDataValue>
        </GeneralDataRow>

        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Nationality
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {activeEmployee?.user_information.country_of_citizenship}
          </GeneralDataValue>
        </GeneralDataRow>
        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Work Email
          </GeneralDataLabel>
          <GeneralDataContainer>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {activeEmployee?.user.email}
            </GeneralDataValue>
          </GeneralDataContainer>
        </GeneralDataRow>
        <GeneralDataRow>
          <GeneralDataLabel
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Company
          </GeneralDataLabel>
          <GeneralDataValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {activeEmployee?.company.name}
          </GeneralDataValue>
        </GeneralDataRow>
      </DataContainer>
    );
  };

  return (
    <OverviewContainer>
      {renderOverview()} {<ContractOverView />}
    </OverviewContainer>
  );
};

export default Overview;
