import styled from "styled-components";
import { Colors, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import { Link } from "react-router-dom";

export const RequestDetails = styled.div`
  width: 100%;
  padding-block: ${Spaces.spacing_3xl};
`;
export const RequestDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-block-end: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;
export const RequestDetailsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 23px;
  flex-wrap: wrap;
`;
export const CardsColumn = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
`;

export const RequestTimelineRow = styled.div`
  display: flex;
  align-items: start;
`;

export const RequestTimelineIcons = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-end: 6px;
`;

export const RequestTimelineLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  text-decoration: none;
`;

export const RequestTimelineLinkText = styled(Typography)`
  color: ${Colors.text_secondary_on_brand};
`;
export const RequestTimelineText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const RequestTimelineDate = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  margin-left: auto;
`;
