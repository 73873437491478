import moment from "moment";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import { PAYMENT_STATUS_BADGE_COLOR } from "../../../types";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Badge from "../../shared/Badge";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../shared/Table/styled";
import { TableTextBold } from "../InvoiceDetails/styled";
import { PaymentsTableContainer, TableFadedText } from "./styled";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { useGetAllPayments } from "../../../queries/payment";
import PeopleEmptyState from "../../people/PeopleEmptyState";

const PaymentsList = () => {
  const { data: payments } = useGetAllPayments();
  const navigate = useNavigate();

  const renderRows = () => {
    return (
      payments &&
      payments.map((payment) => {
        return (
          <TableRow>
            <TableCell>
              <TableTextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {payment.id}
              </TableTextBold>
            </TableCell>
            <TableCell>
              <Badge
                text={payment.status}
                dot
                color={PAYMENT_STATUS_BADGE_COLOR[payment.status]}
              />
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {payment.invoices.length}
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {currencyFormatter(
                  payment.total.amount,
                  payment.total.currency
                )}
              </TableFadedText>
            </TableCell>

            <TableCell>
              <TableFadedText
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {moment(payment.date).format("DD MMM, YYYY")}
              </TableFadedText>
            </TableCell>
            <TableCell>
              <Button
                text="View Details"
                onClick={() =>
                  navigate(`/payment/payment-details/${payment.id}`)
                }
                variant={ButtonVariants.secondary_color}
                size={ButtonSizes.sm}
              />
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  return payments?.length && payments.length > 0 ? (
    <PaymentsTableContainer>
      <Table>
        <TableHeader>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Payment ID
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Status
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Number of Invoices
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Value
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Payment Date
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Actions
            </TableFadedText>
          </TableCell>
        </TableHeader>
        {renderRows()}
      </Table>
    </PaymentsTableContainer>
  ) : (
    <PeopleEmptyState
      title="No Payments Found"
      description="You have not made any payments yet."
    />
  );
};

export default PaymentsList;
