import React from "react";
import { Company } from "../../../../types";
import Modal from "../../../shared/Modal";
import {
  CompanyDetailsLabel,
  CompanyDetailsRow,
  CompanyDetailsValue,
  DataContainer,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleAndIcon,
  Flag,
  Country,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import CloseIcon from "../../../../Images/icons/alertClose.svg";
import CompanyDetailsIcon from "../../../../Images/icons/companyDetailsIcon.svg";
import Button from "../../../shared/Button";

interface CompanyDetailsModalProps {
  company: Company | null;
  onClose: () => void;
  open: boolean;
}

const CompanyDetailsModal: React.FC<CompanyDetailsModalProps> = ({
  company,
  onClose,
  open,
}) => {
  const renderCompanyDetails = () => {
    if (!company) return null;
    return (
      <DataContainer>
        <CompanyDetailsRow>
          <CompanyDetailsLabel>Country</CompanyDetailsLabel>
          <Country>
            <Flag
              src={company.logo.url}
              alt="country-flag"
              width={16}
              height={16}
            />
            <CompanyDetailsValue>{company.plan.country}</CompanyDetailsValue>
          </Country>
        </CompanyDetailsRow>
        <CompanyDetailsRow>
          <CompanyDetailsLabel>Company Type</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.plan.type}</CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Industry</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.industry}</CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Legal Company Name</CompanyDetailsLabel>
          <CompanyDetailsValue>
            {company.legal_company_name}
          </CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Phone</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.phone}</CompanyDetailsValue>
        </CompanyDetailsRow>

        <CompanyDetailsRow>
          <CompanyDetailsLabel>Tax ID</CompanyDetailsLabel>
          <CompanyDetailsValue>{company.tax_id}</CompanyDetailsValue>
        </CompanyDetailsRow>
        <CompanyDetailsRow>
          <CompanyDetailsLabel>Registration Number</CompanyDetailsLabel>
          <CompanyDetailsValue>
            {company.registration_number}
          </CompanyDetailsValue>
        </CompanyDetailsRow>
      </DataContainer>
    );
  };

  if (!company) return null;
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer>
        <ModalHeader>
          <ModalTitleAndIcon>
            <img
              src={CompanyDetailsIcon}
              alt="company-details-icon"
              width={48}
              height={48}
            />
            <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
              Company details
            </ModalTitle>
          </ModalTitleAndIcon>

          <img src={CloseIcon} alt="close-icon" onClick={onClose} />
        </ModalHeader>
        {renderCompanyDetails()}

        <ModalFooter>
          <Button
            text="Close"
            onClick={onClose}
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
          />
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
};

export default CompanyDetailsModal;
