import React, { useState } from "react";
import {
  CompaniesContainer,
  CompaniesHeader,
  CompaniesList,
  CompaniesTitle,
  CompanyCard,
  CompanyCardTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import { COMPANIES } from "../../../mockServer/mockData";
import { Company } from "../../../types";
import CompanyDetailsModal from "./CompanyDetailsModal";
import AddCompanyModal from "./AddCompanyModal";

const Companies: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const [chosenCompany, setChosenCompany] = useState<Company | null>(null);
  const companies = COMPANIES;

  const renderCompanies = () => {
    return companies.map((company: Company) => {
      return (
        <CompanyCard
          key={company.name}
          onClick={() => {
            setChosenCompany(company);
            setOpen(true);
          }}
        >
          <img
            src={company.logo.url}
            alt="company-flag"
            width={40}
            height={40}
          />
          <CompanyCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            {company.name}
          </CompanyCardTitle>
        </CompanyCard>
      );
    });
  };

  return (
    <CompaniesContainer>
      <CompaniesHeader>
        <CompaniesTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Companies
        </CompaniesTitle>
        {companies.length === 0 ? (
          <Button
            text={"Add Company"}
            variant={ButtonVariants.link_color}
            size={ButtonSizes.md}
            style={{ color: "#2191F9" }}
            onClick={() => {
              setOpenAddCompany(true);
            }}
          />
        ) : null}
      </CompaniesHeader>
      <CompaniesList>
        {companies.length > 0 ? renderCompanies() : null}
      </CompaniesList>
      <CompanyDetailsModal
        open={open}
        company={chosenCompany}
        onClose={() => {
          setOpen(false);
          setChosenCompany(null);
        }}
      />
      <AddCompanyModal
        open={openAddCompany}
        company={chosenCompany}
        onClose={() => setOpenAddCompany(false)}
      />
    </CompaniesContainer>
  );
};

export default Companies;
