import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import qs from "qs";
import { CreatePaymentParams, GetInvoicesListParams } from "../types/apisTypes";
import { CategorizedInvoices, Invoice, Payment, UserPayment } from "../types";

export const useGetCategorizedInvoices = (params: GetInvoicesListParams) => {
  return useQuery<CategorizedInvoices, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payment?${qs.stringify(params)}`);
      return response.data.data.invoices;
    },
    retry: false,

    queryKey: ["payments", params],
  });
};

export const useGetInvoiceDetails = (invoiceId: string | undefined) => {
  return useQuery<Invoice, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payment/${invoiceId}`);
      return response.data.data.invoice;
    },
    retry: false,
    queryKey: ["invoices", invoiceId],
    enabled: !!invoiceId,
  });
};

export const useCreatePayment = (params: CreatePaymentParams) => {
  return useMutation<Payment, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/payment", params);
      return response.data.data.payment;
    },
  });
};

export const useGetAllPayments = () => {
  return useQuery<UserPayment[], Error>({
    queryFn: async () => {
      const response = await apiClient.get("/payment-list");
      return response.data.data.payments;
    },
    retry: false,
    queryKey: ["paymentsList"],
  });
};

export const useGetPaymentDetails = (paymentId: string | undefined) => {
  return useQuery<UserPayment, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payment-list/${paymentId}`);
      return response.data.data.payment;
    },
    retry: false,
    queryKey: ["paymentsList", paymentId],
    enabled: !!paymentId,
  });
};
