import React from "react";
import {
  EmptyStateContainer,
  EmptyStateImg,
  EmptyStateContent,
  EmptyStateTitle,
  EmptyStateDescription,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import EmptyStateBannerImg from "../../../../Images/PeopleEmptyStateImg.svg";
import { useNavigate } from "react-router-dom";

const EmptyState: React.FC = () => {
  const navigate = useNavigate();
  return (
    <EmptyStateContainer>
      <EmptyStateContent>
        <EmptyStateTitle size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
          Effortlessly monitor and manage{"\n"} your employees{" "}
        </EmptyStateTitle>
        <EmptyStateDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.MEDIUM}
        >
          Here, you can effortlessly monitor and manage your employees’
          information. Take control of your workforce by easily updating
          profiles, assigning roles, and tracking performance—all from one
          convenient location. Get started by adding your employees or explore
          the various features available to streamline your HR processes. With
          our intuitive tools, managing your team has never been simpler.
        </EmptyStateDescription>
        <Button
          text="Add Employee"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.sm}
          style={{ width: "fit-content" }}
          onClick={() => navigate("/people/new-employee")}
        />
      </EmptyStateContent>
      <EmptyStateImg src={EmptyStateBannerImg} alt="empty state" />
    </EmptyStateContainer>
  );
};

export default EmptyState;
