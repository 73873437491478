import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { updateActivePayrollDetailsEmployeesVariables } from "../../../redux/slices/payrollSlice";
import { ALL_STATUS_OPTIONS } from "../../../mockServer/mockData";
import {
  PayrollReviewCardRow,
  PayrollReviewCardRowValuesContainer,
  DownloadButton,
  PayrollReviewContainer,
  PayrollReviewHeader,
  PayrollReviewHeaderTitleContainer,
  PayrollReviewHeadersButtonsContainer,
  PayrollReviewSubtitle,
  PayrollReviewTableContainer,
  PayrollReviewTitle,
  SearchContainer,
  TableHeaderText,
  PayrollReviewTableHeader,
  NameCell,
  NameCellText,
  EmailCell,
  CellText,
  CellSubtext,
} from "./styled";
import {
  Card,
  CardHeader,
  CardTitle,
  CardRow,
  CardRowValue,
  CardRowTitle,
} from "../../shared/Card";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Badge from "../../shared/Badge";
import {
  BadgeTypes,
  DropdownListOption,
  SnackbarTypes,
} from "../../../types/componentsProps";
import moment from "moment";
import Button from "../../shared/Button";
import Close from "../../../Images/icons/x-close.svg";
import Check from "../../../Images/icons/thin-check.svg";
import SearchInput from "../../shared/SearchInput";
import DropdownList from "../../shared/DropdownList";
import {
  Table,
  TableCell,
  TableRow,
  TableRowDynamic,
} from "../../shared/Table/styled";
import InitialsProfilePic from "../../shared/InitialsProfilePic";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import { PayrollReviewEmployee } from "../../../types";
import EmployeePayrollDetailsModal from "../EmployeePayrollDetailsModal";
import SubmitPayrollReportModal from "../SubmitPayrollReportModal";
import {
  useGetPayrollReview,
  useGetPayrollReviewEmployees,
} from "../../../queries/payroll";
import { AxiosError } from "axios";
import { useSnackbar } from "../../../hooks/useSnackbar";
import TablePagination from "../../shared/TablePagination";

const PayrollReview: React.FC = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const {
    search,
    selectedStatus,
    currentPage,
    itemsPerPage,
    totalPages,
    filteredEmployees,
  } = useAppSelector(
    (state) => state.payroll.activePayrollDetailsEmployeesVariables
  );

  const [openEmployeeDetailsModal, setOpenEmployeeDetailsModal] =
    useState(false);
  const [openSubmitPayrollModal, setOpenSubmitPayrollModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] =
    useState<PayrollReviewEmployee | null>(null);

  const { company_id } = useParams<{ company_id: string }>();
  const dispatch = useAppDispatch();

  const { data: review, error } = useGetPayrollReview(company_id);
  const { data: employees, error: employeesError } =
    useGetPayrollReviewEmployees(company_id, {
      search,
      status: selectedStatus.value,
      page: currentPage,
      page_size: itemsPerPage,
    });

  useEffect(() => {
    if (employees?.data) {
      dispatch(
        updateActivePayrollDetailsEmployeesVariables({
          filteredEmployees: employees.data.payrollEmployees,
          currentPage: employees.data._metadata.page,
          totalRecords: employees.data._metadata.total_records,
          totalPages: employees.data._metadata.last_page,
        })
      );
    }
  }, [employees]);

  useEffect(() => {
    if (error || employeesError) {
      const err = (error ?? employeesError) as unknown as AxiosError;
      switch (err.response?.status) {
        case 401:
          navigate("/login");
          break;
        case 403:
          navigate("/404");
          break;
        default:
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while fetching payroll review.",
            description: "Please try again later.",
          });
          break;
      }
    }
  }, [error, employeesError]);

  if (!company_id) {
    <Navigate to="/404" />;
  }

  const renderRows = () => {
    return filteredEmployees.map((employee: PayrollReviewEmployee) => {
      return (
        <TableRow key={employee.id}>
          <TableCell>
            <NameCell
              onClick={() => {
                setSelectedEmployee(employee);
                setOpenEmployeeDetailsModal(true);
              }}
            >
              <InitialsProfilePic
                fullName={`${employee.first_name} ${employee.last_name}`}
                style={{ width: "32px", height: "32px" }}
              />
              <div>
                <NameCellText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {employee.first_name} {employee.last_name}
                </NameCellText>

                <EmailCell size={FontSizes.TX_XS} weight={FontWeights.SEMIBOLD}>
                  {employee.email}
                </EmailCell>
              </div>
            </NameCell>
          </TableCell>
          <TableCell>
            <Badge text={employee.status ?? ""} color={BadgeTypes.success} />
          </TableCell>

          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(employee.bonus_total, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                Bonus
              </CellSubtext>
            </div>
          </TableCell>
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(employee.total_benefits, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                Benefits
              </CellSubtext>
            </div>
          </TableCell>
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {currencyFormatter(employee.salary, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_XS} weight={FontWeights.REGULAR}>
                Salary
              </CellSubtext>
            </div>
          </TableCell>
          <TableCell>
            <div>
              <CellText size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
                {currencyFormatter(employee.total, employee.currency)}
              </CellText>
              <CellSubtext size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total
              </CellSubtext>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <PayrollReviewTableContainer>
        <PayrollReviewTableHeader>
          <TableHeaderText size={FontSizes.TX_XS} weight={FontWeights.SEMIBOLD}>
            Total of {employees?.data._metadata.total_records} employees
          </TableHeaderText>

          <DownloadButton href={review?.url} download target="_blank">
            Download report
          </DownloadButton>
        </PayrollReviewTableHeader>
        <Table>
          {renderRows()}
          {totalPages > 1 && (
            <TableRowDynamic height="68px">
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={(page: number) =>
                  dispatch(
                    updateActivePayrollDetailsEmployeesVariables({
                      currentPage: page,
                    })
                  )
                }
                totalPages={totalPages}
              />
            </TableRowDynamic>
          )}
        </Table>
      </PayrollReviewTableContainer>
    );
  };

  return review ? (
    <PayrollReviewContainer>
      <PayrollReviewHeader>
        <div>
          <PayrollReviewHeaderTitleContainer>
            <PayrollReviewTitle
              size={FontSizes.SM}
              weight={FontWeights.SEMIBOLD}
            >
              Payroll Review
            </PayrollReviewTitle>
            <Badge
              color={BadgeTypes.grayBlue}
              text={`${moment(review.cycle_start_date).format(
                "DD MMM"
              )} - ${moment(review.cycle_end_date).format("DD MMM. YYYY")}`}
            />
          </PayrollReviewHeaderTitleContainer>
          <PayrollReviewSubtitle
            size={FontSizes.TX_MD}
            weight={FontWeights.REGULAR}
          >
            {review.company_name}
          </PayrollReviewSubtitle>
        </div>
        <PayrollReviewHeadersButtonsContainer>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.sm}
            iconLeading={<img src={Close} alt="Close" width={20} height={20} />}
          />
          <Button
            text="Submit"
            variant={ButtonVariants.primary}
            size={ButtonSizes.sm}
            iconLeading={<img src={Check} alt="Close" width={20} height={20} />}
            onClick={() => setOpenSubmitPayrollModal(true)}
          />
        </PayrollReviewHeadersButtonsContainer>
      </PayrollReviewHeader>
      <Card>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payroll report
          </CardTitle>
        </CardHeader>
        <PayrollReviewCardRowValuesContainer>
          <PayrollReviewCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Submission due date
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {moment(review.payday).format("DD MMM, YYYY")}
            </CardRowValue>
          </PayrollReviewCardRow>

          <PayrollReviewCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payroll Status
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              <Badge text={review.status} color={BadgeTypes.warning} />
            </CardRowValue>
          </PayrollReviewCardRow>
        </PayrollReviewCardRowValuesContainer>
      </Card>

      <SearchContainer>
        <DropdownList
          options={ALL_STATUS_OPTIONS}
          selectedOption={selectedStatus}
          setSelectedOption={(status: DropdownListOption) =>
            dispatch(
              updateActivePayrollDetailsEmployeesVariables({
                selectedStatus: status,
              })
            )
          }
          containerStyle={{ width: "21.3%" }}
          style={{ marginTop: "0px" }}
        />
        <SearchInput
          placeholder="Search"
          containerStyle={{ boxSizing: "border-box", width: "40%" }}
          value={search}
          onChange={(e) => {
            dispatch(
              updateActivePayrollDetailsEmployeesVariables({
                search: e.target.value,
              })
            );
          }}
        />
      </SearchContainer>
      {renderTable()}
      <EmployeePayrollDetailsModal
        open={openEmployeeDetailsModal}
        onClose={() => {
          setOpenEmployeeDetailsModal(false);
        }}
        employee={selectedEmployee}
      />
      <SubmitPayrollReportModal
        open={openSubmitPayrollModal}
        onClose={() => {
          setOpenSubmitPayrollModal(false);
        }}
        companyId={company_id || ""}
      />
    </PayrollReviewContainer>
  ) : null;
};

export default PayrollReview;
