import { useEffect, useState } from "react";
import PeopleEmptyState from "../../PeopleEmptyState";
import VerticalModal from "../../../shared/VerticalModal";
import AddAssetsModal from "../../NewEmployeeContract/Extras/Assets/AddAssetsModal";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../shared/Table/styled";
import Typography from "../../../shared/Typography";
import {
  AssetTitle,
  AssetType,
  AssetsContainer,
  AssetsHeader,
  AssetsTitle,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Badge from "../../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../../types/componentsProps";
import ActionsDropDown from "../../../shared/ActionsDropDown";
import {
  useGetEmployeeAssets,
  useUnassignAsset,
} from "../../../../queries/people";
import { useParams } from "react-router-dom";
import { Asset } from "../../../../types";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { setActiveEmployeeAssets } from "../../../../redux/slices/peopleSlice";
import { useQueryClient } from "@tanstack/react-query";
import { ProgressBar } from "../../../shared/ProgressBar";

const EmployeeAssets = () => {
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const { data: assignedAssets, error } = useGetEmployeeAssets(id);
  const { showSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const assets = useAppSelector((state) => state.people.activeEmployeeAssets);
  const { mutateAsync: unAssignAsset } = useUnassignAsset();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (assignedAssets) {
      dispatch(setActiveEmployeeAssets(assignedAssets.data.assets));
    }
  }, [assignedAssets]);

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch assigned assets",
      });
    }
  }, [error]);

  const onUnAssignAsset = async (asset_id: string) => {
    try {
      await unAssignAsset({ employeeId: id || "", asset_id });
      queryClient.invalidateQueries({
        queryKey: ["employeeAssets", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["AllAssets"],
      });

      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Asset unassigned successfully",
        description: "",
      });
    } catch (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to unassign asset",
      });
    }
  };
  const renderAssetRow = () => {
    return assets?.map((asset: Asset, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <AssetTitle size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {asset.name}
            </AssetTitle>
            <AssetType size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {asset.type}
            </AssetType>
          </TableCell>
          <TableCell>
            <AssetType size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {asset.brand}
            </AssetType>
          </TableCell>
          <TableCell>
            <Typography size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {asset.employeeStatus === "In-Use" ? (
                <Badge color={BadgeTypes.success} dot text="In-Use" />
              ) : (
                <Badge
                  color={BadgeTypes.blueLight}
                  dot
                  text={asset.employeeStatus}
                />
              )}
            </Typography>
          </TableCell>
          <TableCell>
            <ProgressBar condition={asset.condition} />
          </TableCell>
          <TableCell style={{ position: "relative" }}>
            <ActionsDropDown
              actions={[
                {
                  text: "Unassign",
                  onSelect: () => onUnAssignAsset(asset.id),
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderAssetsTable = () => {
    return (
      <AssetsContainer>
        <AssetsHeader>
          <AssetsTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Assets {`(${assets?.length})`}
          </AssetsTitle>
          <Button
            onClick={() => setOpenModal(true)}
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            text="Assign Assets"
          />
        </AssetsHeader>
        <Table>
          <TableHeader>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Asset
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Brand
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Condition
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableHeader>
          {renderAssetRow()}
        </Table>
      </AssetsContainer>
    );
  };

  return (
    <>
      {assets?.length === 0 || !assets ? (
        <PeopleEmptyState
          title="You don’t have assigned assets for this employee"
          btnText="Assign Asset"
          onBtnClick={() => setOpenModal(true)}
        />
      ) : (
        renderAssetsTable()
      )}
      <VerticalModal open={openModal} onClose={() => setOpenModal(false)}>
        <AddAssetsModal
          onClose={() => setOpenModal(false)}
          cancelBtn={false}
          btnText="close"
        />
      </VerticalModal>
    </>
  );
};

export default EmployeeAssets;
