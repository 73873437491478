import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import {
  GetContractsToOnboard,
  GetEmployeeAssetsResponse,
  GetEmployeeContractResponse,
  GetEmployeeResponse,
  GetEmployeesParams,
  GetEmployeesResponse,
  GetJobHierarchyResponse,
} from "../types/apisTypes";
import qs from "qs";
import {
  Benefit,
  Employee,
  EmployeeRequest,
  Payslip,
  PayslipOverView,
  RequestOverview,
  SalaryCalculations,
} from "../types";

export const useGetContractsToOnboard = () => {
  return useQuery<GetContractsToOnboard, Error>({
    queryFn: async () => {
      const response = await apiClient.get("/people/contracts-to-onboard");
      return response.data;
    },
    queryKey: ["contractsToOnboard"],
  });
};

export const useGetEmployees = (params: GetEmployeesParams) => {
  return useQuery<GetEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee?${qs.stringify(params)}`);
      return response.data;
    },
    retry: false,

    queryKey: ["employees", params],
  });
};

export const useGetActiveEmployees = () => {
  return useQuery<GetEmployeesResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee?status=active`);
      return response.data;
    },
    retry: false,
    queryKey: ["activeEmployees"],
  });
};
export const useDeleteEmployee = (id: string) => {
  return useMutation<void, Error, string>({
    mutationFn: async () => {
      const response = await apiClient.delete(`/employee/${id}`);
      return response.data;
    },
  });
};

export const useGetEmployee = (id: string | undefined) => {
  return useQuery<GetEmployeeResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee/${id}`);
      return response.data;
    },
    queryKey: ["employee", id],
    retry: false,
    enabled: !!id,
  });
};
export const useCreateEmployee = () => {
  return useMutation<Employee, Error, any>({
    mutationFn: async (data) => {
      const response = await apiClient.post("/employee", data);
      return response.data.data.employee;
    },
  });
};

export const useGetEmployeeContract = (id: string | undefined) => {
  return useQuery<GetEmployeeContractResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee/${id}/contract`);
      return response.data;
    },
    queryKey: ["employeeContract", id],
    retry: false,
    enabled: !!id,
  });
};

export const useCreateEmployeeContract = (id: string | undefined) => {
  return useMutation<void, Error, any>({
    mutationFn: async (data) => {
      const response = await apiClient.post(`/employee/${id}/contract`, data);
      return response.data;
    },
  });
};

export const useGetEmployeeAssets = (id: string | undefined) => {
  return useQuery<GetEmployeeAssetsResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/employee/${id}/asset`);
      return response.data;
    },
    queryKey: ["employeeAssets", id],
    retry: false,
    enabled: !!id,
  });
};

export const useGetAvailableAssets = () => {
  return useQuery<GetEmployeeAssetsResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/asset`);
      return response.data;
    },
    queryKey: ["AllAssets"],
    retry: false,
  });
};

export const useAssignAsset = () => {
  return useMutation<void, Error, { employeeId: string; asset_id: string }>({
    mutationFn: async ({ employeeId, asset_id }) => {
      const response = await apiClient.post(`/employee/${employeeId}/asset`, {
        asset_id,
      });
      return response.data;
    },
  });
};

export const useUnassignAsset = () => {
  return useMutation<void, Error, { employeeId: string; asset_id: string }>({
    mutationFn: async ({ employeeId, asset_id }) => {
      const response = await apiClient.delete(
        `/employee/${employeeId}/asset/${asset_id}`
      );
      return response.data;
    },
    retry: false,
  });
};

export const useGetAllREmployeeRequests = () => {
  return useQuery<RequestOverview[], Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/request`);
      return response.data.data.requests;
    },
    queryKey: ["AllRequests"],
    retry: false,
  });
};

export const useGetRequestDetails = (id: string | undefined) => {
  return useQuery<EmployeeRequest, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/request/${id}`);
      return response.data.data.request;
    },
    queryKey: ["Request", id],
    retry: false,
    enabled: !!id,
  });
};

export const useGetEmployeeBenefits = () => {
  return useQuery<Benefit[], Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/benefit`);
      return response.data.data.benefits;
    },
    queryKey: ["employeeBenefits"],
    retry: false,
  });
};

export const useUnenrollBenefitNow = () => {
  return useMutation<void, Error, { benefit_id: string }>({
    mutationFn: async ({ benefit_id }) => {
      const response = await apiClient.delete(`/benefit/${benefit_id}`);
      return response.data;
    },
  });
};

export const useUnenrollBenefitByDate = () => {
  return useMutation<
    void,
    Error,
    { benefit_id: string; unenroll_date: string }
  >({
    mutationFn: async ({ benefit_id, unenroll_date }) => {
      const response = await apiClient.post(`/benefit/${benefit_id}/unenroll`, {
        unenroll_date,
      });
      return response.data;
    },
  });
};

export const useGetEmployeePayslips = () => {
  return useQuery<PayslipOverView[], Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payslip`);
      return response.data.data.payslips;
    },
    queryKey: ["employeePayslips"],
    retry: false,
  });
};

export const useGetPayslipDetails = (id: string | undefined) => {
  return useQuery<Payslip, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/payslip/${id}`);
      return response.data.data.payslip;
    },
    queryKey: ["employeePayslip", id],
    retry: false,
    enabled: !!id,
  });
};

export const useRenewEmployeeContract = () => {
  return useMutation<void, Error, { employeeId: string }>({
    mutationFn: async ({ employeeId }) => {
      const response = await apiClient.post(
        `/employee/${employeeId}/contract/renew`
      );
      return response.data;
    },
  });
};

export const useGetSalaryCalculations = (salary_net: string) => {
  return useQuery<SalaryCalculations, Error>({
    queryFn: async () => {
      const response = await apiClient.post(`/utils/calculate-salary`, {
        salary_net,
      });
      return response.data.data;
    },
    queryKey: ["salaryCalculations"],
    retry: false,
  });
};

export const useGetJobHierarchy = () => {
  return useQuery<GetJobHierarchyResponse, Error>({
    queryFn: async () => {
      const response = await apiClient.get(`/job_hierarchy`);
      return response.data;
    },
    queryKey: ["jobHierarchy"],
    retry: false,
  });
};
