import { FontSizes, FontWeights } from "../../../../constants/styles";
import { Employee, PEOPLE_STATUS_BADGE_COLOR } from "../../../../types";
import { TableCell, TableRowDynamic } from "../../../shared/Table/styled";
import {
  BadgesContainer,
  Divider,
  EmployeeTableContainer,
  SortIcon,
  TableHeadWithIcon,
  TextBold,
  TextFaded,
  NameCell,
  ActionsCell,
  ActionButton,
  ActionCellLink,
} from "./styled";
import ArrowDown from "../../../../Images/icons/arrow-down.svg";
import Badge from "../../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../../types/componentsProps";
import InitialsProfilePic from "../../../shared/InitialsProfilePic";
import Delete from "../../../../Images/icons/trash.svg";
import Contract from "../../../../Images/icons/contract.svg";
import ContractMissing from "../../../../Images/icons/contractMising.svg";
import ContractExpiring from "../../../../Images/icons/contractExpiring.svg";
import TablePagination from "../../../shared/TablePagination";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import {
  setActiveEmployee,
  setEmployeesTableVariables,
} from "../../../../redux/slices/peopleSlice";
import { useState } from "react";
import DeleteEmployeeModal from "../DeleteEmployeeModal";
import { useDeleteEmployee } from "../../../../queries/people";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const EmployeeTable = () => {
  const {
    currentPage,
    sortDirection,
    filteredEmployees: data,
    totalPages,
  } = useAppSelector((state) => state.people.employees_table_variables);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [toBeDeletedID, setToBeDeletedID] = useState<Employee | null>(null);
  const { mutateAsync: deleteEmployee, error } = useDeleteEmployee(
    toBeDeletedID?.id || "0"
  );
  const { showSnackbar } = useSnackbar();

  const deleteEmployeeHandler = async () => {
    if (toBeDeletedID) {
      try {
        await deleteEmployee(toBeDeletedID.id);
        setToBeDeletedID(null);
        setOpenDeleteModal(false);
        queryClient.invalidateQueries({ queryKey: ["employees"] });
      } catch (error) {
        setToBeDeletedID(null);
        setOpenDeleteModal(false);
        showSnackbar({
          type: SnackbarTypes.ERROR,
          open: true,
          title: "Error deleting employee",
          description:
            "An error occurred while deleting the employee. Please try again later.",
        });
      }
    }
  };

  const renderTableRows = () => {
    return data?.map((employee, index) => {
      return (
        <>
          <TableRowDynamic height="40px">
            <TableCell>
              <NameCell
                to={`/people/profile/${employee.id}`}
                onMouseDown={() => {
                  dispatch(setActiveEmployee(employee));
                }}
              >
                <InitialsProfilePic
                  fullName={`${employee.user.first_name} ${employee.user.last_name}`}
                />
                <div>
                  <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                    {employee.user.first_name} {employee.user.last_name}
                  </TextBold>
                  <TextFaded
                    size={FontSizes.TX_SM}
                    weight={FontWeights.REGULAR}
                  >
                    {employee.user.email}
                  </TextFaded>
                </div>
              </NameCell>
            </TableCell>

            <TableCell>
              <BadgesContainer>
                <Badge
                  text={employee.employee_status}
                  color={PEOPLE_STATUS_BADGE_COLOR[employee.employee_status]}
                />
                {employee.contract_expiring ? (
                  <Badge
                    text="Contract expiring in 2 weeks"
                    color={BadgeTypes.warning}
                  />
                ) : null}
              </BadgesContainer>
            </TableCell>

            <TableCell>
              <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {employee.user_information.position}
              </TextFaded>
            </TableCell>

            <TableCell>
              <ActionsCell>
                {employee.employee_status === "Draft" ? (
                  <ActionCellLink
                    to={`/people/profile/${employee.id}/contract`}
                    onMouseDown={() => {
                      dispatch(setActiveEmployee(employee));
                    }}
                  >
                    <ActionButton
                      src={ContractMissing}
                      alt="Contract Missing"
                    />
                  </ActionCellLink>
                ) : employee.contract_expiring ? (
                  <ActionCellLink
                    to={`/people/profile/${employee.id}/contract`}
                    onMouseDown={() => {
                      dispatch(setActiveEmployee(employee));
                    }}
                  >
                    <ActionButton
                      src={ContractExpiring}
                      alt="Contract Expiring"
                    />
                  </ActionCellLink>
                ) : (
                  <ActionCellLink
                    to={`/people/profile/${employee.id}/contract`}
                    onMouseDown={() => {
                      dispatch(setActiveEmployee(employee));
                    }}
                  >
                    <ActionButton src={Contract} alt="Contract" />
                  </ActionCellLink>
                )}
                <ActionButton
                  src={Delete}
                  alt="Delete"
                  onClick={() => {
                    setToBeDeletedID(employee);
                    setOpenDeleteModal(true);
                  }}
                />
              </ActionsCell>
            </TableCell>
          </TableRowDynamic>
          {index !== data.length - 1 && <Divider />}
        </>
      );
    });
  };

  return (
    <EmployeeTableContainer>
      <TableRowDynamic height="20px">
        <TableCell>
          <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Name
          </TextFaded>
        </TableCell>

        <TableCell>
          <TableHeadWithIcon
            onClick={() => {
              dispatch(
                setEmployeesTableVariables({
                  sortKey: "employee_status",
                  sortDirection:
                    sortDirection === "ascending" ? "descending" : "ascending",
                })
              );
            }}
          >
            <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              Status
            </TextFaded>
            <SortIcon
              src={ArrowDown}
              alt="Arrow Down"
              width={16}
              height={16}
              direction={sortDirection}
            />
          </TableHeadWithIcon>
        </TableCell>
        <TableCell>
          {" "}
          <TextFaded size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
            Title
          </TextFaded>
        </TableCell>
        <TableCell></TableCell>
      </TableRowDynamic>
      <Divider />

      {renderTableRows()}
      {totalPages > 1 && (
        <TableRowDynamic height="68px">
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={(page: number) =>
              dispatch(setEmployeesTableVariables({ currentPage: page }))
            }
            totalPages={totalPages}
          />
        </TableRowDynamic>
      )}
      <DeleteEmployeeModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => {
          deleteEmployeeHandler();
        }}
        name={`${toBeDeletedID?.user.first_name} ${toBeDeletedID?.user.last_name}`}
      />
    </EmployeeTableContainer>
  );
};

export default EmployeeTable;
