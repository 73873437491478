import React from "react";
import { Container } from "./styled";
import SidePanel from "../../components/register/SidePanel";
import AccountType from "../../components/register/registrationsSteps/AccountType";
import { useParams } from "react-router-dom";
import { RegistrationSteps } from "../../constants/registration";
import OrganizationRegistration from "../../components/register/registrationsSteps/UserRegistration";
import EmailConfirmation from "../../components/register/registrationsSteps/EmailConfirmation";
import OrganizationDetails from "../../components/register/registrationsSteps/OrganizationDetails";
import Solutions from "../../components/register/registrationsSteps/Solutions";

const RegisterScreen: React.FC = () => {
  const { step } = useParams();

  const renderStep = () => {
    switch (step) {
      case RegistrationSteps.account_type:
        return <AccountType />;

      case RegistrationSteps.user_registration:
        return <OrganizationRegistration />;

      case RegistrationSteps.email_confirmation:
        return <EmailConfirmation />;
      case RegistrationSteps.organization_details:
        return <OrganizationDetails />;

      // case RegistrationSteps.solutions:
      //   return <Solutions />;
      // case RegistrationSteps.account_setup:
      //   return <AccountSetUp />;
    }
  };

  return (
    <Container>
      <SidePanel step={step as RegistrationSteps} />
      {renderStep()}
    </Container>
  );
};

export default RegisterScreen;
