import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import SearchInput from "../../shared/SearchInput";
import {
  Container,
  NameCellContainer,
  NameCellImg,
  PayrollTableContainer,
  SearchContainer,
  SortIcon,
  TableContainer,
  TableHeadWithIcon,
  TableHeaderContainer,
  TableHeaderTitle,
  TextFaded,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Badge from "../../shared/Badge";
import { BadgeTypes } from "../../../types/componentsProps";
import {
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
} from "../../shared/Table/styled";
import ArrowDown from "../../../Images/icons/arrow-down.svg";
import { TextBold } from "../../people/Manage/EmployeeTable/styled";
import moment from "moment";
import Button from "../../shared/Button";
import { useNavigate } from "react-router-dom";
import { updatePayrollListVariables } from "../../../redux/slices/payrollSlice";
import TablePagination from "../../shared/TablePagination";
import { CompanyPayroll } from "../../../types";

const PayrollTable = () => {
  const dispatch = useAppDispatch();
  const {
    sortDirection,
    search,
    currentPage,
    filteredPayrolls,
    totalRecords,
    totalPages,
  } = useAppSelector((state) => state.payroll.payrollListVariables);

  const navigate = useNavigate();
  const renderRows = () => {
    return filteredPayrolls.map((payroll) => {
      return (
        <TableRow key={payroll.id}>
          <TableCell>
            <NameCellContainer>
              <NameCellImg src={payroll.company.logo.url} alt="Company Logo" />
              <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {payroll.company.name}
              </TextBold>
            </NameCellContainer>
          </TableCell>
          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {moment(payroll.cycle_start_date).format("DD, MMM YYYY")} -
              {moment(payroll.cycle_end_date).format("DD, MMM YYYY")}
            </TextBold>
          </TableCell>

          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {moment(payroll.payday).format("DD, MMM YYYY")}
            </TextBold>
          </TableCell>
          <TableCell>
            <Badge text={payroll.payroll_status} color={BadgeTypes.warning} />
          </TableCell>
          <TableCell>
            <Button
              text="View payroll"
              variant={ButtonVariants.primary}
              size={ButtonSizes.sm}
              onClick={() => {
                navigate(`/payroll/payroll-review/${payroll.id}`);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTable = () => {
    return (
      <PayrollTableContainer>
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon
              onClick={() => {
                dispatch(
                  updatePayrollListVariables({
                    sortDirection:
                      sortDirection === "ascending"
                        ? "descending"
                        : "ascending",
                    sortKey: "company.name" as keyof CompanyPayroll,
                  })
                );
              }}
            >
              <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Entity
              </TextFaded>
              <SortIcon
                src={ArrowDown}
                alt="Arrow Down"
                width={16}
                height={16}
                direction={sortDirection}
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Cycle
            </TextFaded>
          </TableCell>
          <TableCell>
            <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Due Date
            </TextFaded>
          </TableCell>
          <TableCell>
            <TextFaded size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Status
            </TextFaded>
          </TableCell>
          <TableCell></TableCell>
        </TableHeader>
        {renderRows()}
        {totalPages > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(updatePayrollListVariables({ currentPage: page }))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </PayrollTableContainer>
    );
  };
  return (
    <Container>
      <SearchContainer>
        <SearchInput
          placeholder="Search"
          containerStyle={{ boxSizing: "border-box" }}
          value={search}
          onChange={(e) => {
            dispatch(updatePayrollListVariables({ search: e.target.value }));
          }}
        />
      </SearchContainer>
      <TableContainer>
        <TableHeaderContainer>
          <TableHeaderTitle
            size={FontSizes.TX_LG}
            weight={FontWeights.SEMIBOLD}
          >
            Payroll Submission
          </TableHeaderTitle>
          <Badge text={totalRecords.toString()} color={BadgeTypes.gray} />
        </TableHeaderContainer>
        {renderTable()}
      </TableContainer>
    </Container>
  );
};

export default PayrollTable;
