import { useEffect, useState } from "react";
import PayrollEmptyState from "../../components/payroll/PayrollEmptyState";
import PayrollTable from "../../components/payroll/PayrollTable";
import { FontSizes, FontWeights } from "../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import {
  PayrollContainer,
  PayrollHeader,
  PayrollSubtitle,
  PayrollTitle,
} from "./styled";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetPayrolls } from "../../queries/payroll";
import { updatePayrollListVariables } from "../../redux/slices/payrollSlice";
import { useSnackbar } from "../../hooks/useSnackbar";
import { AxiosError } from "axios";
import { SnackbarTypes } from "../../types/componentsProps";

const PayrollScreen = () => {
  const {
    sortDirection,
    search,
    currentPage,
    itemsPerPage,
    sortKey,
    filteredPayrolls,
  } = useAppSelector((state) => state.payroll.payrollListVariables);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data: payrollData, error } = useGetPayrolls({
    sort: {
      sortDirection: sortDirection,
      sortKey: sortKey,
    },
    search,
    page: currentPage,
    page_size: itemsPerPage,
  });
  const [isOutlet, setIsOutlet] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (payrollData?.data) {
      dispatch(
        updatePayrollListVariables({
          filteredPayrolls: payrollData.data.payrolls,
          currentPage: payrollData.data._metadata.page,
          totalRecords: payrollData.data._metadata.total_records,
          totalPages: payrollData.data._metadata.last_page,
        })
      );
    }
  }, [payrollData]);

  useEffect(() => {
    if (error) {
      dispatch(updatePayrollListVariables({ filteredPayrolls: [] }));
      const err = error as unknown as AxiosError;
      switch (err.response?.status) {
        case 401:
          navigate("/login");
          break;
        case 403:
          navigate("/404");
          break;
        default:
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while fetching payrolls.",
            description: "Please try again later.",
          });
          break;
      }
    }
  }, [error]);
  useEffect(() => {
    if (
      location.pathname.includes("payroll-review") ||
      location.pathname.includes("payroll-details") ||
      location.pathname.includes("process")
    ) {
      setIsOutlet(true);
    } else {
      setIsOutlet(false);
    }
  }, [location.pathname]);

  return (
    <PayrollContainer>
      {isOutlet ? (
        <Outlet />
      ) : (
        <>
          {" "}
          <PayrollHeader>
            <PayrollTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
              Payroll
            </PayrollTitle>
            <PayrollSubtitle
              size={FontSizes.TX_MD}
              weight={FontWeights.REGULAR}
            >
              Manage your payroll here
            </PayrollSubtitle>
          </PayrollHeader>
          {filteredPayrolls.length > 0 ? (
            <PayrollTable />
          ) : (
            <PayrollEmptyState />
          )}{" "}
        </>
      )}
    </PayrollContainer>
  );
};

export default PayrollScreen;
