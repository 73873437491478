import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from "../store";
import { USER } from "../../types";
import { storeDashboardBanners } from "./appState";
import { DashboardBanners } from "../../constants/banners";

export interface AuthState {
  token: string | null;
  user: USER | null;
  loggedIn: boolean;
}

const initialState: AuthState = {
  token: null,
  user: null,
  loggedIn: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<USER | null>) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
  },
});

export const storeToken = (token: string) => (dispatch: AppDispatch) => {
  localStorage.setItem("token", token);
  dispatch(setToken(token));
};

export const removeToken = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("token");
  dispatch(setToken(null));
};

export const retrieveToken = () => (dispatch: AppDispatch) => {
  const token = localStorage.getItem("token");
  dispatch(setToken(token));
};

export const storeUser =
  (user: USER, token: string | null) => (dispatch: AppDispatch) => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatch(setUser(user));
    dispatch(storeToken(token || ""));
  };

  export const storeUserData = (user: USER) => (dispatch: AppDispatch) => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatch(setUser(user));
  }
export const removeUser = () => (dispatch: AppDispatch) => {
  localStorage.removeItem("user");
  dispatch(setUser(null));
  dispatch(removeToken());
  dispatch(storeLoggedIn(false));
  dispatch(storeDashboardBanners(DashboardBanners));
};

export const storeLoggedIn = (loggedIn: boolean) => (dispatch: AppDispatch) => {
  localStorage.setItem("loggedIn", JSON.stringify(loggedIn));
  dispatch(setLoggedIn(loggedIn));
};

export const retrieveLoggedIn = () => (dispatch: AppDispatch) => {
  const flag = localStorage.getItem("loggedIn");
  dispatch(setLoggedIn(JSON.parse(flag || "false")));
};

export const retrieveUser = () => (dispatch: AppDispatch) => {
  const token = localStorage.getItem("token");
  dispatch(setToken(token));
  if (!token) {
    return;
  }

  const user = localStorage.getItem("user");
  dispatch(setUser(JSON.parse(user || "{}")));
};

export const { setToken, setUser, setLoggedIn } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;

export default authSlice.reducer;
