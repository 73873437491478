import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddAssetSteps, RequestAssetSteps } from "../../constants/assetsHub";
import {
  ALL_ASSETS_STATUS_OPTIONS,
  ASSETS_CATEGORIES_OPTIONS,
} from "../../mockServer/mockData";
import { DropdownListOption } from "../../types/componentsProps";
import { UserAsset } from "../../types";
import { sortDirection } from "../../types/apisTypes";

interface AssetsHubState {
  requestAssetActiveStep: number;
  requestAssetsSteps: typeof RequestAssetSteps;
  addAssetActiveStep: number;
  addAssetSteps: typeof AddAssetSteps;
  requestedAssets: {
    assets: {
      category: DropdownListOption;
      brand: string;
      model: string;
      specifications: string;
    }[];

    location: string;
    assignee: DropdownListOption;
  };
  toBeAddedAsset: {
    info: {
      name: string;
      id: string;
      serialNumber: string;
      category: DropdownListOption;
      brand: string;
      model: string;
      specifications: string;
      location: string;
      assignee: DropdownListOption;
      installationDate: string;
      warrantyPeriod: string;
      supplier: string;
      supplierContact: string;
      maintenancePeriod: string;
      lastMaintenance: string;
      maintenanceProvider: string;
      disposalDate: string;
      disposalMethod: string;
      disposalReason: string;
    };
    financialInfo: {
      purchaseDate: string;
      purchasePrice: string;
      deprecationMethod: string;
      currentValue: string;
    };
    deliveryInfo: {
      equipmentStatus: string;
      shipmentStatus: string;
      courierInfo: {
        trackingNumber: string;
        courierName: string;
        link: string;
        sentDate: string;
        notes: string;
      };
      locationInfo: {
        country: string;
        city: string;
        zipCode: string;
        street: string;
        notes: string;
      };
    };
  };
  assetsListVariables: {
    search: string;
    selectedStatus: DropdownListOption;
    currentPage: number;
    totalRecords: number;
    filteredAssets: UserAsset[] | null;
    itemsPerPage: number;
    totalPages: number;
  };
  recentlyAddedListVariables: {
    currentPage: number;
    totalRecords: number;
    itemsPerPage: number;
    totalPages: number;
    sortKey: keyof UserAsset;
    sortDirection: sortDirection;
  };
}

const initialState: AssetsHubState = {
  requestAssetActiveStep: 0,
  requestAssetsSteps: RequestAssetSteps,
  addAssetActiveStep: 0,
  addAssetSteps: AddAssetSteps,
  requestedAssets: {
    assets: [
      {
        category: ASSETS_CATEGORIES_OPTIONS[0],
        brand: "",
        model: "",
        specifications: "",
      },
    ],
    location: "",
    assignee: {
      label: "",
      value: "",
    },
  },
  toBeAddedAsset: {
    info: {
      name: "",
      id: "",
      serialNumber: "",
      category: ASSETS_CATEGORIES_OPTIONS[0],
      brand: "",
      model: "",
      specifications: "",
      location: "",
      assignee: {
        label: "",
        value: "",
      },
      installationDate: "",
      warrantyPeriod: "",
      supplier: "",
      supplierContact: "",
      maintenancePeriod: "",
      lastMaintenance: "",
      maintenanceProvider: "",
      disposalDate: "",
      disposalMethod: "",
      disposalReason: "",
    },
    financialInfo: {
      purchaseDate: "",
      purchasePrice: "",
      deprecationMethod: "",
      currentValue: "",
    },
    deliveryInfo: {
      equipmentStatus: "2",
      shipmentStatus: "1",
      courierInfo: {
        trackingNumber: "",
        courierName: "",
        link: "",
        sentDate: "",
        notes: "",
      },
      locationInfo: {
        country: "",
        city: "",
        zipCode: "",
        street: "",
        notes: "",
      },
    },
  },
  assetsListVariables: {
    currentPage: 1,
    itemsPerPage: 7,
    totalRecords: 0,
    totalPages: 0,
    selectedStatus: ALL_ASSETS_STATUS_OPTIONS[0],
    search: "",
    filteredAssets: null,
  },
  recentlyAddedListVariables: {
    currentPage: 1,
    itemsPerPage: 6,
    totalRecords: 0,
    totalPages: 0,
    sortKey: "id",
    sortDirection: "ascending",
  },
};

const assetsHubSlice = createSlice({
  name: "assetsHub",
  initialState,
  reducers: {
    setRequestAssetActiveStep(state, action: PayloadAction<number>) {
      state.requestAssetActiveStep = action.payload;
    },
    setRequestAssetsSteps(
      state,
      action: PayloadAction<typeof RequestAssetSteps>
    ) {
      state.requestAssetsSteps = action.payload;
    },
    addToRequestedAssets(
      state,
      action: PayloadAction<{
        category: DropdownListOption;
        brand: string;
        model: string;
        specifications: string;
      }>
    ) {
      state.requestedAssets.assets.push(action.payload);
    },

    editRequestedAsset(
      state,
      action: PayloadAction<{
        index: number;
        asset: Partial<{
          category: DropdownListOption;
          brand: string;
          model: string;
          specifications: string;
        }>;
      }>
    ) {
      state.requestedAssets.assets[action.payload.index] = {
        ...state.requestedAssets.assets[action.payload.index],
        ...action.payload.asset,
      };
    },
    editRequestedAssetLocation(state, action: PayloadAction<string>) {
      state.requestedAssets.location = action.payload;
    },
    editRequestedAssetAssignee(
      state,
      action: PayloadAction<DropdownListOption>
    ) {
      state.requestedAssets.assignee = action.payload;
    },
    addRequestedAsset(state) {
      state.requestedAssets.assets.push({
        category: ASSETS_CATEGORIES_OPTIONS[0],
        brand: "",
        model: "",
        specifications: "",
      });
    },
    deleteRequestedAsset(state, action: PayloadAction<number>) {
      state.requestedAssets.assets.splice(action.payload, 1);
    },
    setAddAssetActiveStep(state, action: PayloadAction<number>) {
      state.addAssetActiveStep = action.payload;
    },
    setAddAssetSteps(state, action: PayloadAction<typeof AddAssetSteps>) {
      state.addAssetSteps = action.payload;
    },
    editToBeAddedAsset(
      state,
      action: PayloadAction<Partial<AssetsHubState["toBeAddedAsset"]>>
    ) {
      state.toBeAddedAsset = { ...state.toBeAddedAsset, ...action.payload };
    },
    editAssetsListVariables(
      state,
      action: PayloadAction<Partial<AssetsHubState["assetsListVariables"]>>
    ) {
      state.assetsListVariables = {
        ...state.assetsListVariables,
        ...action.payload,
      };
    },
    editRecentlyAddedListVariables(
      state,
      action: PayloadAction<
        Partial<AssetsHubState["recentlyAddedListVariables"]>
      >
    ) {
      state.recentlyAddedListVariables = {
        ...state.recentlyAddedListVariables,
        ...action.payload,
      };
    },
    resetToBeAddedAsset(state) {
      state.toBeAddedAsset = initialState.toBeAddedAsset;
      state.addAssetActiveStep = initialState.addAssetActiveStep;
      state.addAssetSteps = initialState.addAssetSteps;
    },
  },
});

export const onNextRequestAssetStep = () => (dispatch: any, getState: any) => {
  const { assetsHub } = getState();
  const { requestAssetsSteps, requestAssetActiveStep } = assetsHub;
  const newPSteps = requestAssetsSteps.map((step: any, index: number) => {
    if (index === requestAssetActiveStep) {
      return { ...step, completed: true };
    }
    return step;
  });
  dispatch(setRequestAssetsSteps(newPSteps));
  dispatch(setRequestAssetActiveStep(requestAssetActiveStep + 1));
};

export const onPreviousRequestAssetStep =
  () => (dispatch: any, getState: any) => {
    const { assetsHub } = getState();
    const { requestAssetsSteps, requestAssetActiveStep } = assetsHub;
    const newPSteps = requestAssetsSteps.map((step: any, index: number) => {
      if (index === requestAssetActiveStep) {
        return { ...step, completed: false };
      }
      return step;
    });
    dispatch(setRequestAssetsSteps(newPSteps));
    dispatch(setRequestAssetActiveStep(requestAssetActiveStep - 1));
  };

export const onNextAddAssetStep = () => (dispatch: any, getState: any) => {
  const { assetsHub } = getState();
  const { addAssetSteps, addAssetActiveStep } = assetsHub;
  const newPSteps = addAssetSteps.map((step: any, index: number) => {
    if (index === addAssetActiveStep) {
      return { ...step, completed: true };
    }
    return step;
  });
  dispatch(setAddAssetSteps(newPSteps));
  dispatch(setAddAssetActiveStep(addAssetActiveStep + 1));
};

export const onPreviousAddAssetStep = () => (dispatch: any, getState: any) => {
  const { assetsHub } = getState();
  const { addAssetSteps, addAssetActiveStep } = assetsHub;
  const newPSteps = addAssetSteps.map((step: any, index: number) => {
    if (index === addAssetActiveStep) {
      return { ...step, completed: false };
    }
    return step;
  });
  dispatch(setAddAssetSteps(newPSteps));
  dispatch(setAddAssetActiveStep(addAssetActiveStep - 1));
};

export const {
  setRequestAssetActiveStep,
  setRequestAssetsSteps,
  addToRequestedAssets,
  editRequestedAsset,
  editRequestedAssetLocation,
  editRequestedAssetAssignee,
  addRequestedAsset,
  deleteRequestedAsset,
  setAddAssetActiveStep,
  setAddAssetSteps,
  editToBeAddedAsset,
  editAssetsListVariables,
  editRecentlyAddedListVariables,
  resetToBeAddedAsset,
} = assetsHubSlice.actions;
export default assetsHubSlice.reducer;
