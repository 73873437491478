import React, { useEffect } from "react";
import {
  DashboardContainer,
  DashboardHeader,
  PageSubtitle,
  PageTitle,
  UserName,
  DashboardContentContainer,
  CardsContainer,
  Grid,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import {
  selectUser,
  storeUser,
  storeUserData,
} from "../../redux/slices/authSlice";
import { FontSizes, FontWeights } from "../../constants/styles";
import MultipleActionCard from "../../components/dashboard/MultipleActionCard";
import PaymentSection from "../../components/dashboard/PaymentSection";
import ContactUsBanner from "../../components/dashboard/ContactUsBanner";
import Companies from "../../components/dashboard/Companies";
import BannersCarousel from "../../components/dashboard/BannersCarousel";
import UpcomingPayrollCard from "../../components/dashboard/UpcomingPayrollCard";
import PieChartCard from "../../components/dashboard/PieChartCard";
import {
  AssetsCard,
  AssetsCardColors,
  PeopleCard,
  PeopleCardColors,
} from "../../mockServer/mockData";
import { useGetUserData } from "../../queries/auth";

const DashboardScreen: React.FC = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const { data: userData } = useGetUserData();

  useEffect(() => {
    if (userData) {
      dispatch(storeUserData(userData));
    }
  }, [userData]);

  return (
    <DashboardContainer>
      <DashboardHeader>
        <PageTitle size={FontSizes.SM} weight={FontWeights.BOLD}>
          Dashboard
        </PageTitle>
        <PageSubtitle size={FontSizes.TX_MD} weight={FontWeights.REGULAR}>
          Welcome back, <UserName>{user?.first_name}</UserName> !!
        </PageSubtitle>
      </DashboardHeader>
      <DashboardContentContainer>
        <CardsContainer>
          <PieChartCard
            title="People"
            data={PeopleCard}
            colors={PeopleCardColors}
          />

          <PieChartCard
            title="Assets"
            data={AssetsCard}
            colors={AssetsCardColors}
          />
          <UpcomingPayrollCard />
        </CardsContainer>
        <Grid hasBanners={true}>
          <MultipleActionCard
            title="Upcoming actions"
            actions={[
              {
                title: "No upcoming actions",
              },
            ]}
            style={{ gridArea: "upcoming" }}
          />
          <MultipleActionCard
            title="Onboarding & Off-boarding"
            actions={[
              {
                title: "No onboarding employees",
              },
              {
                title: "No off-boarding employees",
              },
            ]}
            style={{ gridArea: "onboarding" }}
          />
          <BannersCarousel />

          <PaymentSection payments={[]} style={{ gridArea: "payments" }} />
          <Companies />
          <ContactUsBanner />
        </Grid>
      </DashboardContentContainer>{" "}
    </DashboardContainer>
  );
};

export default DashboardScreen;
