import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import { OVARC_BANK_ACCOUNT } from "../../../../mockServer/mockData";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxHooks";
import { onPayInvoicesPreviousStep } from "../../../../redux/slices/paymentSlice";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import Button from "../../../shared/Button";
import {
  CardHeader,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import {
  ConfirmationStepContainer,
  StepTitle,
  StepTitleCard,
  ConfirmationCard,
  ConfirmationCardRow,
  ButtonsContainer,
} from "./styled";
import ArrowBack from "../../../../Images/icons/arrow-left.svg";
import { useState } from "react";
import TransactionNumberModal from "../../TransactionNumberModal";

const Confirmation = () => {
  const { pay_invoices_steps, current_pay_invoices_step, totalPayment } =
    useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  return (
    <ConfirmationStepContainer>
      <StepTitleCard>
        <StepTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Step {current_pay_invoices_step + 1}:{" "}
          {pay_invoices_steps[current_pay_invoices_step].title}
        </StepTitle>
      </StepTitleCard>

      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payment Details
          </CardTitle>
        </CardHeader>
        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Amount Due
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {currencyFormatter(totalPayment, "USD")}
          </CardRowValue>
        </ConfirmationCardRow>
      </ConfirmationCard>

      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Manual transfer details
          </CardTitle>
        </CardHeader>
        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Method
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Bank Transfer{" "}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Account Location
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Egypt
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Currency
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            USD
          </CardRowValue>
        </ConfirmationCardRow>
      </ConfirmationCard>

      <ConfirmationCard>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Ovarc bank account details
          </CardTitle>
        </CardHeader>
        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Currency
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.currency}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Beneficiary
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.beneficiary}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Account Number
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.account_number}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Swift Code
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.swift_code}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            IBAN
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.iban}
          </CardRowValue>
        </ConfirmationCardRow>

        <ConfirmationCardRow>
          <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            Address
          </CardRowTitle>
          <CardRowValue size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            {OVARC_BANK_ACCOUNT.address}
          </CardRowValue>
        </ConfirmationCardRow>
      </ConfirmationCard>

      <ButtonsContainer>
        <Button
          text="Back"
          variant={ButtonVariants.secondary_gray}
          size={ButtonSizes.lg}
          iconLeading={<img src={ArrowBack} alt="arrow-back" />}
          onClick={() => {
            dispatch(onPayInvoicesPreviousStep());
          }}
        />
        <Button
          text="Pay"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          style={{ width: "40%" }}
          onClick={() => {
            setOpenModal(true);
          }}
        />
      </ButtonsContainer>
      <TransactionNumberModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </ConfirmationStepContainer>
  );
};

export default Confirmation;
