import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Badge from "../../shared/Badge";
import { BadgeTypes } from "../../../types/componentsProps";
import Placeholder from "../../../Images/placeholderPP.svg";
import {
  ProfileContainer,
  ProfileHeader,
  ProfileHeaderInfo,
  ProfilePP,
  ProfileName,
  ProfilePersonalInfo,
  BadgesContainer,
} from "./styled";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { useEffect, useState } from "react";
import Tabs from "../../shared/Tabs";
import { useGetEmployee } from "../../../queries/people";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { setActiveEmployee } from "../../../redux/slices/peopleSlice";
import { PEOPLE_STATUS_BADGE_COLOR } from "../../../types";

const Profile = () => {
  const tabs = [
    {
      label: "Overview",
      path: "overview",
    },
    {
      label: "Contract",
      path: "contract",
    },
    {
      label: "Assets",
      path: "assets",
    },
    {
      label: "Payslips",
      path: "payslips",
    },
    {
      label: "Requests",
      path: "requests",
    },
    {
      label: "Benefits",
      path: "benefits",
    },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [isOutlet, setIsOutlet] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const { data: employee, error } = useGetEmployee(id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  window.onpopstate = () => {
    if (activeTab === 0) navigate("/people");
  };
  useEffect(() => {
    if (employee) {
      dispatch(setActiveEmployee(employee.data.employee));
    }
  }, [employee]);

  useEffect(() => {
    if (error) {
      navigate("/404");
    }
  }, [error]);

  useEffect(() => {
    const lastPath = location.pathname.split("/").pop();
    if (
      lastPath === "employee-contract" ||
      location.pathname.includes("request-details") ||
      location.pathname.includes("payslip-details")
    ) {
      setIsOutlet(true);
    } else {
      setIsOutlet(false);
      const selectedTab = tabs.findIndex((tab) => lastPath === tab.path) || 0;
      if (selectedTab !== -1) {
        setActiveTab(selectedTab);
      } else {
        navigate(`/people/profile/${id}/overview`);
      }
    }
  }, [location.pathname]);

  return isOutlet ? (
    <Outlet />
  ) : !employee ? null : (
    <ProfileContainer>
      <ProfileHeader>
        <ProfilePersonalInfo>
          <ProfilePP>
            <img
              src={Placeholder}
              alt="Profile Picture"
              width={30}
              height={30}
            />
          </ProfilePP>
          <ProfileHeaderInfo>
            <ProfileName size={FontSizes.XS} weight={FontWeights.SEMIBOLD}>
              {employee?.data.employee.user.first_name +
                " " +
                employee?.data.employee.user.last_name}
            </ProfileName>
            <BadgesContainer>
              <Badge
                text={employee?.data.employee.employee_status || ""}
                color={
                  PEOPLE_STATUS_BADGE_COLOR[
                    employee?.data.employee.employee_status || ""
                  ]
                }
                dot={true}
              />

              {employee?.data.employee.contract_expiring ? (
                <Badge
                  text="Contract expiring in 2 weeks"
                  color={BadgeTypes.warning}
                />
              ) : null}
            </BadgesContainer>
          </ProfileHeaderInfo>
        </ProfilePersonalInfo>
        <Tabs
          activeTab={activeTab}
          setActiveTab={(index) => {
            navigate(`/people/profile/${id}/${tabs[index].path}`);
          }}
          tabsTitles={tabs.map((tab) => tab.label)}
        />
      </ProfileHeader>
      <Outlet />
    </ProfileContainer>
  );
};

export default Profile;
