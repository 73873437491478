import { ReactNode } from "react";
import {
  ASSETS,
  ASSETS_DETAILS,
  ASSETS_HUB_LIST,
  ASSETS_OVERVIEW,
  CATEGORIZED_INVOICES,
  COMPANIES_PAYROLLS,
  CONTRACT,
  EMPLOYEE_BENEFITS,
  EMPLOYEE_PAYSLIPS_OVERVIEW,
  EMPLOYEE_REQUESTS_Details,
  EMPLOYEE_REQUESTS_LIST,
  INVOICES_LIST,
  PAYMENTS,
  PAYMENTS_LIST,
  PAYROLLS_REVIEWS,
  PAYROLLS_REVIEWS_EMPLOYEES,
  PAYROLL_DETAILS,
  PAYSLIPS,
  USERS,
  USER_PAYMENT_PROCESS,
} from "../mockServer/mockData";
import { BadgeTypes } from "./componentsProps";

export type USER = (typeof USERS)[0];
export type Payment = (typeof PAYMENTS)[0];
export type Contract = typeof CONTRACT;
export type AssetStatus = "Pending Contract" | "In-Active" | "Pending";
export type SideBarEntry = {
  text: string;
  icon: string;
  path: string;
  extraPaths?: string[];

  children?: {
    text: string;
    path: string;
    extraPaths?: string[];
  }[];
};

export enum PAYMENTS_STATUS {
  WaitingForPaymentToArrive = "Waiting for payment to arrive",
  Processing = "Processing",
}

export interface DashboardBanner {
  id: string;
  title: string;
  description: string;
  btnText: string;
  onClick: () => void;
  color: string;
  image: ReactNode;
}

export enum PEOPLE_STATUS {
  draft = "Draft",
  pending_invite = "Pending Invite",
  invited = "Invited",
  onboarding = "Onboarding",
  onboarding_at_risk = "Onboarding at risk",
  active = "Active",
  onboarding_overdue = "Onboarding overdue",
  ready_to_start = "Ready to start",
  inactive = "Inactive",
}

export const PEOPLE_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [PEOPLE_STATUS.draft]: BadgeTypes.gray,
  [PEOPLE_STATUS.pending_invite]: BadgeTypes.error,
  [PEOPLE_STATUS.invited]: BadgeTypes.blueLight,
  [PEOPLE_STATUS.onboarding]: BadgeTypes.blue,
  [PEOPLE_STATUS.onboarding_at_risk]: BadgeTypes.error,
  [PEOPLE_STATUS.active]: BadgeTypes.success,
  [PEOPLE_STATUS.onboarding_overdue]: BadgeTypes.error,
  [PEOPLE_STATUS.ready_to_start]: BadgeTypes.success,
  [PEOPLE_STATUS.inactive]: BadgeTypes.gray,
};

export enum PAYMENT_STATUS {
  initiated = "Initiated",
  pending = "Pending",
  received = "Received",
  overdue = "Over due",
  processing = "Processing",
  awaiting_funds = "Awaiting Funds",
}

export const PAYMENT_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [PAYMENT_STATUS.initiated]: BadgeTypes.gray,
  [PAYMENT_STATUS.pending]: BadgeTypes.blue,
  [PAYMENT_STATUS.received]: BadgeTypes.success,
  [PAYMENT_STATUS.overdue]: BadgeTypes.warning,
  [PAYMENT_STATUS.processing]: BadgeTypes.indigo,
  [PAYMENT_STATUS.awaiting_funds]: BadgeTypes.orange,
};
export enum ASSETS_HUB_ASSETS_LIST_STATUS {
  inuse = "In-Use",
  inactive = "In-Active",
  pendingDelivery = "Pending Delivery",
  underMaintenance = "Under Maintenance",
  decommissioned = "Decommissioned",
  lost = "Lost",
}

export const ASSETS_LIST_STATUS_BADGE_COLOR: Record<string, BadgeTypes> = {
  [ASSETS_HUB_ASSETS_LIST_STATUS.inuse]: BadgeTypes.success,
  [ASSETS_HUB_ASSETS_LIST_STATUS.inactive]: BadgeTypes.gray,
  [ASSETS_HUB_ASSETS_LIST_STATUS.pendingDelivery]: BadgeTypes.blueLight,
  [ASSETS_HUB_ASSETS_LIST_STATUS.underMaintenance]: BadgeTypes.warning,
  [ASSETS_HUB_ASSETS_LIST_STATUS.decommissioned]: BadgeTypes.error,
  [ASSETS_HUB_ASSETS_LIST_STATUS.lost]: BadgeTypes.error,
};

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface UserInformation {
  id: number;
  position: string;
  country_of_citizenship: string;
}

export interface Employee {
  id: string;
  employee_status: string;
  joining_date: string;
  user: User;
  user_information: UserInformation;
  contract_expiring?: boolean;
  company: {
    id: number;
    name: string;
  };
}

export type Asset = (typeof ASSETS)[0];

export type RequestOverview = (typeof EMPLOYEE_REQUESTS_LIST)[0];
export type EmployeeRequest = (typeof EMPLOYEE_REQUESTS_Details)[0];
export type Benefit = (typeof EMPLOYEE_BENEFITS)[0];
export type PayslipOverView = (typeof EMPLOYEE_PAYSLIPS_OVERVIEW)[0];
export type Payslip = (typeof PAYSLIPS)[0];
export type CompanyPayroll = (typeof COMPANIES_PAYROLLS)[0];
export type PayrollReview = (typeof PAYROLLS_REVIEWS)[0];
export type PayrollReviewEmployee = (typeof PAYROLLS_REVIEWS_EMPLOYEES)[0];
export type PayrollDetails = (typeof PAYROLL_DETAILS)[0];
export type UserPaymentProcessReview = typeof USER_PAYMENT_PROCESS;
export type Invoice = (typeof INVOICES_LIST)[0];
export type CategorizedInvoices = typeof CATEGORIZED_INVOICES;
export type UserPayment = (typeof PAYMENTS_LIST)[0];
export type UserAsset = (typeof ASSETS_HUB_LIST)[0];
export type UserAssetsOverview = typeof ASSETS_OVERVIEW;
export type UserAssetDetails = (typeof ASSETS_DETAILS)[0];
export type Country = {
  id: number;
  name: string;
  flag?: string;
};
export type Company = {
  id: string;
  name: string;
  industry: string;
  phone: string;
  legal_company_name: string;
  tax_id: string;
  registration_number: string;
  plan: {
    type: string;
    country: string;
  };
  logo: {
    id: string;
    url: string;
  };
};

export type SalaryCalculations = {
  salary_total: number;
  salary_gross: number;
  currency: string;
  eor_fees: {
    total: number;
    amount: number;
    period: string;
  };
};
