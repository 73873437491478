import { PieChart, Pie, Cell } from "recharts";
import { FontSizes, FontWeights } from "../../../constants/styles";
import {
  ASSETS_GROUPS_COLORS,
  ASSETS_Statuses_COLORS,
} from "../../../mockServer/mockData";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import {
  AssetsOverViewCard,
  AssetsOverViewCardTitle,
  AssetsOverViewCardValue,
  AssetsOverViewCardsContainer,
  AssetsOverViewContainer,
  PeiChartKey,
  PeiChartKeyColor,
  PeiChartKeysContainer,
  PieChartContainer,
} from "./styled";
import RecentlyAddedList from "../RecentlyAddedList";
import { useGetAssetsOverview } from "../../../queries/assetsHub";
import { useEffect } from "react";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useSnackbar } from "../../../hooks/useSnackbar";

const AssetsOverview = () => {
  const { showSnackbar } = useSnackbar();
  const { data: overviewData, error } = useGetAssetsOverview();

  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while Assets Overview.",
        description: "Please try again later.",
      });
    }
  }, [error]);

  return overviewData?.data ? (
    <AssetsOverViewContainer>
      <AssetsOverViewCardsContainer>
        <AssetsOverViewCard>
          <AssetsOverViewCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.MEDIUM}
          >
            Total Assets
          </AssetsOverViewCardTitle>
          <AssetsOverViewCardValue
            size={FontSizes.SM}
            weight={FontWeights.SEMIBOLD}
          >
            {overviewData.data.total_assets}
          </AssetsOverViewCardValue>
        </AssetsOverViewCard>
        <AssetsOverViewCard>
          <AssetsOverViewCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.MEDIUM}
          >
            Total Assets Value
          </AssetsOverViewCardTitle>
          <AssetsOverViewCardValue
            size={FontSizes.SM}
            weight={FontWeights.SEMIBOLD}
          >
            {currencyFormatter(
              overviewData.data.total_value,
              overviewData.data.currency
            )}
          </AssetsOverViewCardValue>
        </AssetsOverViewCard>
        <AssetsOverViewCard>
          <AssetsOverViewCardTitle
            size={FontSizes.TX_SM}
            weight={FontWeights.MEDIUM}
          >
            Available Assets
          </AssetsOverViewCardTitle>
          <AssetsOverViewCardValue
            size={FontSizes.SM}
            weight={FontWeights.SEMIBOLD}
          >
            {overviewData.data.available_assets}
          </AssetsOverViewCardValue>
        </AssetsOverViewCard>
      </AssetsOverViewCardsContainer>
      <AssetsOverViewCardsContainer>
        <AssetsOverViewCard>
          <AssetsOverViewCardValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Assets By Group
          </AssetsOverViewCardValue>
          <PieChartContainer>
            <PieChart height={200} width={200}>
              <Pie
                data={overviewData.data.assets_groups}
                dataKey="value"
                nameKey="name"
                outerRadius={100}
                innerRadius={50}
                startAngle={90}
                endAngle={-270}
              >
                {overviewData.data.assets_groups?.map((group, index) => (
                  <>
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        ASSETS_GROUPS_COLORS.filter((g) => g.id === group.id)[0]
                          .color
                      }
                    />
                  </>
                ))}
              </Pie>
            </PieChart>
            <PeiChartKeysContainer>
              {overviewData.data.assets_groups?.map((group, index) => (
                <PeiChartKey key={index}>
                  <PeiChartKeyColor
                    color={
                      ASSETS_GROUPS_COLORS.filter((g) => g.id === group.id)[0]
                        .color
                    }
                  />
                  <AssetsOverViewCardTitle
                    size={FontSizes.TX_SM}
                    weight={FontWeights.REGULAR}
                  >
                    {group.name}
                  </AssetsOverViewCardTitle>
                </PeiChartKey>
              ))}
            </PeiChartKeysContainer>
          </PieChartContainer>
        </AssetsOverViewCard>
        <AssetsOverViewCard>
          <AssetsOverViewCardValue
            size={FontSizes.TX_SM}
            weight={FontWeights.SEMIBOLD}
          >
            Assets By Status
          </AssetsOverViewCardValue>
          <PieChartContainer>
            <PieChart height={200} width={200}>
              <Pie
                data={overviewData.data.assets_statuses}
                dataKey="value"
                nameKey="name"
                outerRadius={100}
                startAngle={90}
                endAngle={-270}
              >
                {overviewData.data.assets_statuses?.map((status, index) => (
                  <>
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        ASSETS_Statuses_COLORS.filter(
                          (s) => s.id === status.id
                        )[0].color
                      }
                    />
                  </>
                ))}
              </Pie>
            </PieChart>
            <PeiChartKeysContainer>
              {overviewData.data.assets_statuses?.map((status, index) => (
                <PeiChartKey key={index}>
                  <PeiChartKeyColor
                    color={
                      ASSETS_Statuses_COLORS.filter(
                        (s) => s.id === status.id
                      )[0].color
                    }
                  />
                  <AssetsOverViewCardTitle
                    size={FontSizes.TX_SM}
                    weight={FontWeights.REGULAR}
                  >
                    {status.status}
                  </AssetsOverViewCardTitle>
                </PeiChartKey>
              ))}
            </PeiChartKeysContainer>
          </PieChartContainer>
        </AssetsOverViewCard>
      </AssetsOverViewCardsContainer>
      <RecentlyAddedList />
    </AssetsOverViewContainer>
  ) : null;
};

export default AssetsOverview;
