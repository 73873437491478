import { ContentContainer, ModalTitle, ModalFooter } from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import Modal from "../../../shared/Modal";
import { ModalProps } from "../../../../types/componentsProps";

const UnrollNowModal = ({
  open,
  onClose,
  onConfirm,
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  onConfirm: ModalProps["onConfirm"];
}) => {
  return (
    <Modal onClose={onClose} open={open}>
      <ContentContainer>
        <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Are you sure you want to unroll{"\n"} from this benefit?
        </ModalTitle>

        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            onClick={onClose}
          />

          <Button
            text="Unroll Now"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            onClick={onConfirm}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};

export default UnrollNowModal;
