import { useEffect } from "react";
import PeopleEmptyState from "../../PeopleEmptyState";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../shared/Table/styled";
import Typography from "../../../shared/Typography";
import {
  PayslipPeriod,
  PayslipsContainer,
  PayslipsHeader,
  PayslipsText,
  PayslipsTitle,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import moment from "moment";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import ActionsDropDown from "../../../shared/ActionsDropDown";
import { useNavigate } from "react-router-dom";
import { useGetEmployeePayslips } from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";

const Payslips = () => {
  const { data: employeePayslips, error } = useGetEmployeePayslips();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch employee payslips",
      });
    }
  }, [error]);
  const renderPayslipsRows = () => {
    return employeePayslips?.map((payslip, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <PayslipPeriod size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {moment(payslip.cycle_start_date).format("DD MMM, YYYY")} -{" "}
              {moment(payslip.cycle_end_date).format("DD MMM, YYYY")}
            </PayslipPeriod>
          </TableCell>

          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(payslip.payday_date).format("DD MMM, YYYY")}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(payslip.salary_gross, payslip.currency)}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(payslip.total_deductions, payslip.currency)}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <PayslipsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(payslip.salary_net, payslip.currency)}
            </PayslipsText>
          </TableCell>
          <TableCell>
            <ActionsDropDown
              actions={[
                {
                  text: "View",
                  onSelect: () => {
                    navigate(`/people/profile/1/payslip-details/${payslip.id}`);
                  },
                },
                { text: "Download", onSelect: () => {}, url: payslip.url },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderPayslipsTable = () => {
    return (
      <PayslipsContainer>
        <PayslipsHeader>
          <PayslipsTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Payslips
          </PayslipsTitle>
        </PayslipsHeader>
        <Table>
          <TableHeader>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Pay period
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Payment date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Gross pay
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total deductions
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Net Pay
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableHeader>
          {renderPayslipsRows()}
        </Table>
      </PayslipsContainer>
    );
  };

  return employeePayslips?.length === 0 || !employeePayslips ? (
    <PeopleEmptyState
      title="You don’t have payslips for this employee"
      btnText="Check Payments"
      onBtnClick={() => {}}
    />
  ) : (
    renderPayslipsTable()
  );
};

export default Payslips;
