import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  PayrollDetailsContainer,
  PayrollDetailsHeader,
  PayrollDetailsHeaderTitleContainer,
  PayrollDetailsSubtitle,
  PayrollDetailsTitle,
  CardsContainer,
  CardsColumn,
  TransparentRow,
  DownloadAllButton,
  DownloadButtonContainer,
  Header,
  PayrollDetailsCardRow,
} from "./styled";
import {
  Card,
  CardHeader,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
  CardRowValuesContainer,
} from "../../shared/Card";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Badge from "../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../types/componentsProps";
import moment from "moment";
import Button from "../../shared/Button";
import Banknote from "../../../Images/icons/bank-note-white.svg";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import Typography from "../../shared/Typography";
import Download from "../../../Images/icons/download-.svg";
import { useGetPayrollDetails } from "../../../queries/payroll";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useSnackbar } from "../../../hooks/useSnackbar";

const PayrollDetails = () => {
  const { report_id } = useParams<{ report_id: string }>();
  const { data: report, error } = useGetPayrollDetails(report_id);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      const err = error as unknown as AxiosError;
      switch (err.response?.status) {
        case 401:
          navigate("/login");
          break;
        case 403:
          navigate("/404");
          break;
        default:
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while fetching payroll review.",
            description: "Please try again later.",
          });
          break;
      }
    }
  }, [error]);

  if (!report_id) {
    <Navigate to="/404" />;
  }

  const renderReportDetails = () => {
    return (
      <CardsContainer>
        <CardsColumn>
          <Card style={{ marginBottom: "0rem" }}>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Employees
              </CardTitle>
            </CardHeader>

            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total Employees
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {report?.total_employees}
              </CardRowValue>
            </CardRow>

            <TransparentRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Active
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {report?.total_active_Employees}
              </CardRowValue>
            </TransparentRow>
          </Card>

          <Card style={{ marginBlockStart: "0rem" }}>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Payroll Cost
              </CardTitle>
            </CardHeader>

            <CardRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Total Cost
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(report?.total_coast, report?.currency)}
              </CardRowValue>
            </CardRow>

            <TransparentRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Net Salary
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(report?.net_salaries, report?.currency)}
              </CardRowValue>
            </TransparentRow>
            <TransparentRow>
              <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Liabilities
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {currencyFormatter(report?.liabilities, report?.currency)}
              </CardRowValue>
            </TransparentRow>
          </Card>
        </CardsColumn>

        <CardsColumn>
          <Card>
            <CardHeader>
              <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
                Payroll Documents
              </CardTitle>
              <DownloadAllButton
                href={report?.all_docs_url}
                download
                target="_blank"
              >
                <Typography
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  Download All
                </Typography>
              </DownloadAllButton>
            </CardHeader>
            {report?.docs.map((document, index) => (
              <CardRow key={index}>
                <CardRowTitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  {document.name}
                </CardRowTitle>
                <DownloadButtonContainer
                  href={document.url}
                  download
                  target="_blank"
                >
                  <img src={Download} alt="download" width={16} height={16} />
                </DownloadButtonContainer>
              </CardRow>
            ))}
          </Card>
        </CardsColumn>
      </CardsContainer>
    );
  };

  return report ? (
    <PayrollDetailsContainer>
      <PayrollDetailsHeader>
        <div>
          <PayrollDetailsHeaderTitleContainer>
            <PayrollDetailsTitle
              size={FontSizes.SM}
              weight={FontWeights.SEMIBOLD}
            >
              Payroll Details
            </PayrollDetailsTitle>
            <Badge
              color={BadgeTypes.grayBlue}
              text={`${moment(report.cycle_start_date).format(
                "DD MMM"
              )} - ${moment(report.cycle_end_date).format("DD MMM. YYYY")}`}
            />
          </PayrollDetailsHeaderTitleContainer>
          <PayrollDetailsSubtitle
            size={FontSizes.TX_MD}
            weight={FontWeights.REGULAR}
          >
            {report.company_name}
          </PayrollDetailsSubtitle>
        </div>

        <Button
          text="Proceed with payment"
          variant={ButtonVariants.primary}
          size={ButtonSizes.md}
          iconLeading={
            <img src={Banknote} alt="ban-note" width={20} height={20} />
          }
          onClick={() => {
            navigate(`/payroll/process`);
          }}
        />
      </PayrollDetailsHeader>
      <Header>
        <CardHeader>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Payroll report
          </CardTitle>
        </CardHeader>
        <CardRowValuesContainer>
          <PayrollDetailsCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Submission due date
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              {moment(report.payday).format("DD MMM. YYYY")}
            </CardRowValue>
          </PayrollDetailsCardRow>

          <PayrollDetailsCardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Payroll Status
            </CardRowTitle>

            <Badge text={report.status} color={BadgeTypes.warning} dot />
          </PayrollDetailsCardRow>
        </CardRowValuesContainer>
      </Header>
      {renderReportDetails()}
    </PayrollDetailsContainer>
  ) : null;
};

export default PayrollDetails;
