import React, { useState } from "react";
import {
  NoPaymentText,
  PaymentCard,
  PaymentCardAmount,
  PaymentCardData,
  PaymentCardDate,
  PaymentCardLeftCol,
  PaymentCardRightCol,
  PaymentCardType,
  PaymentCardsContainer,
  PaymentSectionContainer,
  PaymentSectionHeader,
  PaymentSectionTitle,
  PaymentsContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Button from "../../shared/Button";
import PaymentAlert from "./PaymentAlert";
import { Payment } from "../../../types";
import { BadgeTypes } from "../../../types/componentsProps";
import Bank from "../../../Images/icons/bankIcon.svg";
import Badge from "../../shared/Badge";
interface PaymentSectionProps {
  payments: Payment[];
  style?: React.CSSProperties;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ payments , style}) => {
  const [showAlert, setShowAlert] = useState(false);
  const isEmpty = payments.length === 0;

  const getBadgeColor = (status: string) => {
    switch (status) {
      case "Waiting for payment to arrive":
        return BadgeTypes.warning;
      case "Processing":
        return BadgeTypes.blue;
      default:
        return BadgeTypes.blue;
    }
  };

  const renderPayments = () => {
    return payments.map((payment, index) => {
      return (
        <PaymentCard key={index}>
          <PaymentCardLeftCol>
            <img src={Bank} alt="bank-icon" width={36} height={36} />
            <PaymentCardData>
              <PaymentCardType
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {payment.type}
              </PaymentCardType>
              <PaymentCardDate
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                Initiated on {payment.date}
              </PaymentCardDate>
            </PaymentCardData>
          </PaymentCardLeftCol>

          <PaymentCardRightCol>
            <PaymentCardAmount
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              $
              {new Intl.NumberFormat("en-US").format(
                parseFloat(payment.amount)
              )}
            </PaymentCardAmount>
            <Badge
              color={getBadgeColor(payment.status)}
              text={payment.status}
            />
          </PaymentCardRightCol>
        </PaymentCard>
      );
    });
  };
  return (
    <PaymentSectionContainer style={style}>
      <PaymentSectionHeader>
        <PaymentSectionTitle
          size={FontSizes.TX_MD}
          weight={FontWeights.SEMIBOLD}
        >
          Payments in Progress
        </PaymentSectionTitle>
        <Button
          text="View all"
          variant={ButtonVariants.link_color}
          size={ButtonSizes.md}
          style={{ color: "#2191F9" }}
        />
      </PaymentSectionHeader>
      {showAlert ? <PaymentAlert /> : null}
      {isEmpty ? (
        <PaymentsContainer>
          <NoPaymentText size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
            No Payments
          </NoPaymentText>
        </PaymentsContainer>
      ) : (
        <PaymentCardsContainer>{renderPayments()} </PaymentCardsContainer>
      )}
    </PaymentSectionContainer>
  );
};

export default PaymentSection;
