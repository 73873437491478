import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyPayroll, PayrollReviewEmployee } from "../../types";
import { ALL_STATUS_OPTIONS, CURRENCIES } from "../../mockServer/mockData";
import { AppDispatch, RootState } from "../store";
import { organizationLocationOptionsWithoutIcon } from "../../constants/registration";
import { DropdownListOption } from "../../types/componentsProps";
import { sortDirection } from "../../types/apisTypes";

interface PayrollState {
  activePayrollDetailsEmployeesVariables: {
    search: string;
    selectedStatus: DropdownListOption;
    currentPage: number;
    itemsPerPage: number;
    totalRecords: number;
    filteredEmployees: PayrollReviewEmployee[];
    totalPages: number;
  };
  processSteps: {
    title: string;
    completed: boolean;
  }[];
  activeProcessStep: number;
  payrollProcessData: {
    paymentMethod: string | null;
    manualTransferMethod: string | null;
    bankDetails: {
      location: DropdownListOption;
      currency: DropdownListOption;
    };
  };
  payrollListVariables: {
    search: string;
    sortKey: keyof CompanyPayroll;
    sortDirection: sortDirection;
    currentPage: number;
    itemsPerPage: number;
    totalPages: number;
    filteredPayrolls: CompanyPayroll[];
    totalRecords: number;
  };
}

const initialState: PayrollState = {
  activePayrollDetailsEmployeesVariables: {
    search: "",
    selectedStatus: ALL_STATUS_OPTIONS[0],
    currentPage: 1,
    itemsPerPage: 3,
    totalRecords: 0,
    filteredEmployees: [],
    totalPages: 0,
  },
  activeProcessStep: 0,
  processSteps: [
    {
      title: "Review Payment",
      completed: false,
    },
    {
      title: "Choose how you want to pay",
      completed: false,
    },
    {
      title: "Choose manual transfer",
      completed: false,
    },
    {
      title: "Add transfer details",
      completed: false,
    },
    {
      title: "Confirm payment",
      completed: false,
    },
  ],
  payrollProcessData: {
    paymentMethod: "manual",
    manualTransferMethod: "bank",
    bankDetails: {
      location: organizationLocationOptionsWithoutIcon[0],
      currency: CURRENCIES[0],
    },
  },
  payrollListVariables: {
    search: "",
    sortKey: "company.name" as keyof CompanyPayroll,
    sortDirection: "ascending",
    currentPage: 1,
    itemsPerPage: 7,
    totalPages: 0,
    filteredPayrolls: [],
    totalRecords: 0,
  },
};

const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {
    setPayrollProcessSteps: (
      state,
      action: PayloadAction<{ title: string; completed: boolean }[]>
    ) => {
      state.processSteps = action.payload;
    },

    setActiveProcessStep: (state, action: PayloadAction<number>) => {
      state.activeProcessStep = action.payload;
    },
    updatePayrollProcessData: (
      state,
      action: PayloadAction<
        Partial<{
          paymentMethod: string | null;
          manualTransferMethod: string | null;
          bankDetails: {
            location: DropdownListOption;
            currency: DropdownListOption;
          };
        }>
      >
    ) => {
      state.payrollProcessData = {
        ...state.payrollProcessData,
        ...action.payload,
      };
    },
    updatePayrollListVariables: (
      state,
      action: PayloadAction<Partial<PayrollState["payrollListVariables"]>>
    ) => {
      state.payrollListVariables = {
        ...state.payrollListVariables,
        ...action.payload,
      };
    },
    updateActivePayrollDetailsEmployeesVariables: (
      state,
      action: PayloadAction<
        Partial<PayrollState["activePayrollDetailsEmployeesVariables"]>
      >
    ) => {
      state.activePayrollDetailsEmployeesVariables = {
        ...state.activePayrollDetailsEmployeesVariables,
        ...action.payload,
      };
    },
  },
});

export const onNextPayrollStep =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { payroll } = getState();
    const { processSteps, activeProcessStep } = payroll;
    const newProcessSteps = processSteps.map((step: any, index: number) => {
      if (index === activeProcessStep) {
        return { ...step, completed: true };
      }
      return step;
    });
    dispatch(setPayrollProcessSteps(newProcessSteps));
    dispatch(setActiveProcessStep(activeProcessStep + 1));
  };

export const onPreviousPayrollStep =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { payroll } = getState();
    const { processSteps, activeProcessStep } = payroll;
    const newProcessSteps = processSteps.map((step: any, index: number) => {
      if (index === activeProcessStep) {
        return { ...step, completed: false };
      }
      return step;
    });
    dispatch(setPayrollProcessSteps(newProcessSteps));
    dispatch(setActiveProcessStep(activeProcessStep - 1));
  };

export const {
  setPayrollProcessSteps,
  setActiveProcessStep,
  updatePayrollProcessData,
  updatePayrollListVariables,
  updateActivePayrollDetailsEmployeesVariables,
} = payrollSlice.actions;

export default payrollSlice.reducer;
