import {
  ContentContainer,
  EmployeeName,
  ModalDescription,
  ModalFooter,
  ModalTitle,
} from "./styled";

import { ModalProps } from "../../../../types/componentsProps";
import Modal from "../../../shared/Modal";
import Button from "../../../shared/Button";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import ModalIcon from "../../../../Images/icons/alertTriangle.svg";

const DeleteEmployeeModal = ({
  open,
  onClose,
  onConfirm,
  name,
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  onConfirm: () => void;
  name: string;
}) => {
  return (
    <Modal onClose={onClose} open={open}>
      <ContentContainer>
        <img src={ModalIcon} alt="alert-icon" />
        <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Are you sure you want to delete {"\n"}{" "}
          <EmployeeName>{name}</EmployeeName> {"\n"} from your company?
        </ModalTitle>
        <ModalDescription size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          All their data will be discarded.
        </ModalDescription>
        <ModalFooter>
          <Button
            text="Cancel"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={onClose}
          />

          <Button
            text="Delete"
            variant={ButtonVariants.destructive_secondary}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={onConfirm}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};

export default DeleteEmployeeModal;
