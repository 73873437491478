import { useNavigate } from "react-router-dom";
import { ButtonSizes, ButtonVariants } from "../../../constants/styles";
import { HOME_SCREEN } from "../../../constants/routesNames";
import LogOut from "../../../Images/icons/log-out.svg";
import { LogoutBtn } from "./styled";
import { useLogout } from "../../../queries/auth";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { removeUser } from "../../../redux/slices/authSlice";

const LogoutButton = ({ style }: { style?: React.CSSProperties }) => {
  const { mutateAsync: logout } = useLogout();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const onClick = async () => {
    try {
      await logout();
      navigate(HOME_SCREEN);
      dispatch(removeUser());
    } catch (error: any) {
      showSnackbar({
        open: true,
        title: "Logout error",
        description: error.response.message,
        type: SnackbarTypes.ERROR,
      });
    }
  };

  return (
    <LogoutBtn
      variant={ButtonVariants.secondary_gray}
      size={ButtonSizes.lg}
      text="Logout"
      iconLeading={<img src={LogOut} alt="logout" width={20} height={20} />}
      onClick={onClick}
      style={style}
    />
  );
};

export default LogoutButton;
