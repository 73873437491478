import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  DownloadButton,
  InvoiceDetailsCards,
  InvoiceDetailsContainer,
  InvoiceDetailsHeader,
  InvoiceDetailsHeaderButtons,
  InvoiceDetailsSubtitle,
  InvoiceDetailsTitle,
  ItemsTableContainer,
  TableTextBold,
  TableTextFaded,
  TotalText,
  TotalTextContainer,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import Printer from "../../../Images/icons/printer.svg";
import Download from "../../../Images/icons/download.svg";
import Button from "../../shared/Button";
import Typography from "../../shared/Typography";
import { useAppDispatch } from "../../../redux/reduxHooks";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../shared/Card";
import moment from "moment";
import Badge from "../../shared/Badge";
import { PAYMENT_STATUS_BADGE_COLOR } from "../../../types";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../shared/Table/styled";
import { TableFadedText } from "../InvoicesDue/styled";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import {
  selectSingleInvoice,
  setTotalPayment,
} from "../../../redux/slices/paymentSlice";
import { useGetInvoiceDetails } from "../../../queries/payment";
import { useEffect } from "react";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../types/componentsProps";

const InvoiceDetails = () => {
  const { invoice_id } = useParams();
  const { data: invoice, error } = useGetInvoiceDetails(invoice_id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handlePrint = (event: any, url: string) => {
    event.preventDefault();
    window.open(url, "PRINT", "height=600,width=600");
  };
  const { showSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      showSnackbar({
        type: SnackbarTypes.ERROR,
        open: true,
        title: "Something went wrong while fetching invoice details",
        description: "Please try again later.",
      });
      navigate("/payment");
    }
  }, [error]);

  const renderRows = () => {
    return invoice?.items.map((item, index) => {
      return (
        <>
          <TableRow>
            <TableCell
              style={{
                width: "70%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TableTextBold
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  {item.item_name}
                </TableTextBold>
                <TableFadedText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  {invoice.category}
                </TableFadedText>
              </div>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {item.quantity}
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {currencyFormatter(item.unit_price, item.currency)}
              </TableTextFaded>
            </TableCell>
            <TableCell>
              <TableTextFaded
                size={FontSizes.TX_SM}
                weight={FontWeights.MEDIUM}
              >
                {currencyFormatter(item.amount, item.currency)}
              </TableTextFaded>
            </TableCell>
          </TableRow>
          {index !== invoice.items.length - 1 && <Divider />}
        </>
      );
    });
  };

  return invoice ? (
    <InvoiceDetailsContainer>
      <InvoiceDetailsHeader>
        <div>
          <InvoiceDetailsTitle
            size={FontSizes.SM}
            weight={FontWeights.SEMIBOLD}
          >
            {invoice_id}
          </InvoiceDetailsTitle>
          <InvoiceDetailsSubtitle
            size={FontSizes.TX_MD}
            weight={FontWeights.REGULAR}
          >
            Fermentum Venenatis Tortor
          </InvoiceDetailsSubtitle>
        </div>
        <InvoiceDetailsHeaderButtons>
          <Button
            text=""
            iconLeading={<img src={Printer} width={20} height={20} alt="" />}
            size={ButtonSizes.md}
            variant={ButtonVariants.secondary_color}
            onClick={(event) => handlePrint(event, invoice.url)}
          />
          <DownloadButton href={invoice.url} target={"_blank"}>
            <img src={Download} width={20} height={20} alt="" />
            <Typography size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Download
            </Typography>
          </DownloadButton>
          {invoice.status === "Initiated" || invoice.status === "Pending" ? (
            <Button
              text="Pay Invoice"
              size={ButtonSizes.md}
              variant={ButtonVariants.primary}
              onClick={() => {
                dispatch(selectSingleInvoice(invoice));
                dispatch(setTotalPayment(invoice.total.amount));
                navigate("/payment/pay-invoices");
              }}
            />
          ) : null}
        </InvoiceDetailsHeaderButtons>
      </InvoiceDetailsHeader>
      <InvoiceDetailsCards>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Bill To
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Client Name
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.bill.client_name}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Address
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.bill.address}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Registration Number
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.bill.registration_number}
            </CardRowValue>
          </CardRow>
        </Card>
        <Card>
          <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            Invoice Details
          </CardTitle>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Issue Date
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.issue_date).format("DD MMM, YYYY")}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Due Date
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.due_date).format("DD MMM, YYYY")}
            </CardRowValue>
          </CardRow>
          <CardRow>
            <CardRowTitle size={FontSizes.TX_SM} weight={FontWeights.SEMIBOLD}>
              Status
            </CardRowTitle>
            <CardRowValue size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              <Badge
                text={invoice.status}
                dot
                color={PAYMENT_STATUS_BADGE_COLOR[invoice.status]}
              />
            </CardRowValue>
          </CardRow>
        </Card>
      </InvoiceDetailsCards>
      <ItemsTableContainer>
        <Table>
          <TableHeader>
            <TableCell
              style={{
                width: "70%",
              }}
            >
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Item
              </TableTextFaded>
            </TableCell>

            <TableCell>
              {" "}
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                QTY
              </TableTextFaded>
            </TableCell>
            <TableCell>
              {" "}
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Unit Price
              </TableTextFaded>
            </TableCell>
            <TableCell>
              {" "}
              <TableTextFaded
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Amount
              </TableTextFaded>
            </TableCell>
          </TableHeader>
          <Divider />
          {renderRows()}
        </Table>
      </ItemsTableContainer>
      <TotalTextContainer>
        <TotalText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          Total
        </TotalText>
        <TotalText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
          {currencyFormatter(invoice.total.amount, invoice.total.currency)}
        </TotalText>
      </TotalTextContainer>
    </InvoiceDetailsContainer>
  ) : null;
};

export default InvoiceDetails;
