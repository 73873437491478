import { useLocation } from "react-router-dom";
import {
  BreadcrumbItem,
  BreadcrumbsContainer,
  Chevron,
  InactiveBreadcrumb,
} from "./styled";
import Home from "../../../Images/icons/home.svg";
import ChevronRight from "../../../Images/icons/chevronRight.svg";
import Typography from "../Typography";
import { FontSizes, FontWeights } from "../../../constants/styles";
import { useAppSelector } from "../../../redux/reduxHooks";
import { useEffect, useState } from "react";

const Breadcrumbs = () => {
  const activeEmployee = useAppSelector((state) => state.people.activeEmployee);
  const [name, setName] = useState("");

  useEffect(() => {
    if (activeEmployee) {
      setName(
        `${activeEmployee.user.first_name
          .charAt(0)
          .toUpperCase()}${activeEmployee.user.first_name.slice(
          1
        )} ${activeEmployee.user.last_name
          .charAt(0)
          .toUpperCase()}${activeEmployee.user.last_name.slice(1)}`
      );
    }
  }, [activeEmployee]);

  const location = useLocation();
  if (location.pathname === "/") {
    return null;
  }

  const getItemText = (item: string, index: number) => {
    if (Number(item) && location.pathname.split("/")[index - 1] === "profile") {
      return name;
    }

    const itemParts = item.split("-");
    return itemParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  };

  const getItemPath = (item: string, index: number) => {
    const path = `/${location.pathname
      .split("/")
      .slice(1, index + 1)
      .join("/")}`;

    if (item === "people") {
      return "/people/manage";
    } else return path;
  };

  const renderInactiveBreadcrumb = () => {
    return (
      <InactiveBreadcrumb>
        <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
          ...
        </Typography>
        <Chevron src={ChevronRight} alt="chevron-right" />
      </InactiveBreadcrumb>
    );
  };

  const renderBreadcrumbsItem = (item: any, index: number, isLast: boolean) => {
    return (
      <BreadcrumbItem key={index} to={getItemPath(item, index)} isLast={isLast}>
        <Typography
          size={FontSizes.TX_SM}
          weight={isLast ? FontWeights.SEMIBOLD : FontWeights.MEDIUM}
        >
          {getItemText(item, index)}
        </Typography>
        {!isLast && <Chevron src={ChevronRight} alt="chevron-right" />}
      </BreadcrumbItem>
    );
  };

  const renderBreadCrumbs = () => {
    const items = location.pathname.split("/");
    const isLongPath = items.length >= 5;
    if (isLongPath) {
      return (
        <>
          {renderBreadcrumbsItem(location.pathname.split("/")[1], 1, false)}
          {renderInactiveBreadcrumb()}
          {renderBreadcrumbsItem(
            location.pathname.split("/")[items.length - 2],
            items.length - 2,
            false
          )}
          {renderBreadcrumbsItem(
            location.pathname.split("/")[items.length - 1],
            items.length - 1,
            true
          )}
        </>
      );
    } else {
      return items.map((item, index) => {
        if (index === 0) {
          return null;
        }
        return renderBreadcrumbsItem(item, index, index === items.length - 1);
      });
    }
  };

  return (
    <BreadcrumbsContainer>
      <BreadcrumbItem to="/" isLast={false}>
        <img src={Home} alt="home" />
        <Chevron src={ChevronRight} alt="chevron-right" />
      </BreadcrumbItem>

      {renderBreadCrumbs()}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
