import styled from "styled-components";
import Typography from "../../../shared/Typography";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import { Card, CardRowValue } from "../../../shared/Card";

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  height: 100%;
  @media screen and (max-width: 760px) {
    width: 70%;
  }
`;

export const SummaryTitle = styled(Typography)`
  color: ${Colors.text_brand_primary};
`;
export const SummarySubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
  white-space: pre-wrap;
  margin-bottom: ${Spaces.spacing_xl};
  margin-top: ${Spaces.spacing_xs};
`;
export const EgyptBanner = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  padding: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_warning_primary};
  border: 1px solid ${Colors.fg_warning_secondary};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: ${Spaces.spacing_xl};
  align-items: flex-start;
`;

export const BannerDescription = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spaces.spacing_2xl};
`;

export const ContractSummaryCard = styled(Card)`
  margin-bottom: ${Spaces.spacing_xl};
`;

export const ContractSummaryCardRowValue = styled(CardRowValue)`
  display: flex;
  flex-direction: column;
`;

export const ContractFileContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  padding: ${Spaces.spacing_xl};
  margin-bottom: ${Spaces.spacing_xl};
`;
export const DownloadButton = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.bg_brand_secondary};
  border-radius: ${Radiuses.radius_full};
  margin-left: auto;
`;
export const ContractFileName = styled(Typography)`
  color: ${Colors.text_secondary_700};
  margin-left: ${Spaces.spacing_xl};
`;

export const InviteEmployeeBanner = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xl};
  padding: ${Spaces.spacing_xl};
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_disabled};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-bottom: ${Spaces.spacing_xl};
  align-items: flex-start;
`;
