import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import Button from "../../shared/Button";
import { Table, TableCell, TableRow } from "../../shared/Table/styled";
import {
  InvoicesDueContainer,
  InvoicesDueHeader,
  InvoicesDueSelectedCounter,
  InvoicesDueTableContainer,
  SortIcon,
  TableBoldText,
  TableCustomHeader,
  TableFadedText,
  TableHeadWithIcon,
  TableSecondaryHeader,
} from "./styled";
import ArrowDown from "../../../Images/icons/arrow-down.svg";
import Checkbox from "../../shared/Checkbox";
import {
  selectAllInvoices,
  selectCategoryInvoices,
  selectSingleInvoice,
  setTotalPayment,
  updateInvoicesDueTableVariables,
} from "../../../redux/slices/paymentSlice";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import moment from "moment";
import { Invoice, PAYMENT_STATUS_BADGE_COLOR } from "../../../types";
import Badge from "../../shared/Badge";
import { useNavigate } from "react-router-dom";
import { useGetCategorizedInvoices } from "../../../queries/payment";

const InvoicesDue = () => {
  const { selected_invoices, invoices_due_tableVariables, invoices_due_list } =
    useAppSelector((state) => state.payment);
  const [allInvoices, setAllInvoices] = useState<string[]>([]);

  const { data: invoices } = useGetCategorizedInvoices({
    sort: {
      sortKey: invoices_due_tableVariables.sortKey,
      sortDirection: invoices_due_tableVariables.sortDirection,
    },
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (invoices) {
      let allInvoices: string[] = [];

      for (let i = 0; i < invoices.length; i++) {
        const nestedInvoices = invoices[i].invoices.map(
          (invoice) => invoice.id
        );
        allInvoices = [...allInvoices, ...nestedInvoices];
      }
      setAllInvoices(allInvoices);
    }
  }, [invoices]);

  const renderInvoices = (invoices: Invoice[], hasCategory: boolean) => {
    return invoices.map((invoice, index) => {
      return (
        <TableRow key={index}>
          <TableCell
            style={
              hasCategory
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "72px",
                    boxSizing: "border-box",
                  }
                : {}
            }
            onClick={() => dispatch(selectSingleInvoice(invoice))}
          >
            <TableHeadWithIcon>
              <Checkbox
                label={""}
                checked={selected_invoices.includes(invoice)}
              />

              <TableBoldText size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                {invoice.id}
              </TableBoldText>
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {invoice.description}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Badge
              text={invoice.status}
              dot
              color={PAYMENT_STATUS_BADGE_COLOR[invoice.status]}
            />
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(invoice.total.amount, invoice.total.currency)}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.issue_date).format("MMM DD, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <TableFadedText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(invoice.due_date).format("MMM DD, YYYY")}
            </TableFadedText>
          </TableCell>
          <TableCell>
            <Button
              variant={ButtonVariants.secondary_color}
              size={ButtonSizes.sm}
              text="View Invoice"
              onClick={() => {
                navigate(`/payment/invoice-details/${invoice.id}`);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderCategory = () => {
    return invoices_due_list.map((category, index) => {
      return (
        <>
          {category.category !== null ? (
            <TableSecondaryHeader key={index} height="20px">
              <TableCell
                onClick={() => {
                  dispatch(selectCategoryInvoices(category.category));
                }}
              >
                <Checkbox
                  label=""
                  checked={category.invoices.every((invoice: { id: string }) =>
                    selected_invoices.some(
                      (selected) => selected.id === invoice.id
                    )
                  )}
                />
              </TableCell>
              <TableCell>
                <TableFadedText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  {category.category}
                </TableFadedText>
              </TableCell>
              <TableCell>
                <Badge
                  text={category.status}
                  dot
                  color={PAYMENT_STATUS_BADGE_COLOR[category.status]}
                />
              </TableCell>
              <TableCell>
                <TableFadedText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  {currencyFormatter(category.total, category.currency)}
                </TableFadedText>
              </TableCell>

              <TableCell>
                <TableFadedText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  {moment(category.issue_date).format("MMM DD, YYYY")}
                </TableFadedText>
              </TableCell>
              <TableCell>
                <TableFadedText
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                >
                  {moment(category.due_date).format("MMM DD, YYYY")}
                </TableFadedText>
              </TableCell>
              <TableCell></TableCell>
            </TableSecondaryHeader>
          ) : null}

          {renderInvoices(category.invoices, category.category !== null)}
        </>
      );
    });
  };

  const renderTable = () => {
    return (
      <InvoicesDueTableContainer>
        <Table
          style={{
            position: "relative",
          }}
        >
          <TableCustomHeader height="20px">
            <TableCell
              onClick={(e) => {
                e.stopPropagation();
                dispatch(selectAllInvoices());
              }}
            >
              <TableHeadWithIcon>
                <Checkbox
                  label=""
                  checked={selected_invoices.length === allInvoices.length}
                />
                <TableFadedText
                  size={FontSizes.TX_XS}
                  weight={FontWeights.MEDIUM}
                >
                  Invoice number
                </TableFadedText>
                <SortIcon
                  src={ArrowDown}
                  alt="Arrow Down"
                  width={16}
                  height={16}
                  direction={invoices_due_tableVariables.sortDirection}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      updateInvoicesDueTableVariables({
                        sortDirection:
                          invoices_due_tableVariables.sortDirection ===
                          "ascending"
                            ? "descending"
                            : "ascending",
                      })
                    );
                  }}
                />
              </TableHeadWithIcon>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Description
              </TableFadedText>
            </TableCell>

            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Status
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Value
              </TableFadedText>
            </TableCell>

            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Issue Date
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Due Date
              </TableFadedText>
            </TableCell>
            <TableCell>
              <TableFadedText
                size={FontSizes.TX_XS}
                weight={FontWeights.MEDIUM}
              >
                Actions
              </TableFadedText>
            </TableCell>
          </TableCustomHeader>
          {renderCategory()}
        </Table>
      </InvoicesDueTableContainer>
    );
  };

  const onPayInvoices = () => {
    const total = selected_invoices.reduce(
      (acc, invoice) => acc + invoice.total.amount,
      0
    );
    dispatch(setTotalPayment(total));

    navigate("/payment/pay-invoices");
  };
  return (
    <InvoicesDueContainer>
      <InvoicesDueHeader>
        <InvoicesDueSelectedCounter
          size={FontSizes.TX_SM}
          weight={FontWeights.MEDIUM}
        >
          {selected_invoices.length} selected invoice(s)
        </InvoicesDueSelectedCounter>
        <Button
          variant={ButtonVariants.primary}
          size={ButtonSizes.sm}
          disabled={!selected_invoices.length}
          text="Pay Invoice(s)"
          onClick={() => onPayInvoices()}
        />
      </InvoicesDueHeader>

      {renderTable()}
    </InvoicesDueContainer>
  );
};

export default InvoicesDue;
