import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "./apiClient";
import {
  AddServicesToCompanyParams,
  CreateOrganizationParams,
  CreateOrganizationResponse,
} from "../types/apisTypes";
import { Company } from "../types";

export const useCreateOrganization = (params: CreateOrganizationParams) => {
  return useMutation<CreateOrganizationResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.post("/organization", params);
      return response.data;
    },
  });
};

export const useAddServicesToCompany = ({
  companyId,
  services,
}: AddServicesToCompanyParams) => {
  return useMutation<CreateOrganizationResponse, Error>({
    mutationFn: async () => {
      const response = await apiClient.patch(`/company/${companyId}`, {
        services,
      });
      return response.data;
    },
  });
};

export const useGetUserCompanies = () => {
  return useQuery<Company[], Error>({
    queryKey: ["AllCompanies"],
    queryFn: async () => {
      const response = await apiClient.get("/admin/company");
      return response.data.data.companies;
    },
    retry: false,
  });
};
