import PeopleEmptyState from "../../PeopleEmptyState";
import { useNavigate } from "react-router-dom";

const OffBoarding = () => {
  const navigate = useNavigate();

  return (
    <PeopleEmptyState
      title="You don’t have any offboarding contracts"
      description="Off-boarded employees have completed all their off-boarding tasks. You can view contacts from employees profile."
      btnText="View People"
      onBtnClick={() => navigate("/people/manage")}
    />
  );
};

export default OffBoarding;
