import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DropdownListOption } from "../../types/componentsProps";
import { Company } from "../../types";

interface CompaniesState {
  createdCompanyId: string | null;
  userCompaniesOptions: DropdownListOption[];
  activeCompany: Company | null;
}

const initialState: CompaniesState = {
  createdCompanyId: null,
  userCompaniesOptions: [],
  activeCompany: null,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCreatedCompanyId: (state, action: PayloadAction<string | null>) => {
      state.createdCompanyId = action.payload;
    },
    setUserCompaniesOptions: (
      state,
      action: PayloadAction<DropdownListOption[]>
    ) => {
      state.userCompaniesOptions = action.payload;
    },
    createCompaniesDropdownOptions: (
      state,
      action: PayloadAction<Company[]>
    ) => {
      state.userCompaniesOptions = action.payload.map((company) => ({
        label: company.name,
        value: company.name,
        iconLeading: company.logo.url,
      }));
      state.activeCompany = action.payload[1];
    },
    activeCompany: (state, action: PayloadAction<Company | null>) => {
      state.activeCompany = action.payload;
    },
  },
});

export const {
  setCreatedCompanyId,
  setUserCompaniesOptions,
  createCompaniesDropdownOptions,
} = companiesSlice.actions;

export default companiesSlice.reducer;
