import React, { useState } from "react";
import { Company } from "../../../../types";
import Modal from "../../../shared/Modal";
import {
  CompanyDetailsLabel,
  CompanyDetailsRow,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTitleAndIcon,
  ModalSubtitle,
  DataContainer,
  RadioGroup,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import CloseIcon from "../../../../Images/icons/alertClose.svg";
import CompanyDetailsIcon from "../../../../Images/icons/companyDetailsIcon.svg";
import Button from "../../../shared/Button";
import { PhoneCodes } from "../../../../constants/registration";
import DropdownList from "../../../shared/DropdownList";
import InputField from "../../../shared/InputField";
import PartialDropdown from "../../../shared/PartialDropdown";
import { DropdownListOption } from "../../../../types/componentsProps";
import Checkbox from "../../../shared/Checkbox";

interface AddCompanyModalProps {
  company: Company | null;
  onClose: () => void;
  open: boolean;
}

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({ onClose, open }) => {
  const [formData, setFormData] = useState({
    country: { label: "", value: "" },
    legal_name: "",
    companyType: { label: "", value: "" },
    phone: "",
    phoneCode: PhoneCodes[0],
    industry: { label: "", value: "" },
    established: false,
    tax_id: "",
    registration_number: "",
  });

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer>
        <ModalHeader>
          <ModalTitleAndIcon>
            <img
              src={CompanyDetailsIcon}
              alt="company-details-icon"
              width={48}
              height={48}
            />
            <div>
              {" "}
              <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
                Add Company details
              </ModalTitle>
              <ModalSubtitle
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                Create your company profile for free in less than 5 minutes.
              </ModalSubtitle>
            </div>
          </ModalTitleAndIcon>

          <img src={CloseIcon} alt="close-icon" onClick={onClose} />
        </ModalHeader>
        <DataContainer>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Country
            </CompanyDetailsLabel>
            <DropdownList
              options={[]}
              selectedOption={formData.country}
              setSelectedOption={(option) =>
                setFormData({ ...formData, country: option })
              }
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Legal Company Name
            </CompanyDetailsLabel>
            <InputField
              value={formData.legal_name}
              onChange={(e) =>
                setFormData({ ...formData, legal_name: e.target.value })
              }
              containerStyle={{ width: "100%" }}
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Company Type
            </CompanyDetailsLabel>
            <DropdownList
              options={[]}
              selectedOption={formData.companyType}
              setSelectedOption={(option) =>
                setFormData({ ...formData, companyType: option })
              }
            />
          </CompanyDetailsRow>

          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Phone
            </CompanyDetailsLabel>
            <PartialDropdown
              value={formData.phone}
              onChange={(e) => {
                setFormData({ ...formData, phone: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === ".") {
                  e.preventDefault();
                }
              }}
              options={PhoneCodes}
              setSelectedOption={(option: DropdownListOption) => {
                setFormData({ ...formData, phoneCode: option });
              }}
              selectedOption={formData.phoneCode}
              type="number"
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Industry
            </CompanyDetailsLabel>
            <DropdownList
              options={[]}
              selectedOption={formData.industry}
              setSelectedOption={(option) =>
                setFormData({ ...formData, industry: option })
              }
            />
          </CompanyDetailsRow>
          <CompanyDetailsRow>
            <CompanyDetailsLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              Is it established?
            </CompanyDetailsLabel>
            <RadioGroup>
              <Checkbox
                label={"Yes"}
                checked={formData.established}
                onChange={() => setFormData({ ...formData, established: true })}
                radio={true}
              />
              <Checkbox
                label={"No"}
                checked={!formData.established}
                onChange={() =>
                  setFormData({ ...formData, established: false })
                }
                radio={true}
              />
            </RadioGroup>
          </CompanyDetailsRow>
          {formData.established && (
            <>
              <CompanyDetailsRow>
                <CompanyDetailsLabel
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  Tax ID
                </CompanyDetailsLabel>
                <InputField
                  value={formData.tax_id}
                  onChange={(e) =>
                    setFormData({ ...formData, tax_id: e.target.value })
                  }
                  containerStyle={{ width: "100%" }}
                />
              </CompanyDetailsRow>
              <CompanyDetailsRow>
                <CompanyDetailsLabel
                  size={FontSizes.TX_SM}
                  weight={FontWeights.MEDIUM}
                >
                  Registration Number
                </CompanyDetailsLabel>
                <InputField
                  value={formData.registration_number}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      registration_number: e.target.value,
                    })
                  }
                  containerStyle={{ width: "100%" }}
                />
              </CompanyDetailsRow>
            </>
          )}
        </DataContainer>

        <ModalFooter>
          <Button
            text="Add Company"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
          />
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
};

export default AddCompanyModal;
