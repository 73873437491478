import { Navigate, useParams } from "react-router-dom";
import {
  CardsColumn,
  CardsContainer,
  RequestDetails,
  RequestDetailsHeader,
  RequestDetailsTitle,
  RequestTimelineDate,
  RequestTimelineIcons,
  RequestTimelineLink,
  RequestTimelineLinkText,
  RequestTimelineRow,
  RequestTimelineText,
} from "./styled";
import {
  Card,
  CardRow,
  CardRowTitle,
  CardRowValue,
  CardTitle,
} from "../../../shared/Card";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import moment from "moment";
import { useEffect } from "react";
import Badge from "../../../shared/Badge";
import { BadgeTypes, SnackbarTypes } from "../../../../types/componentsProps";
import Check from "../../../../Images/icons/check-circle.svg";
import X from "../../../../Images/icons/x-circle.svg";
import ExternalLink from "../../../../Images/icons/link-external.svg";
import DottedLine from "../../../../Images/icons/dotted-line.svg";
import { useGetRequestDetails } from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const EmployeeRequestDetails = () => {
  const { request_id } = useParams();
  const { data: request, error } = useGetRequestDetails(request_id);

  useEffect(() => {
    if (!request_id || !request) {
      <Navigate to={"/404"} />;
    }
  }, [request_id, request]);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to fetch employee requests",
      });
    }
  }, [error]);

  const renderTimeline = () => {
    return request?.timeline?.map((timeline: any, index: number) => {
      return (
        <RequestTimelineRow key={index}>
          <RequestTimelineIcons>
            <img
              src={
                timeline?.status === "Initiated" ||
                timeline?.status === "Delivered"
                  ? Check
                  : X
              }
              alt="status"
              width={16}
              height={16}
            />
            {index !== request?.timeline?.length - 1 && (
              <img src={DottedLine} alt="status" width={14} height={30} />
            )}
          </RequestTimelineIcons>
          <div>
            <RequestTimelineText
              size={FontSizes.TX_SM}
              weight={FontWeights.REGULAR}
            >
              Request {timeline?.status}
            </RequestTimelineText>
            <RequestTimelineLink to={`/people/profile/1`} target="_blank">
              <RequestTimelineLinkText
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                by: {timeline?.by}
              </RequestTimelineLinkText>
              <img src={ExternalLink} alt="status" width={10} height={10} />
            </RequestTimelineLink>
          </div>
          <RequestTimelineDate
            size={FontSizes.TX_SM}
            weight={FontWeights.REGULAR}
          >
            {moment(timeline?.date).format("DD MMM , YYYY")}
          </RequestTimelineDate>
        </RequestTimelineRow>
      );
    });
  };
  return request ? (
    <RequestDetails>
      <RequestDetailsHeader>
        <RequestDetailsTitle size={FontSizes.SM} weight={FontWeights.SEMIBOLD}>
          Request Details
        </RequestDetailsTitle>
      </RequestDetailsHeader>
      <CardsContainer>
        <CardsColumn>
          {/* Request Information */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Request Information
            </CardTitle>
            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Type
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {request?.type}
              </CardRowValue>
            </CardRow>
            {request?.startDate && request?.endDate ? (
              <>
                <CardRow>
                  <CardRowTitle
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    Start date
                  </CardRowTitle>
                  <CardRowValue
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    {moment(request?.startDate).format("DD MMM , YYYY")}
                  </CardRowValue>
                </CardRow>

                <CardRow>
                  <CardRowTitle
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    End date
                  </CardRowTitle>
                  <CardRowValue
                    size={FontSizes.TX_SM}
                    weight={FontWeights.SEMIBOLD}
                  >
                    {moment(request?.endDate).format("DD MMM , YYYY")}
                  </CardRowValue>
                </CardRow>
              </>
            ) : null}

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Description
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {request?.description}
              </CardRowValue>
            </CardRow>
          </Card>

          {/* Request Status */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Request Status
            </CardTitle>

            <CardRow>
              <CardRowTitle
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                Status
              </CardRowTitle>
              <CardRowValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                <Badge
                  dot
                  text={request?.status || "Pending"}
                  color={
                    request?.status === "Delivered"
                      ? BadgeTypes.success
                      : request?.status === "Pending"
                      ? BadgeTypes.blueLight
                      : BadgeTypes.error
                  }
                />
              </CardRowValue>
            </CardRow>

            {request?.reasonOfRejection && (
              <CardRow>
                <CardRowTitle
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  Reason of rejection
                </CardRowTitle>
                <CardRowValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.SEMIBOLD}
                >
                  {request?.reasonOfRejection}
                </CardRowValue>
              </CardRow>
            )}
          </Card>
        </CardsColumn>

        <CardsColumn>
          {/* Request Timeline */}
          <Card>
            <CardTitle size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
              Request Timeline
            </CardTitle>
            {renderTimeline()}
          </Card>
        </CardsColumn>
      </CardsContainer>
    </RequestDetails>
  ) : null;
};

export default EmployeeRequestDetails;
