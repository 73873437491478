import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";
import { Link } from "react-router-dom";

export const RecentlyAddedListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spaces.spacing_3xl};
  gap: ${Spaces.spacing_md};
  justify-content: start;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: ${Radiuses.radius_xl};
  border: 1px solid ${Colors.border_secondary};
  background-color: ${Colors.bg_primary};
  flex: 1;
  margin-bottom: ${Spaces.spacing_3xl};
`;
export const RecentlyAddedTableHeader = styled.div`
  width: 100%;
  padding-inline: ${Spaces.spacing_3xl};
`;
export const TextBold = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const TextLight = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const TableHeadWithIcon = styled.div`
  display: flex;
  gap: ${Spaces.spacing_xs};
  cursor: pointer;
  align-items: center;
`;

export const SortIcon = styled.img<{ direction: string }>`
  transform: ${(props) =>
    props.direction === "ascending" ? "rotate(180deg)" : "rotate(0deg)"};
  transition: transform 0.3s;
`;

export const AssetLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;
