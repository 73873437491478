import { createServer, Model, Response } from "miragejs";
import {
  ACCESS_TOKEN,
  ASSETS,
  COMPANIES,
  COUNTRIES,
  EMPLOYEES,
  EMPLOYEES_CONTRACTS,
  EMPLOYEE_BENEFITS,
  EMPLOYEE_PAYSLIPS_OVERVIEW,
  EMPLOYEE_REQUESTS_Details,
  EMPLOYEE_REQUESTS_LIST,
  EmployeeAssets,
  PAYSLIPS,
  PEOPLE_TO_ONBOARD,
  USERS,
  USER_COMPANY,
  CONTRACT,
  COMPANIES_PAYROLLS,
  PAYROLLS_REVIEWS,
  PAYROLLS_REVIEWS_EMPLOYEES,
  PAYROLL_DETAILS,
  USER_PAYMENT_PROCESS,
  INVOICES_LIST,
  CATEGORIZED_INVOICES,
  ASSETS_HUB_LIST,
  ALL_ASSETS_STATUSES,
  ASSETS_DETAILS,
  ALL_ASSETS_GROUPS,
  JOB_HIERARCHY,
} from "./mockData";
import {
  AddAssetParams,
  CreateOrganizationParams,
  CreatePaymentParams,
  LoginParams,
  RegisterUserParams,
} from "../types/apisTypes";
import {
  Asset,
  CompanyPayroll,
  Employee,
  Invoice,
  PayrollReviewEmployee,
  UserAsset,
} from "../types";
import qs from "qs";
import { URLS_TO_PASS_THROUGH } from "./urlsToPassThrough";

export function makeServer({ environment = "test" } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
      company: Model,
      employee: Model,
      contract: Model,
      asset: Model,
      employeeAsset: Model,
      requestOverview: Model,
      requestDetail: Model,
      benefit: Model,
      payslipOverview: Model,
      payslipDetail: Model,
      userCompany: Model,
      country: Model,
      companiesPayroll: Model,
      payrollReview: Model,
      payrollReviewEmployee: Model,
      payrollDetail: Model,
      payrollProcess: Model,
      invoice: Model,
      categorizedInvoice: Model,
      payment: Model,
      userAsset: Model,
      assetsDetail: Model,
      jobHierarchy: Model,
    },
    seeds(server) {
      server.db.loadData({
        users: USERS,
        companies: [USER_COMPANY],
        employees: EMPLOYEES,
        contracts: EMPLOYEES_CONTRACTS,
        assets: ASSETS,
        employeeAssets: EmployeeAssets,
        requestOverviews: EMPLOYEE_REQUESTS_LIST,
        requestDetails: EMPLOYEE_REQUESTS_Details,
        benefits: EMPLOYEE_BENEFITS,
        payslipOverviews: EMPLOYEE_PAYSLIPS_OVERVIEW,
        payslipDetails: PAYSLIPS,
        useCompanies: COMPANIES,
        countries: COUNTRIES,
        companiesPayrolls: COMPANIES_PAYROLLS,
        payrollReviews: PAYROLLS_REVIEWS,
        payrollReviewEmployees: PAYROLLS_REVIEWS_EMPLOYEES,
        payrollDetails: PAYROLL_DETAILS,
        payrollProcesses: USER_PAYMENT_PROCESS,
        invoices: INVOICES_LIST,
        categorizedInvoices: CATEGORIZED_INVOICES,
        payments: [],
        userAssets: ASSETS_HUB_LIST,
        assetsDetails: ASSETS_DETAILS,
        jobHierarchy: JOB_HIERARCHY,
      });
    },

    routes() {
      this.pretender.handledRequest = (verb, path, request) => {
        console.log(
          `✍🏼 Your server responded to ${verb}: ${path} \n with a ${request.status} status code`
        );
      };

      // To fix passThrough issue with MirageJS
      // https://github.com/miragejs/miragejs/issues/1006#issuecomment-1439946798
      // FIX -->
      const NativeXMLHttpRequest = window.XMLHttpRequest;
      //@ts-ignore
      window.XMLHttpRequest = function XMLHttpRequest() {
        const request = new NativeXMLHttpRequest();
        // @ts-ignore
        delete request.onloadend;
        return request;
      };
      // <-- FIX

      this.passthrough("https://www.googleapis.com/oauth2/v1/***");
      this.passthrough((request) => {
        return URLS_TO_PASS_THROUGH.some((url) =>
          request.url.includes(process.env.REACT_APP_BACKEND_URL + url)
        );
      });
      this.urlPrefix = process.env.REACT_APP_BACKEND_URL || "";

      this.post("/login", (schema, request) => {
        const data: LoginParams = JSON.parse(request.requestBody);
        const user = schema.db.users.findBy({ email: data.email });
        if (user.password !== data.password) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",

            user,
            access_token: ACCESS_TOKEN,
          }
        );
      });
      this.post("/register", (schema, request) => {
        const data: RegisterUserParams = JSON.parse(request.requestBody);
        const existingUser = schema.db.users.findBy({ email: data.email });

        if (existingUser) {
          return new Response(
            409,
            {},
            {
              status: "failed",
              message: "User already exists",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            user: {
              ...USERS[0],
              email: data.email,
              first_name: data.first_name,
              last_name: data.last_name,
              user_information: {
                ...USERS[0].user_information,
                phone: data.phone,
                country_of_citizenship: data.country_of_citizenship,
              },
            },
            access_token: ACCESS_TOKEN,
          }
        );
      });
      this.get("/verify_email", (schema, request) => {
        const token = request.queryParams.token;
        if (token === "1234") {
          return new Response(
            200,
            {},
            {
              status: "succeeded",
              message: "Processed successfully",
            }
          );
        }
        return new Response(
          401,
          {},
          {
            status: "failed",
            message: "Invalid token",
          }
        );
      });
      this.post("/resend_email", (schema, request) => {
        const data = JSON.parse(request.requestBody);
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
          }
        );
      });

      this.get("/logout", (_: any) => {
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/user", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",

            user: USERS[0],
          }
        );
      });
      this.get("/country", (schema, request) => {
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",

            countries: COUNTRIES,
          }
        );
      });
      this.post("/organization", (schema, request) => {
        const data: CreateOrganizationParams = JSON.parse(request.requestBody);

        const token = request.requestHeaders.Authorization;

        schema.db.companies.insert(data);
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: USER_COMPANY,
          }
        );
      });

      this.patch("/company/:id", (schema, request) => {
        const data = JSON.parse(request.requestBody);
        const id = request.params.id === "null" ? "11" : request.params.id;
        const company = schema.db.companies.findBy({ id });
        if (!company) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Company not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { ...company, services: { ...data.services } },
          }
        );
      });

      this.get("/admin/company", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              companies: COMPANIES,
            },
          }
        );
      });

      this.post("/auth-otp", (_: any, request) => {
        const data = JSON.parse(request.requestBody);
        if (data.otp === "123456") {
          return new Response(
            200,
            {},
            {
              status: "succeeded",
              message: "Processed successfully",
            }
          );
        }
        return new Response(
          401,
          {},
          {
            status: "failed",
            message: "Invalid OTP",
          }
        );
      });

      this.get("/people/contracts-to-onboard", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: PEOPLE_TO_ONBOARD,
          }
        );
      });
      this.post("employee/:id/contract/renew", (schema, request) => {
        const id = request.params.id;
        const contract = schema.db.contracts.where({
          user_id: id,
        })[0];
        const employee = schema.db.employees.find(id);
        if (!contract) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Contract not found",
            }
          );
        }
        schema.db.contracts.insert({
          ...CONTRACT,
          id: undefined,
          user_id: id,

          expiring: false,
        });

        schema.db.employees.update(id, {
          ...employee,
          contract_expiring: undefined,
        });
        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });

      this.get("/employee", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        let employees = schema
          .all("employee")
          .models.map((model) => model.attrs);
        const params: any = qs.parse(request.queryParams as unknown as string);
        const filter = params.filter;
        const sort = params.sort;
        const search = params.search;
        if (filter?.employee_status) {
          employees = schema.db.employees.where((employee: Employee) => {
            if (filter.employee_status === "All Statuses") {
              return true;
            }
            return employee.employee_status === filter.employee_status;
          });
        }

        if (search) {
          employees = schema.db.employees.where((employee: Employee) => {
            return (
              employee.user.first_name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              employee.user.last_name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              employee.user.email.toLowerCase().includes(search.toLowerCase())
            );
          });
        }
        if (sort?.sortKey) {
          employees = employees.sort((a, b) => {
            if (sort.sortDirection === "ascending") {
              return (
                a[sort.sortKey as keyof typeof a] as string
              ).localeCompare(b[sort.sortKey as keyof typeof b] as string);
            } else {
              return (
                b[sort.sortKey as keyof typeof b] as string
              ).localeCompare(a[sort.sortKey as keyof typeof a] as string);
            }
          });
        }
        const page = Number(request.queryParams.page) || 1;
        const pageSize = Number(request.queryParams.page_size) || 7;
        const lastPage = Math.ceil(employees.length / pageSize);
        const paginatedEmployees = employees.slice(
          (page - 1) * pageSize,
          page * pageSize
        );

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              employees: paginatedEmployees,
              _metadata: {
                page: page,
                page_size: pageSize,
                first_page: 1,
                last_page: lastPage,
                previous_page: null,
                next_page: null,
                total_records: employees.length,
              },
            },
          }
        );
      });
      this.get("/employee/:id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const employee = schema.db.employees.find(id);

        if (!employee) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Employee not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { employee },
          }
        );
      });
      this.post("/employee", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        const data = JSON.parse(request.requestBody);
        const employee = schema.db.employees.insert({
          type: "employee",
          employee_status: "Draft",
          joining_date: new Date().toISOString(),
          user: {
            id: "1",
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
          },
          user_information: {
            id: "1",
            position: data.position,

            country_of_citizenship: data.nationality,
            how_did_you_hear_about_us: "",
          },
          company: {
            id: "1",
            name: data.company,
          },
        });

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { employee },
          }
        );
      });
      this.delete("/employee/:id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const employee = schema.db.employees.find(id);
        schema.db.employees.remove({ id });

        if (!employee) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Employee not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/employee/:id/contract", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const contract = schema.db.contracts.where({
          user_id: id,
        })[0];
        if (!contract) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Contract not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { contract },
          }
        );
      });
      this.post("/employee/:id/contract", (schema, request) => {
        const data = JSON.parse(request.requestBody);
        const id = request.params.id;
        const employee = schema.db.employees.find(id);
        if (!employee) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Employee not found",
            }
          );
        }
        const contract = {
          ...CONTRACT,
          user_id: id,
          id: undefined,

          assignment_details: {
            ...CONTRACT.assignment_details,
            job_title: data.job_title,
          },
        };
        schema.db.contracts.insert(contract);

        schema.db.employees.update(employee.id, {
          employee_status: "Active",
        });

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/employee/:id/asset", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const employeeAssets = schema.db.employeeAssets.where({
          user_id: id,
        })[0];
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { assets: employeeAssets.assets },
          }
        );
      });
      this.get("/job_hierarchy", (schema, request) => {
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            records: JOB_HIERARCHY,
          }
        );
      });
      this.get("/asset", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const assets = schema.db.assets;
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { assets },
          }
        );
      });
      this.post("/employee/:id/asset", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const data = JSON.parse(request.requestBody);
        const id = request.params.id;
        const employee = schema.db.employees.find(id);
        if (!employee) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Employee not found",
            }
          );
        }
        const asset = schema.db.assets.where({
          id: data.asset_id,
        })[0];
        asset.employeeStatus = "In-Use";
        const employeeAssets: {
          user_id: string;
          assets: Asset[];
        } = schema.db.employeeAssets.where({
          user_id: id,
        })[0];

        schema.db.employeeAssets.update(employee.id, {
          assets: [...employeeAssets.assets, asset],
        });
        schema.db.assets.remove(asset.id);

        if (!asset) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Asset not found",
            }
          );
        }

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.delete("/employee/:id/asset/:asset_id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const assetId = request.params.asset_id;

        const employee = schema.db.employees.find(id);
        if (!employee) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Employee not found",
            }
          );
        }

        const employeeAssets = schema.db.employeeAssets.where({
          user_id: id,
        })[0];
        const asset = employeeAssets.assets.find(
          (employeeAsset: Asset) => employeeAsset.id === assetId
        );
        if (!asset) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Asset not found",
            }
          );
        }
        schema.db.employeeAssets.update(employee.id, {
          assets: employeeAssets.assets.filter(
            (employeeAsset: Asset) => employeeAsset.id !== assetId
          ),
        });

        schema.db.assets.insert({
          ...asset,
          employeeStatus: "In-Active",
          id: undefined,
        });

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/request", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { requests: schema.db.requestOverviews },
          }
        );
      });
      this.get("/request/:id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const requestDetail = schema.db.requestDetails.find(id);
        if (!requestDetail) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Request not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { request: requestDetail },
          }
        );
      });
      this.get("/benefit", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { benefits: schema.db.benefits },
          }
        );
      });
      this.delete("/benefit/:id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const benefit = schema.db.benefits.find(id);
        schema.db.benefits.remove({ id });

        if (!benefit) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Benefit not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.post("/benefit/:id/unenroll", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const benefit = schema.db.benefits.find(id);
        if (!benefit) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Benefit not found",
            }
          );
        }
        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/payslip", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { payslips: schema.db.payslipOverviews },
          }
        );
      });

      this.get("/payslip/:id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const payslipDetail = schema.db.payslipDetails.find(id);
        if (!payslipDetail) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Payslip not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { payslip: payslipDetail },
          }
        );
      });
      this.post("/utils/calculate-salary", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            currency: "USD",
            data: {
              salary_gross: 20000,
              salary_total: 30000,
              eor_fees: {
                total: 6000,
                amount: 200,
                period: "month",
              },
            },
          }
        );
      });
      this.get("/payroll", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        let payrolls = schema
          .all("companiesPayroll")
          .models.map((model) => model.attrs);
        const params: any = qs.parse(request.queryParams as unknown as string);
        const sort = params.sort;
        const search = params.search;

        if (search) {
          payrolls = schema.db.companiesPayrolls.where(
            (payroll: CompanyPayroll) => {
              return payroll.company.name
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          );
        }
        if (sort?.sortKey) {
          payrolls = payrolls.sort((a, b) => {
            if (sort.sortDirection === "ascending") {
              if (sort.sortKey === "company.name") {
                // @ts-ignore
                return a.company.name.localeCompare(
                  // @ts-ignore
                  b.company.name
                );
              }
              return (
                a[sort.sortKey as keyof typeof a] as string
              ).localeCompare(b[sort.sortKey as keyof typeof b] as string);
            } else {
              if (sort.sortKey === "company.name") {
                return (
                  (b as CompanyPayroll).company.name as string
                ).localeCompare(
                  (a as CompanyPayroll)[
                    sort.sortKey as keyof typeof a
                  ] as string
                );
              }
              return (
                // @ts-ignore
                b.company.name.localeCompare(
                  // @ts-ignore
                  a.company.name
                )
              );
            }
          });
        }
        const page = Number(request.queryParams.page) || 1;
        const pageSize = Number(request.queryParams.page_size) || 7;
        const lastPage = Math.ceil(payrolls.length / pageSize);
        const paginatedPayrolls = payrolls.slice(
          (page - 1) * pageSize,
          page * pageSize
        );

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              payrolls: paginatedPayrolls,
              _metadata: {
                page: page,
                page_size: pageSize,
                first_page: 1,
                last_page: lastPage,
                previous_page: null,
                next_page: null,
                total_records: schema.db.companiesPayrolls.length,
              },
            },
          }
        );
      });
      this.get("/payroll/:id", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const review = schema.db.payrollReviews.find(id);

        if (!review) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Payroll not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { review },
          }
        );
      });
      this.get("/payroll/:id/employee", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const id = request.params.id;
        const params = qs.parse(request.queryParams as unknown as string);
        const search = params.search as string;
        const status = params.status as string;
        let payrollReviewEmployees = schema
          .all("payrollReviewEmployee")
          .models.map((model) => model.attrs);

        if (search) {
          payrollReviewEmployees = schema.db.payrollReviewEmployees.where(
            (employee: PayrollReviewEmployee) => {
              return (
                employee.first_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                employee.last_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                employee.email.toLowerCase().includes(search.toLowerCase())
              );
            }
          );
        }
        if (status) {
          payrollReviewEmployees = payrollReviewEmployees.filter(
            // @ts-ignore
            (employee: PayrollReviewEmployee) => {
              return employee.status === status || status === "All Statuses";
            }
          );
        }

        const page = Number(request.queryParams.page) || 1;
        const pageSize = Number(request.queryParams.page_size) || 7;
        const lastPage = Math.ceil(payrollReviewEmployees.length / pageSize);
        const paginatedEmployees = payrollReviewEmployees.slice(
          (page - 1) * pageSize,
          page * pageSize
        );

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              payrollEmployees: paginatedEmployees,
              _metadata: {
                page: page,
                page_size: pageSize,
                first_page: 1,
                last_page: lastPage,
                previous_page: null,
                next_page: null,
                total_records: schema.db.payrollReviewEmployees.length,
              },
            },
          }
        );
      });
      this.post(
        "/payroll/:id/employee/:employee_id/bonus",
        (schema, request) => {
          const id = request.params.id;
          const employeeId = request.params.employee_id;
          const data = JSON.parse(request.requestBody);
          const review = schema.db.payrollReviews.find(id);
          let employee = schema.db.payrollReviewEmployees.find(employeeId);

          employee.bonuses = data;

          schema.db.payrollReviewEmployees.update(employeeId, employee);

          if (!review) {
            return new Response(
              404,
              {},
              {
                status: "failed",
                message: "Payroll not found",
              }
            );
          }
          if (!employee) {
            return new Response(
              404,
              {},
              {
                status: "failed",
                message: "Employee not found",
              }
            );
          }
          return new Response(
            201,
            {},
            {
              status: "succeeded",
              message: "Processed successfully",
              data: {},
            }
          );
        }
      );
      this.get("/payroll-details/:company_id", (schema, request) => {
        const id = request.params.company_id;
        const payrollDetails = schema.db.payrollDetails.where({
          company_id: id,
        })[0];

        if (!payrollDetails) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Payroll not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { payrollDetails },
          }
        );
      });
      this.get("/payroll/process", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { payrollProcess: schema.db.payrollProcesses[0] },
          }
        );
      });
      this.post("/payroll/submit", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/payment", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        let invoices = schema
          .all("categorizedInvoice")
          .models.map((model) => model.attrs);
        const params: any = qs.parse(request.queryParams as unknown as string);
        const sort = params.sort;
        if (sort?.sortKey) {
          invoices = invoices.sort((a, b) => {
            if (sort.sortDirection === "ascending") {
              return (
                a[sort.sortKey as keyof typeof a] as string
              ).localeCompare(b[sort.sortKey as keyof typeof b] as string);
            } else {
              return (
                b[sort.sortKey as keyof typeof b] as string
              ).localeCompare(a[sort.sortKey as keyof typeof a] as string);
            }
          });
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              invoices,
            },
          }
        );
      });
      this.get("/payment/:id", (schema, request) => {
        const id = request.params.id;
        const invoice = schema.db.invoices.find(id);

        if (!invoice) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Invoice not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { invoice },
          }
        );
      });
      this.post("/payment", (schema, request) => {
        const data: CreatePaymentParams = JSON.parse(request.requestBody);
        const total = data.invoices.reduce(
          (acc: any, invoice: any) => acc + invoice.total.amount,
          0
        );
        const invoicesIds = data.invoices.map((invoice: any) => invoice.id);
        const categorizedInvoices = schema.db.categorizedInvoices.map(
          (categorizedInvoice: (typeof CATEGORIZED_INVOICES)[0]) => {
            return schema.db.categorizedInvoices.update(categorizedInvoice.id, {
              invoices: categorizedInvoice.invoices.filter(
                (invoice) => !invoicesIds.includes(invoice.id)
              ),
            });
          }
        );

        const invoices = schema.db.invoices.map((invoice: Invoice) => {
          if (invoicesIds.includes(invoice.id)) {
            return schema.db.invoices.update(invoice.id, {
              status: "Processing",
            });
          }
          return invoice;
        });
        for (const invoice of categorizedInvoices) {
          if (invoice.invoices.length === 0) {
            schema.db.categorizedInvoices.remove({ id: invoice.id });
          }
        }

        schema.db.payments.insert({
          id: undefined,
          status: "Processing",
          total: {
            amount: total,
            currency: data.currency,
          },
          date: new Date().toISOString(),
          invoices: data.invoices.map((invoice: any) => ({
            ...invoice,
            status: "Processing",
          })),
          created_at: new Date().toISOString(),
          received_at: null,
        });
        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {},
          }
        );
      });
      this.get("/payment-list", (schema, request) => {
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { payments: schema.db.payments },
          }
        );
      });
      this.get("/payment-list/:id", (schema, request) => {
        const id = request.params.id;
        const payment = schema.db.payments.find(id);

        if (!payment) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Payment not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: { payment },
          }
        );
      });

      this.get("/assets", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        let assets = schema.all("userAsset").models.map((model) => model.attrs);
        const params: any = qs.parse(request.queryParams as unknown as string);
        const filter = params.filter;
        const search = params.search;
        if (filter?.status) {
          assets = schema.db.userAssets.where((userAsset: UserAsset) => {
            if (filter.status === "All Statuses") {
              return true;
            }
            return userAsset.status === filter.status;
          });
        }
        if (search) {
          assets.filter((asset: any) => {
            return (
              asset.name.toLowerCase().includes(search.toLowerCase()) ||
              asset.brand.toLowerCase().includes(search.toLowerCase()) ||
              asset.type.toLowerCase().includes(search.toLowerCase())
            );
          });
        }

        const page = Number(request.queryParams.page) || 1;
        const pageSize = Number(request.queryParams.page_size) || 7;
        const lastPage = Math.ceil(assets.length / pageSize);
        const paginatedAssets = assets.slice(
          (page - 1) * pageSize,
          page * pageSize
        );

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              assets: paginatedAssets,
              _metadata: {
                page: page,
                page_size: pageSize,
                first_page: 1,
                last_page: lastPage,
                previous_page: null,
                next_page: null,
                total_records: assets.length,
              },
            },
          }
        );
      });
      this.get("/assets/recently-added", (schema, request) => {
        const token = request.requestHeaders.Authorization;

        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        let allAssets = schema
          .all("UserAsset")
          .models.map((model) => model.attrs);
        let recentlyAddedAssets = allAssets.filter((asset: any) => {
          const purchaseDate = new Date(asset.purchase_date);
          const currentYear = new Date().getFullYear();
          return purchaseDate.getFullYear() === currentYear;
        });

        const params: any = qs.parse(request.queryParams as unknown as string);
        const sort = params.sort;
        if (sort?.sortKey) {
          recentlyAddedAssets = recentlyAddedAssets.sort((a, b) => {
            if (sort.sortDirection === "ascending") {
              return (
                a[sort.sortKey as keyof typeof a] as string
              ).localeCompare(b[sort.sortKey as keyof typeof b] as string);
            } else {
              return (
                b[sort.sortKey as keyof typeof b] as string
              ).localeCompare(a[sort.sortKey as keyof typeof a] as string);
            }
          });
        }

        const page = Number(request.queryParams.page) || 1;
        const pageSize = Number(request.queryParams.page_size) || 7;
        const lastPage = Math.ceil(recentlyAddedAssets.length / pageSize);
        const paginatedAssets = recentlyAddedAssets.slice(
          (page - 1) * pageSize,
          page * pageSize
        );

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              assets: paginatedAssets,
              _metadata: {
                page: page,
                page_size: pageSize,
                first_page: 1,
                last_page: lastPage,
                previous_page: null,
                next_page: null,
                total_records: recentlyAddedAssets.length,
              },
            },
          }
        );
      });
      this.get("/assets/overview", (schema, request) => {
        const token = request.requestHeaders.Authorization;

        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }
        const total_assets = schema.db.userAssets.length;
        const total_value = schema.db.userAssets.reduce(
          (acc: number, asset: UserAsset) => acc + asset.price.amount,
          0
        );
        const allAssets = [...schema.db.userAssets];
        const assets_groups: {
          id: number;
          name: string;
          value: number;
        }[] = [];
        const assets_statuses: {
          id: number;
          status: string;
          value: number;
        }[] = [];
        const available_assets = allAssets.filter(
          (asset) => asset.status === "In-Active"
        ).length;

        for (const asset of allAssets) {
          const group = assets_groups.find((g) => g.id === asset.group.id);
          if (group) {
            group.value += 1;
          } else {
            assets_groups.push({
              id: asset.group.id,
              name: asset.group.name,
              value: 1,
            });
          }

          const status = assets_statuses.find((s) => s.status === asset.status);
          if (status) {
            status.value += 1;
          } else {
            assets_statuses.push({
              id: ALL_ASSETS_STATUSES.filter(
                (status) => status.status === asset.status
              )[0].id,
              status: ALL_ASSETS_STATUSES.filter(
                (status) => status.status === asset.status
              )[0].status,
              value: 1,
            });
          }
        }

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              total_assets,
              total_value,
              currency: "USD",
              available_assets,
              assets_groups,
              assets_statuses,
            },
          }
        );
      });
      this.get("/assets/:id", (schema, request) => {
        const id = request.params.id;
        const asset = schema.db.assetsDetails.find(id);

        if (!asset) {
          return new Response(
            404,
            {},
            {
              status: "failed",
              message: "Asset not found",
            }
          );
        }
        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: asset,
          }
        );
      });
      this.post("/assets", (schema, request) => {
        const token = request.requestHeaders.Authorization;
        if (!token) {
          return new Response(
            401,
            {},
            {
              status: "failed",
              message:
                "Authorization error. Token is invalid, please login again",
            }
          );
        }

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
          }
        );
      });
      this.delete("/assets/:id/deassign", (schema, request) => {
        const id = request.params.id;
        let asset = schema.db.userAssets.find(id);
        if (asset) {
          schema.db.userAssets.update(id, {
            status: "In-Active",
            assignee: null,
          });
        } else if ((asset = schema.db.recentlyAddedAssets.find(id))) {
          schema.db.recentlyAddedAssets.update(id, {
            status: "In-Active",
            assignee: null,
          });
        }

        const details = schema.db.assetsDetails.find(id);
        const statuses = details.status.filter((s: any) => s !== "In-Use");
        schema.db.assetsDetails.update(id, {
          status: ["In-Active", ...statuses],
          assignee: null,
        });

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
          }
        );
      });
      this.post("/assets/:id/assign", (schema, request) => {
        const assetId = request.params.id;
        const id = JSON.parse(request.requestBody).user_id;
        const employee = schema.db.employees.find(id);
        schema.db.userAssets.update(assetId, {
          status: "In-Use",
          assignee: {
            name: employee.user.first_name + " " + employee.user.last_name,
            email: employee.user.email,
          },
        });
        const details = schema.db.assetsDetails.find(assetId);
        const statuses = details.status.filter((s: any) => s !== "In-Active");
        schema.db.assetsDetails.update(assetId, {
          status: ["In-Use", ...statuses],
          assignee: {
            id: employee.id,
            name: employee.user.first_name + " " + employee.user.last_name,
            email: employee.user.email,
            position: employee.user_information.position,
          },
        });

        return new Response(
          200,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
          }
        );
      });
      this.post("/assets/new", (schema, request) => {
        const data: AddAssetParams = JSON.parse(request.requestBody);
        const id = data.info.id;
        const employee: Employee = schema.db.employees.where({
          id: data.info.assignee,
        })[0];
        const isIDDuplicate = schema.db.userAssets.find(
          (asset) => asset.id === id
        );
        const newID = isIDDuplicate ? id + 11 : id;
        schema.db.userAssets.insert({
          id: newID,
          name: data.info.name,
          type: data.info.category,
          brand: data.info.brand,
          status: "In-Use",
          assignee: {
            name: employee.user.first_name + " " + employee.user.last_name,
            email: employee.user.email,
          },
          price: {
            amount: parseFloat(data.financialInfo.purchasePrice),
            currency: "USD",
          },
          purchase_date: data.financialInfo.purchaseDate,
          group: {
            id: ALL_ASSETS_GROUPS.filter(
              (group) => group.name === data.info.category
            )[0].id,
            name: data.info.category,
          },
        });
        schema.db.assetsDetails.insert({
          id: newID,
          name: data.info.name,
          type: data.info.category,
          brand: data.info.brand,
          condition: {
            percentage: "100%",
            last_reviewed: new Date().toISOString(),
          },
          status: ["In-Use"],
          assignee: {
            id: employee.id,
            name: employee.user.first_name + " " + employee.user.last_name,
            email: employee.user.email,
            position: employee.user_information.position,
          },
          warranty_and_supplier: {
            warranty_period: data.info.warrantyPeriod,
            supplier: data.info.supplier,
            supplier_contact: data.info.supplierContact,
          },
          maintenance_and_service: {
            maintenance_schedule: data.info.maintenancePeriod,
            last_service: "Not applicable",
            service_provider: data.info.maintenanceProvider,
          },
          disposal_information: {
            disposal_date: data.info.disposalDate,
            method_of_disposal: data.info.disposalMethod,
            disposal_reason: data.info.disposalReason,
          },
          financial: {
            purchase_date: data.financialInfo.purchaseDate,
            purchase_price: parseFloat(data.financialInfo.purchasePrice),
            currency: "USD",
            depreciation_method: data.financialInfo.deprecationMethod,
            current_value: data.financialInfo.currentValue,
          },
          asset_log: [
            {
              date: data.financialInfo.purchaseDate,
              employee_name: "Ovarc Admin",
              action: {
                type: "Purchased",
                file: {
                  url: "https://tourism.gov.in/sites/default/files/2019-04/dummy-pdf_2.pdf",
                  name: "purchase_order.pdf",
                  size: "2.2 MB",
                },
              },
            },
          ],
        });

        return new Response(
          201,
          {},
          {
            status: "succeeded",
            message: "Processed successfully",
            data: {
              asset_id: newID,
            },
          }
        );
      });
    },
  });

  return server;
}
