import {
  ContentContainer,
  ModalDescription,
  ModalFooter,
  ModalTitle,
} from "./styled";

import { ModalProps } from "../../../types/componentsProps";
import Modal from "../../shared/Modal";
import Button from "../../shared/Button";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../constants/styles";
import ModalIcon from "../../../Images/icons/alert-circle-warning.svg";
import { useNavigate } from "react-router-dom";

const SubmitPayrollReportModal = ({
  open,
  onClose,
  companyId,
}: {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  companyId: string;
}) => {
  const navigate = useNavigate();
  return (
    <Modal onClose={onClose} open={open}>
      <ContentContainer>
        <img src={ModalIcon} alt="alert-icon" />
        <ModalTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Submit payroll report?
        </ModalTitle>
        <ModalDescription size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          Once submitted, you will not be able to make{`\n`} changes.
        </ModalDescription>
        <ModalFooter>
          <Button
            text="Go back"
            variant={ButtonVariants.secondary_gray}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={onClose}
          />

          <Button
            text="Submit Payroll"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            style={{ width: "100%" }}
            onClick={() => {
              navigate(`/payroll/payroll-details/${companyId}`);
            }}
          />
        </ModalFooter>
      </ContentContainer>
    </Modal>
  );
};

export default SubmitPayrollReportModal;
