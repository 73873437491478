import {
  GeneralDataContainer,
  GeneralDataLabel,
  GeneralDataRow,
  GeneralDataValue,
  DataContainer,
  DataContainerHeader,
  DataContainerTitle,
  NoContractContainer,
  NoContractText,
  ExpiringContractBannerContainer,
  ExpiringContractTitle,
  ExpiringContractDescription,
  ExpiringContractButtons,
} from "./styled";
import {
  ButtonSizes,
  ButtonVariants,
  FontSizes,
  FontWeights,
} from "../../../../constants/styles";
import Button from "../../../shared/Button";
import NoContact from "../../../../Images/noContract.svg";
import Plus from "../../../../Images/icons/plus.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/reduxHooks";

import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import { useRenewEmployeeContract } from "../../../../queries/people";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";

const ContractOverView = () => {
  const { activeEmployee, activeEmployeeContract } = useAppSelector(
    (state) => state.people
  );
  const { mutateAsync: renewContract } = useRenewEmployeeContract();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const onRenewContract = async () => {
    if (activeEmployeeContract) {
      try {
        await renewContract({
          employeeId: activeEmployeeContract.id.toString(),
        });
        queryClient.invalidateQueries({
          queryKey: ["employeeContract", activeEmployeeContract.id],
        });
        queryClient.invalidateQueries({
          queryKey: ["employee", activeEmployeeContract.user_id],
        });
        queryClient.invalidateQueries({
          queryKey: ["employees"],
        });

        showSnackbar({
          open: true,
          type: SnackbarTypes.SUCCESS,
          title: "Contract Renewed successfully",
          description: "Failed to fetch employee requests",
          actionBtnText: "View Contract",
          actionBtnCallback() {
            navigate(`/people/profile/${activeEmployee?.id}/employee-contract`);
          },
        });
      } catch (error) {
        showSnackbar({
          open: true,
          type: SnackbarTypes.ERROR,
          title: "Something went wrong",
          description: "Failed to renew contract",
        });
      }
    }
  };

  const renderExpiringContractBanner = () => {
    return (
      <ExpiringContractBannerContainer>
        <ExpiringContractTitle
          size={FontSizes.TX_SM}
          weight={FontWeights.MEDIUM}
        >
          Contract Expiration
        </ExpiringContractTitle>
        <ExpiringContractDescription
          size={FontSizes.TX_SM}
          weight={FontWeights.REGULAR}
        >
          This employee’s contract will expire in 2 weeks (24 Nov, 2024){"\n"}
          .Do you want to renew the contract?
        </ExpiringContractDescription>
        <ExpiringContractButtons>
          <Button
            text={"Yes, Renew Contract"}
            size={ButtonSizes.sm}
            variant={ButtonVariants.primary}
            onClick={() => {
              onRenewContract();
            }}
          />
        </ExpiringContractButtons>
      </ExpiringContractBannerContainer>
    );
  };
  return (
    <DataContainer>
      <DataContainerHeader>
        <DataContainerTitle
          size={FontSizes.TX_MD}
          weight={FontWeights.SEMIBOLD}
        >
          Contract Information
        </DataContainerTitle>
      </DataContainerHeader>

      {activeEmployeeContract ? (
        <div>
          {activeEmployeeContract.expiring
            ? renderExpiringContractBanner()
            : null}
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Worker Type
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {activeEmployeeContract.worker_type}
            </GeneralDataValue>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Job Title
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {activeEmployeeContract.assignment_details.job_title}
            </GeneralDataValue>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Seniority Level
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {activeEmployeeContract.assignment_details.seniority_level}
            </GeneralDataValue>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Employment Type
            </GeneralDataLabel>
            <GeneralDataContainer>
              <GeneralDataValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {activeEmployeeContract.assignment_details.employment_type}
              </GeneralDataValue>
              <GeneralDataValue
                size={FontSizes.TX_SM}
                weight={FontWeights.REGULAR}
              >
                {activeEmployeeContract.assignment_details.hours_per_week}
              </GeneralDataValue>
            </GeneralDataContainer>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Salary
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {currencyFormatter(
                activeEmployeeContract.salary,
                activeEmployeeContract.currency
              )}
            </GeneralDataValue>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Employment Terms
            </GeneralDataLabel>
            <GeneralDataContainer>
              <GeneralDataValue
                size={FontSizes.TX_SM}
                weight={FontWeights.SEMIBOLD}
              >
                {activeEmployeeContract.assignment_details.employment_terms}
              </GeneralDataValue>
              {activeEmployeeContract.expiring ? (
                <GeneralDataValue
                  size={FontSizes.TX_SM}
                  weight={FontWeights.REGULAR}
                  error={true}
                >
                  Contract expiring in 2 weeks
                </GeneralDataValue>
              ) : null}
            </GeneralDataContainer>
          </GeneralDataRow>
          <GeneralDataRow>
            <GeneralDataLabel
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              Company
            </GeneralDataLabel>
            <GeneralDataValue
              size={FontSizes.TX_SM}
              weight={FontWeights.SEMIBOLD}
            >
              {activeEmployeeContract.assignment_details.company}
            </GeneralDataValue>
          </GeneralDataRow>
        </div>
      ) : (
        <NoContractContainer>
          <img src={NoContact} alt="No contracts" width={152} height={118} />
          <NoContractText size={FontSizes.TX_MD} weight={FontWeights.SEMIBOLD}>
            No contract assigned to this employee
          </NoContractText>
          <Button
            text={"Create Contract"}
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            iconLeading={<img src={Plus} alt="Add contract" />}
            onClick={() =>
              navigate(
                `/people/profile/${activeEmployee?.id}/employee-contract`
              )
            }
            style={{ marginBottom: "20px" }}
          />
        </NoContractContainer>
      )}
    </DataContainer>
  );
};

export default ContractOverView;
