import styled from "styled-components";
import { Colors, Radiuses, Spaces } from "../../../../constants/styles";
import Typography from "../../../shared/Typography";
import BG from "../../../../Images/icons/BackgroundDatternDecorative.svg";

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${Radiuses.radius_xl};
  background-color: ${Colors.bg_primary};
  border: 1px solid ${Colors.border_secondary};
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  margin-top: 22px;
`;

export const BenefitsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spaces.spacing_2xl} ${Spaces.spacing_3xl};
  border-bottom: 1px solid ${Colors.border_secondary};
`;
export const BenefitsTitle = styled(Typography)`
  color: ${Colors.text_primary};
`;

export const BenefitsBoldText = styled(Typography)`
  color: ${Colors.text_primary};
`;
export const BenefitsText = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spaces.spacing_3xl};
  justify-content: center;
  align-items: center;
  max-width: 400px;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-position: bottom;
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: ${Radiuses.radius_full};
  background-color: ${Colors.bg_brand_secondary};
`;
export const ModalTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-block-start: ${Spaces.spacing_xl};
  margin-block-end: ${Spaces.spacing_2xl};

  text-align: center;
  white-space: pre-line;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: ${Spaces.spacing_lg};
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 400px) {
    flex-direction: column-reverse;
  }
`;
