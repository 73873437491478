import { FontSizes, FontWeights } from "../../../constants/styles";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowDynamic,
} from "../../shared/Table/styled";
import {
  RecentlyAddedListContainer,
  RecentlyAddedTableHeader,
  TextBold,
  TextLight,
  TableHeadWithIcon,
  SortIcon,
  AssetLink,
} from "./styled";
import ArrowDown from "../../../Images/icons/arrow-down.svg";
import ArrowRight from "../../../Images/icons/arrow-right.svg";
import Badge from "../../shared/Badge";
import { ASSETS_LIST_STATUS_BADGE_COLOR } from "../../../types";
import { currencyFormatter } from "../../../utilities/currencyFormatter";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../redux/reduxHooks";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useGetRecentlyAddedAssetsList } from "../../../queries/assetsHub";
import { useEffect } from "react";
import { editRecentlyAddedListVariables } from "../../../redux/slices/assetsHubSlice";
import { AxiosError } from "axios";
import { SnackbarTypes } from "../../../types/componentsProps";
import { useNavigate } from "react-router-dom";
import TablePagination from "../../shared/TablePagination";

const RecentlyAddedList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showSnackbar } = useSnackbar();
  const { sortDirection, sortKey, currentPage, totalPages, itemsPerPage } =
    useAppSelector((state) => state.assetsHub.recentlyAddedListVariables);

  const activeCompany = useAppSelector(
    (state) => state.companies.activeCompany
  );

  const { data: list, error } = useGetRecentlyAddedAssetsList({
    company_id: activeCompany?.id ?? "",
    sort: {
      sortKey: sortKey,
      sortDirection: sortDirection,
    },

    page: currentPage,
    page_size: itemsPerPage,
  });

  useEffect(() => {
    if (list?.data) {
      dispatch(
        editRecentlyAddedListVariables({
          currentPage: list.data._metadata.page,
          totalRecords: list.data._metadata.total_records,
          totalPages: list.data._metadata.last_page,
        })
      );
    }
  }, [list]);

  useEffect(() => {
    if (error) {
      const err = error as unknown as AxiosError;
      switch (err.response?.status) {
        case 401:
          navigate("/login");
          break;
        case 403:
          navigate("/404");
          break;
        default:
          showSnackbar({
            type: SnackbarTypes.ERROR,
            open: true,
            title: "Something went wrong while fetching assets.",
            description: "Please try again later.",
          });
          break;
      }
    }
  }, [error]);

  const renderRows = () => {
    return list?.data.assets.map((item) => {
      return (
        <TableRow key={item.id}>
          <TableCell>
            <TextBold size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
              {item.id}
            </TextBold>
          </TableCell>
          <TableCell>
            <Badge
              dot
              text={item.status}
              color={ASSETS_LIST_STATUS_BADGE_COLOR[item.status]}
            />
          </TableCell>
          <TableCell>
            <div>
              <TextBold size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {item.name}
              </TextBold>
              <TextLight size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                {item.brand} - {item.type}
              </TextLight>
            </div>
          </TableCell>
          <TableCell>
            {item.assignee ? (
              <div>
                <TextBold size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                  {item.assignee.name}
                </TextBold>
                <TextLight size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                  {item.assignee.position}
                </TextLight>
              </div>
            ) : (
              <TextLight size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
                Unassigned
              </TextLight>
            )}
          </TableCell>
          <TableCell>
            <TextLight size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(item.price.amount, item.price.currency)}
            </TextLight>
          </TableCell>
          <TableCell>
            <TextLight size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(item.purchase_date).format("DD MMM , YYYY")}
            </TextLight>
          </TableCell>
          <TableCell>
            <AssetLink to={`/assets-hub/asset-details/${item.id}`}>
              <img src={ArrowRight} alt="Arrow Right" width={16} height={16} />
            </AssetLink>
          </TableCell>
        </TableRow>
      );
    });
  };
  return (
    <RecentlyAddedListContainer>
      <RecentlyAddedTableHeader>
        <TextBold size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
          Recent Added Assets
        </TextBold>
        <TextLight size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
          List of recent added assets in the last month.
        </TextLight>
      </RecentlyAddedTableHeader>{" "}
      <Table
        style={{
          position: "relative",
        }}
      >
        <TableHeader>
          <TableCell>
            <TableHeadWithIcon>
              <TextLight size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
                Id
              </TextLight>
              <SortIcon
                src={ArrowDown}
                alt="Arrow Down"
                width={16}
                height={16}
                direction={sortDirection}
                onClick={(e) =>
                  dispatch(
                    editRecentlyAddedListVariables({
                      sortKey: "id",
                      sortDirection:
                        sortDirection === "ascending"
                          ? "descending"
                          : "ascending",
                    })
                  )
                }
              />
            </TableHeadWithIcon>
          </TableCell>
          <TableCell>
            <TextLight size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Status
            </TextLight>
          </TableCell>
          <TableCell style={{ width: "35%" }}>
            <TextLight size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Asset
            </TextLight>
          </TableCell>
          <TableCell>
            <TextLight size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Assignee
            </TextLight>
          </TableCell>
          <TableCell>
            <TextLight size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Purchase Price
            </TextLight>
          </TableCell>
          <TableCell>
            <TextLight size={FontSizes.TX_XS} weight={FontWeights.MEDIUM}>
              Purchase Date
            </TextLight>
          </TableCell>
          <TableCell />
        </TableHeader>
        {renderRows()}
        {totalPages > 1 && (
          <TableRowDynamic height="68px">
            <TablePagination
              currentPage={currentPage}
              setCurrentPage={(page: number) =>
                dispatch(editRecentlyAddedListVariables({ currentPage: page }))
              }
              totalPages={totalPages}
            />
          </TableRowDynamic>
        )}
      </Table>
    </RecentlyAddedListContainer>
  );
};

export default RecentlyAddedList;
