import React from "react";
import { Link } from "react-router-dom";
import { Logo, SidePanelContainer } from "./styled";
import { CopyRightText } from "../../shared/CopyRight";
import { Colors } from "../../../constants/styles";
import EmailButton from "../../shared/EmailButton";
import OvarcLogoWhite from "../../../Images/logos/LogoWhite.svg";
import {
  RegistrationSteps,
  registrationSteps,
} from "../../../constants/registration";
import AccountTypeContent from "./AccountTypeContent";
import RegistrationStepper from "../RegistrationStepper";

interface SidePanelProps {
  step: RegistrationSteps;
}

const SidePanel: React.FC<SidePanelProps> = ({ step }) => {
  // const renderContent = () => {
  //   switch (step) {
  //     case RegistrationSteps.solutions:
  //       let steps = registrationSteps;
  //       steps[1].completed = true;
  //       return <RegistrationStepper currentStep={2} steps={steps} />;

  //     default:
  //       return <AccountTypeContent />;
  //   }
  // };
  return (
    <SidePanelContainer>
      <Link to="/" style={{ width: "fit-content", height: "fit-content" }}>
        <Logo src={OvarcLogoWhite} alt="ovarc-logo" />
      </Link>
      <AccountTypeContent />
      <CopyRightText style={{ color: Colors.text_white }} />
      <EmailButton />
    </SidePanelContainer>
  );
};

export default SidePanel;
