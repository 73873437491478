import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  ASSETS_SCREEN,
  BENEFITS_SCREEN,
  CONTRACTS_SCREEN,
  CONTRACT_SCREEN,
  HOME_SCREEN,
  LOGIN_SCREEN,
  MANAGE_SCREEN,
  NEW_EMPLOYEE_CONTRACT_SCREEN,
  NEW_EMPLOYEE_SCREEN,
  OVERVIEW_SCREEN,
  PAYROLL_DETAILS_SCREEN,
  PAYROLL_PROCESS_SCREEN,
  PAYROLL_REVIEW_SCREEN,
  PAYROLL_SCREEN,
  PAYSLIPS_SCREEN,
  PAYSLIP_DETAILS_SCREEN,
  PEOPLE_SCREEN,
  PROFILE_SCREEN,
  PAYMENTS_SCREEN,
  REGISTER_SCREEN,
  REQUESTS_SCREEN,
  REQUEST_DETAILS_SCREEN,
  registrationRoutes,
  INVOICE_DETAILS_SCREEN,
  PAY_INVOICES_SCREEN,
  PAYMENT_DETAILS_SCREEN,
  ASSETS_HUB_SCREEN,
  ASSET_DETAILS_SCREEN,
  REQUEST_ASSET_SCREEN,
  ADD_ASSET_SCREEN,
} from "../constants/routesNames";
import LoginScreen from "../screens/Login";
import RegisterScreen from "../screens/Register";
import PrivateRoutes from "./PrivateRoutes";
import DashboardScreen from "../screens/Dashboard";
import { useAppDispatch } from "../redux/reduxHooks";
import {
  retrieveLoggedIn,
  retrieveToken,
  retrieveUser,
} from "../redux/slices/authSlice";
import Layout from "../components/shared/Layout";
import ErrorScreen from "../screens/ErrorScreen";
import AuthRoutes from "./AuthRoutes";
import PeopleScreen from "../screens/People";
import DynamicRoute from "./DynamicRoute";
import Profile from "../components/people/Profile";
import Manage from "../components/people/Manage";
import NewEmployee from "../components/people/NewEmployee";
import Contract from "../components/people/Contract";
import NewEmployeeContract from "../components/people/NewEmployeeContract";
import EmployeeRequestDetails from "../components/people/Profile/EmployeeRequestDetails";
import EmployeePayslipDetails from "../components/people/Profile/EmployeePayslipDetails";
import EmployeeContract from "../components/people/Profile/EmployeeContract";
import Overview from "../components/people/Profile/Overview";
import Payslips from "../components/people/Profile/Payslips";
import EmployeeAssets from "../components/people/Profile/EmployeeAssets";
import EmployeeRequests from "../components/people/Profile/EmployeeRequests";
import EmployeeBenefits from "../components/people/Profile/EmployeeBenefits";
import PayrollScreen from "../screens/Payroll";
import PayrollReview from "../components/payroll/PayrollReview";
import PayrollDetails from "../components/payroll/PayrollDetails";
import PayrollProcess from "../components/payroll/PayrollProcess";
import PaymentScreen from "../screens/Payment";
import { retrieveDashboardBanners } from "../redux/slices/appState";
import { useEffect, useState } from "react";
import MobileView from "../screens/MobileView";
import InvoiceDetails from "../components/payment/InvoiceDetails";
import PayInvoices from "../components/payment/PayInvoices";
import PaymentDetails from "../components/payment/PaymentDetails";
import AssetsHubScreen from "../screens/AssetsHub";
import AssetDetails from "../components/assetsHub/AssetDetails";
import RequestAsset from "../components/assetsHub/RequestAsset";
import AddAsset from "../components/assetsHub/AddAsset";

export const RootRouter = () => {
  const dispatch = useAppDispatch();

  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    dispatch(retrieveToken());
    dispatch(retrieveLoggedIn());
    dispatch(retrieveDashboardBanners());
    dispatch(retrieveUser());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 750);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return mobile ? (
    <MobileView />
  ) : (
    <Router>
      <Layout>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path={HOME_SCREEN} element={<DashboardScreen />} />

            <Route path={PEOPLE_SCREEN} element={<PeopleScreen />}>
              <Route index path={MANAGE_SCREEN} element={<Manage />} />
              <Route path={PROFILE_SCREEN} element={<Profile />}>
                <Route
                  path={NEW_EMPLOYEE_CONTRACT_SCREEN}
                  element={<NewEmployeeContract />}
                />
                <Route
                  path={REQUEST_DETAILS_SCREEN}
                  element={<EmployeeRequestDetails />}
                />
                <Route
                  path={PAYSLIP_DETAILS_SCREEN}
                  element={<EmployeePayslipDetails />}
                />
                <Route path={CONTRACT_SCREEN} element={<EmployeeContract />} />
                <Route path={OVERVIEW_SCREEN} element={<Overview />} />
                <Route path={PAYSLIPS_SCREEN} element={<Payslips />} />
                <Route path={ASSETS_SCREEN} element={<EmployeeAssets />} />
                <Route path={REQUESTS_SCREEN} element={<EmployeeRequests />} />
                <Route path={BENEFITS_SCREEN} element={<EmployeeBenefits />} />
              </Route>
              <Route path={NEW_EMPLOYEE_SCREEN} element={<NewEmployee />} />
              <Route path={CONTRACTS_SCREEN} element={<Contract />} />
            </Route>

            <Route path={PAYROLL_SCREEN} element={<PayrollScreen />}>
              <Route path={PAYROLL_REVIEW_SCREEN} element={<PayrollReview />} />
              <Route
                path={PAYROLL_DETAILS_SCREEN}
                element={<PayrollDetails />}
              />

              <Route
                path={PAYROLL_PROCESS_SCREEN}
                element={<PayrollProcess />}
              />
            </Route>
            <Route path={PAYMENTS_SCREEN} element={<PaymentScreen />}>
              <Route
                path={INVOICE_DETAILS_SCREEN}
                element={<InvoiceDetails />}
              />
              <Route path={PAY_INVOICES_SCREEN} element={<PayInvoices />} />
              <Route
                path={PAYMENT_DETAILS_SCREEN}
                element={<PaymentDetails />}
              />
            </Route>
            <Route path={ASSETS_HUB_SCREEN} element={<AssetsHubScreen />}>
              <Route path={ASSET_DETAILS_SCREEN} element={<AssetDetails />} />
              <Route path={REQUEST_ASSET_SCREEN} element={<RequestAsset />} />
              <Route path={ADD_ASSET_SCREEN} element={<AddAsset />} />
            </Route>
          </Route>
          <Route element={<AuthRoutes />}>
            <Route path={LOGIN_SCREEN} element={<LoginScreen />} />
            <Route
              element={
                <DynamicRoute values={registrationRoutes} paramName="step" />
              }
            >
              <Route path={REGISTER_SCREEN} element={<RegisterScreen />} />
            </Route>
          </Route>
          <Route
            path="/payment/payment-details"
            element={<Navigate to={PAYMENTS_SCREEN} />}
          />
          <Route path="*" element={<ErrorScreen />} />
        </Routes>
      </Layout>
    </Router>
  );
};
