import { useState } from "react";
import PeopleEmptyState from "../../PeopleEmptyState";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../shared/Table/styled";
import Typography from "../../../shared/Typography";
import {
  BenefitsBoldText,
  BenefitsContainer,
  BenefitsHeader,
  BenefitsText,
  BenefitsTitle,
} from "./styled";
import { FontSizes, FontWeights } from "../../../../constants/styles";
import moment from "moment";
import { currencyFormatter } from "../../../../utilities/currencyFormatter";
import ActionsDropDown from "../../../shared/ActionsDropDown";
import UnrollNowModal from "./UnrollNowModal";
import UnrollOnSpecificDateModal from "./UnrollOnSpeceficDateModal";
import {
  useGetEmployeeBenefits,
  useUnenrollBenefitByDate,
  useUnenrollBenefitNow,
} from "../../../../queries/people";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { SnackbarTypes } from "../../../../types/componentsProps";
import { useQueryClient } from "@tanstack/react-query";

const EmployeeBenefits = () => {
  const { data: employeeBenefits } = useGetEmployeeBenefits();
  const { mutateAsync: unenrollNow } = useUnenrollBenefitNow();
  const { mutateAsync: unenrollByDate } = useUnenrollBenefitByDate();

  const [openNowModal, setOpenNowModal] = useState(false);
  const [openSpecificDateModal, setOpenSpecificDateModal] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [activeBenefitID, setActiveBenefitID] = useState<any>(null);
  const queryClient = useQueryClient();

  const onUnenrollNow = async () => {
    try {
      await unenrollNow({ benefit_id: activeBenefitID });
      queryClient.invalidateQueries({
        queryKey: ["employeeBenefits"],
      });
    } catch (e) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to unenroll now",
      });
    }
    setOpenNowModal(false);
  };

  const onUnenrollByDate = async (date: string) => {
    try {
      await unenrollByDate({
        benefit_id: activeBenefitID,
        unenroll_date: date,
      });

      showSnackbar({
        open: true,
        type: SnackbarTypes.SUCCESS,
        title: "Unenrolled successfully",
        description: "benefit will be unenrolled on the selected date",
      });
    } catch (e) {
      showSnackbar({
        open: true,
        type: SnackbarTypes.ERROR,
        title: "Something went wrong",
        description: "Failed to unenroll by date",
      });
    }
    setOpenSpecificDateModal(false);
  };

  const renderPayslipsRows = () => {
    return employeeBenefits?.map((benefit, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <BenefitsBoldText
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              {benefit.provider}
            </BenefitsBoldText>
          </TableCell>

          <TableCell>
            <BenefitsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {benefit.type}
            </BenefitsText>
          </TableCell>
          <TableCell>
            <BenefitsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(benefit.startDate).format("DD-MM-YYYY")}
            </BenefitsText>
          </TableCell>
          <TableCell>
            <BenefitsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {moment(benefit.endDate).format("DD-MM-YYYY")}
            </BenefitsText>
          </TableCell>
          <TableCell>
            <BenefitsBoldText
              size={FontSizes.TX_SM}
              weight={FontWeights.MEDIUM}
            >
              {benefit.plan.title}
            </BenefitsBoldText>
            <BenefitsText size={FontSizes.TX_SM} weight={FontWeights.REGULAR}>
              {currencyFormatter(benefit.plan.price, "USD")}/{" "}
              {benefit.plan.coveragePeriod}
            </BenefitsText>
          </TableCell>
          <TableCell>
            <ActionsDropDown
              actions={[
                {
                  text: "Unroll now",
                  onSelect: () => {
                    setActiveBenefitID(benefit.id);
                    setOpenNowModal(true);
                  },
                },

                {
                  text: "Unroll on a specific date",
                  onSelect: () => {
                    setActiveBenefitID(benefit.id);
                    setOpenSpecificDateModal(true);
                  },
                },
              ]}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderPayslipsTable = () => {
    return (
      <BenefitsContainer>
        <BenefitsHeader>
          <BenefitsTitle size={FontSizes.TX_LG} weight={FontWeights.SEMIBOLD}>
            Benefits
          </BenefitsTitle>
        </BenefitsHeader>
        <Table>
          <TableHeader>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Type
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Start Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                End Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography size={FontSizes.TX_SM} weight={FontWeights.MEDIUM}>
                Plan
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableHeader>
          {renderPayslipsRows()}
        </Table>
        <UnrollNowModal
          open={openNowModal}
          onClose={() => setOpenNowModal(false)}
          onConfirm={() => {
            onUnenrollNow();
          }}
        />
        <UnrollOnSpecificDateModal
          open={openSpecificDateModal}
          onClose={() => setOpenSpecificDateModal(false)}
          onConfirm={(date: string) => {
            onUnenrollByDate(date);
            setOpenSpecificDateModal(false);
          }}
        />
      </BenefitsContainer>
    );
  };

  return employeeBenefits?.length === 0 || !employeeBenefits ? (
    <PeopleEmptyState
      title="You have not subscribed this employee in a benefit"
      btnText="View Benefits"
      onBtnClick={() => {}}
    />
  ) : (
    renderPayslipsTable()
  );
};

export default EmployeeBenefits;
