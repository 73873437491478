import styled from "styled-components";
import { Colors, Spaces } from "../../../constants/styles";
import Typography from "../../shared/Typography";

export const PayInvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
export const PayInvoicesHeader = styled.div`
  width: 100%;
  padding-bottom: ${Spaces.spacing_2xl};
  border-bottom: 1px solid ${Colors.border_secondary};
  display: flex;
  flex-direction: column;
`;

export const PayInvoicesTitle = styled(Typography)`
  color: ${Colors.text_primary};
  margin-bottom: ${Spaces.spacing_xs};
`;
export const PayInvoicesSubtitle = styled(Typography)`
  color: ${Colors.text_tertiary_600};
`;

export const PayInvoicesContentContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  padding-top: ${Spaces.spacing_4xl};
`;

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spaces.spacing_xl};
`;
